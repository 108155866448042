<template>
  <div>
    <div>
      <p class="content">Harry Potter and the Forbidden Journey ™ will be closed from Monday, May 10th to Sunday, May 16th.</p>
      <p class="content">If you are using a time-designated Studio Pass (such as those that permit entry after 3 p.m.), or an Express Pass, and you plan to participate in a time-designated event, there are time slots in which you will not be able to participate. </p>
        <p class="content">When you are deciding when to participate, make sure the times do not overlap.</p>
        <p class="content">Availability may be limited depending on the entry date. Please check the availability calendar in advance.</p>
      <!-- <p class="content">For attractions that do not specify other times, please use at times other than the following</p> -->
      <!-- <p class="content">It may be sold out. Also, depending on the admission date, there may be times when it is not sold.</p> -->
    </div>
    <el-row class="btn_box">
        <div href="session" @click="schedule = '1'" class="select_designated_time_btn" :class="{'btn-active': schedule === '1'}">
          Admission date：～May 31st
          <img src="@/assets/down-icon.png" alt="">
        </div>
        <div href="session" @click="schedule = '2'" class="select_designated_time_btn" :class="{'btn-active': schedule === '2'}">
          Admission date：June 1st～July 1st
          <img src="@/assets/down-icon.png" alt="">
        </div>
    </el-row>
    <table v-if="schedule === '1'" class="cmn_att_time01 cmn_tbl01">
              <caption>Admission date：～May 31st</caption>
              <colgroup>
              <col class="w100">
              <col>
              </colgroup>
              <thead>
                <tr>
                  <th colspan="6" class="txtleft">▼ View time at left side</th>
                </tr>
              </thead>
              <tbody>
                <!-- ↓挿入箇所↓ -->

<tr>
  <th rowspan="2">1</th>
  <th rowspan="2">10:00～<br class="sp_only">13:40</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>12:40～</td>
  <td>13:00～13:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">2</th>
  <th rowspan="2">10:00～<br class="sp_only">13:50</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>12:50～</td>
  <td>13:10～13:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">3</th>
  <th rowspan="2">10:00～<br class="sp_only">15:30</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">4</th>
  <th rowspan="2">10:00～<br class="sp_only">16:20</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">5</th>
  <th rowspan="2">10:00～<br class="sp_only">18:50</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>17:50～</td>
  <td>18:10～18:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">6～7</th>
  <th rowspan="2">10:10～<br class="sp_only">16:40</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">8</th>
  <th rowspan="2">10:10～<br class="sp_only">17:20</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">9</th>
  <th rowspan="2">10:10～<br class="sp_only">17:40</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">10</th>
  <th rowspan="2">10:10～<br class="sp_only">18:10</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">11</th>
  <th rowspan="2">10:20～<br class="sp_only">14:20</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>13:20～</td>
  <td>13:40～14:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">12</th>
  <th rowspan="2">10:30～<br class="sp_only">14:40</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>13:40～</td>
  <td>14:00～14:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">13</th>
  <th rowspan="2">10:30～<br class="sp_only">15:40</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">14</th>
  <th rowspan="2">10:40～<br class="sp_only">14:40</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>13:40～</td>
  <td>14:00～14:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">15</th>
  <th rowspan="2">10:40～<br class="sp_only">16:10</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">16</th>
  <th rowspan="2">10:40～<br class="sp_only">17:30</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">17</th>
  <th rowspan="2">10:50～<br class="sp_only">13:20</th>
  <td>10:50～</td>
  <td>11:10～11:40</td>
  <td>12:20～</td>
  <td>12:40～13:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">18</th>
  <th rowspan="2">10:50～<br class="sp_only">17:20</th>
  <td>10:50～</td>
  <td>11:10～11:40</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">19</th>
  <th rowspan="2">10:50～<br class="sp_only">19:10</th>
  <td>10:50～</td>
  <td>11:10～11:40</td>
  <td>18:10～</td>
  <td>18:30～19:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">20</th>
  <th rowspan="2">11:00～<br class="sp_only">13:40</th>
  <td>11:00～</td>
  <td>11:20～11:50</td>
  <td>12:40～</td>
  <td>13:00～13:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">21</th>
  <th rowspan="2">11:00～<br class="sp_only">17:10</th>
  <td>11:00～</td>
  <td>11:20～11:50</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">22</th>
  <th rowspan="2">11:10～<br class="sp_only">13:30</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>12:30～</td>
  <td>12:50～13:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">23～24</th>
  <th rowspan="2">11:10～<br class="sp_only">13:50</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>12:50～</td>
  <td>13:10～13:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">25</th>
  <th rowspan="2">11:10～<br class="sp_only">14:20</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>13:20～</td>
  <td>13:40～14:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">26</th>
  <th rowspan="2">11:10～<br class="sp_only">16:50</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">27</th>
  <th rowspan="2">11:10～<br class="sp_only">18:00</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">28</th>
  <th rowspan="2">11:10～<br class="sp_only">19:00</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>18:00～</td>
  <td>18:20～18:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">29</th>
  <th rowspan="2">11:20～<br class="sp_only">14:00</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>13:00～</td>
  <td>13:20～13:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">30</th>
  <th rowspan="2">11:20～<br class="sp_only">17:40</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">31</th>
  <th rowspan="2">11:20～<br class="sp_only">18:10</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">32</th>
  <th rowspan="2">11:20～<br class="sp_only">18:30</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>17:30～</td>
  <td>17:50～18:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">33</th>
  <th rowspan="2">11:30～<br class="sp_only">14:20</th>
  <td>11:30～</td>

  <td>11:50～12:20</td>
  <td>13:20～</td>
  <td>13:40～14:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">34</th>
  <th rowspan="2">11:30～<br class="sp_only">15:50</th>
  <td>11:30～</td>
  <td>11:50～12:20</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">35</th>
  <th rowspan="2">11:30～<br class="sp_only">16:00</th>
  <td>11:30～</td>
  <td>11:50～12:20</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">36</th>
  <th rowspan="2">11:30～<br class="sp_only">17:30</th>
  <td>11:30～</td>
  <td>11:50～12:20</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">37</th>
  <th rowspan="2">11:40～<br class="sp_only">15:10</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>14:10～</td>
  <td>14:30～15:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">38</th>
  <th rowspan="2">11:40～<br class="sp_only">15:10</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>14:10～</td>
  <td>14:30～15:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">39</th>
  <th rowspan="2">11:40～<br class="sp_only">16:30</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">40</th>
  <th rowspan="2">11:40～<br class="sp_only">17:50</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">41～42</th>
  <th rowspan="2">11:50～<br class="sp_only">15:50</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">43</th>
  <th rowspan="2">11:50～<br class="sp_only">15:50</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">44</th>
  <th rowspan="2">11:50～<br class="sp_only">16:10</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">45</th>
  <th rowspan="2">11:50～<br class="sp_only">16:40</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">46</th>
  <th rowspan="2">11:50～<br class="sp_only">17:50</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">47～48</th>
  <th rowspan="2">11:50～<br class="sp_only">18:00</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">49</th>
  <th rowspan="2">11:50～<br class="sp_only">19:40</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>18:40～</td>
  <td>19:00～19:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">50</th>
  <th rowspan="2">12:00～<br class="sp_only">17:10</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">51</th>
  <th rowspan="2">12:00～<br class="sp_only">17:10</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">52</th>
  <th rowspan="2">12:00～<br class="sp_only">17:20</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">53</th>
  <th rowspan="2">12:00～<br class="sp_only">17:20</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">54</th>
  <th rowspan="2">12:00～<br class="sp_only">17:50</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">55</th>
  <th rowspan="2">12:00～<br class="sp_only">18:10</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">56</th>
  <th rowspan="2">12:10～<br class="sp_only">16:10</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">57</th>
  <th rowspan="2">12:10～<br class="sp_only">16:20</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">58</th>
  <th rowspan="2">12:10～<br class="sp_only">16:20</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">59</th>
  <th rowspan="2">12:10～<br class="sp_only">16:50</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">60</th>
  <th rowspan="2">12:10～<br class="sp_only">17:00</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">61</th>
  <th rowspan="2">12:10～<br class="sp_only">17:40</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">62</th>
  <th rowspan="2">12:10～<br class="sp_only">17:50</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">63</th>
  <th rowspan="2">12:10～<br class="sp_only">18:00</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">64～65</th>
  <th rowspan="2">12:20～<br class="sp_only">15:20</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>14:20～</td>
  <td>14:40～15:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">66</th>
  <th rowspan="2">12:20～<br class="sp_only">15:50</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">67</th>
  <th rowspan="2">12:20～<br class="sp_only">15:50</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">68</th>
  <th rowspan="2">12:20～<br class="sp_only">16:10</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">69</th>
  <th rowspan="2">12:20～<br class="sp_only">17:30</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">70</th>
  <th rowspan="2">12:20～<br class="sp_only">18:00</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">71</th>
  <th rowspan="2">12:20～<br class="sp_only">18:10</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">72</th>
  <th rowspan="2">12:30～<br class="sp_only">16:30</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">73</th>
  <th rowspan="2">12:30～<br class="sp_only">17:00</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">74</th>
  <th rowspan="2">12:30～<br class="sp_only">17:50</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">75</th>
  <th rowspan="2">12:30～<br class="sp_only">18:00</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">76</th>
  <th rowspan="2">12:30～<br class="sp_only">18:00</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">77</th>
  <th rowspan="2">12:30～<br class="sp_only">18:10</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">78</th>
  <th rowspan="2">12:40～<br class="sp_only">16:10</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">79～80</th>
  <th rowspan="2">12:40～<br class="sp_only">17:00</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">81</th>
  <th rowspan="2">12:40～<br class="sp_only">17:10</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">82</th>
  <th rowspan="2">12:50～<br class="sp_only">16:40</th>
  <td>12:50～</td>
  <td>13:10～13:40</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">83</th>
  <th rowspan="2">12:50～<br class="sp_only">17:00</th>
  <td>12:50～</td>
  <td>13:10～13:40</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">84</th>
  <th rowspan="2">12:50～<br class="sp_only">17:30</th>
  <td>12:50～</td>
  <td>13:10～13:40</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">85</th>
  <th rowspan="2">12:50～<br class="sp_only">18:10</th>
  <td>12:50～</td>
  <td>13:10～13:40</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">86</th>
  <th rowspan="2">12:50～<br class="sp_only">20:10</th>
  <td>12:50～</td>
  <td>13:10～13:40</td>
  <td>19:10～</td>
  <td>19:30～20:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">87</th>
  <th rowspan="2">13:00～<br class="sp_only">16:00</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">88</th>
  <th rowspan="2">13:00～<br class="sp_only">16:40</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">89</th>
  <th rowspan="2">13:00～<br class="sp_only">17:20</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">90</th>
  <th rowspan="2">13:00～<br class="sp_only">17:30</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">91</th>
  <th rowspan="2">13:00～<br class="sp_only">18:00</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">92</th>
  <th rowspan="2">13:10～<br class="sp_only">16:00</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">93</th>
  <th rowspan="2">13:10～<br class="sp_only">16:30</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">94</th>
  <th rowspan="2">13:10～<br class="sp_only">17:20</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">95</th>
  <th rowspan="2">13:10～<br class="sp_only">17:30</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">96～97</th>
  <th rowspan="2">13:10～<br class="sp_only">17:50</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">98</th>
  <th rowspan="2">13:20～<br class="sp_only">16:30</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">99</th>
  <th rowspan="2">13:20～<br class="sp_only">17:00</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">100～101</th>
  <th rowspan="2">13:20～<br class="sp_only">17:30</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">102</th>
  <th rowspan="2">13:20～<br class="sp_only">18:00</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">103</th>
  <th rowspan="2">13:20～<br class="sp_only">18:20</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>17:20～</td>
  <td>17:40～18:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">104</th>
  <th rowspan="2">13:20～<br class="sp_only">18:50</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>17:50～</td>
  <td>18:10～18:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">105</th>
  <th rowspan="2">13:30～<br class="sp_only">17:00</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">106</th>
  <th rowspan="2">13:30～<br class="sp_only">17:30</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">107</th>
  <th rowspan="2">13:30～<br class="sp_only">19:00</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
  <td>18:00～</td>
  <td>18:20～18:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">108</th>
  <th rowspan="2">13:30～<br class="sp_only">20:10</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
  <td>19:10～</td>
  <td>19:30～20:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">109</th>
  <th rowspan="2">13:40～<br class="sp_only">17:10</th>
  <td>13:40～</td>
  <td>14:00～14:30</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">110</th>
  <th rowspan="2">13:40～<br class="sp_only">17:50</th>
  <td>13:40～</td>
  <td>14:00～14:30</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">111</th>
  <th rowspan="2">13:50～<br class="sp_only">17:40</th>
  <td>13:50～</td>
  <td>14:10～14:40</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">112</th>
  <th rowspan="2">13:50～<br class="sp_only">18:10</th>
  <td>13:50～</td>
  <td>14:10～14:40</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">113</th>
  <th rowspan="2">14:00～<br class="sp_only">16:40</th>
  <td>14:00～</td>
  <td>14:20～14:50</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">114</th>
  <th rowspan="2">14:00～<br class="sp_only">16:50</th>
  <td>14:00～</td>
  <td>14:20～14:50</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">115～116</th>
  <th rowspan="2">14:00～<br class="sp_only">17:30</th>
  <td>14:00～</td>
  <td>14:20～14:50</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">117</th>
  <th rowspan="2">14:10～<br class="sp_only">17:10</th>
  <td>14:10～</td>
  <td>14:30～15:00</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">118</th>
  <th rowspan="2">14:10～<br class="sp_only">17:20</th>
  <td>14:10～</td>
  <td>14:30～15:00</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">119</th>
  <th rowspan="2">14:10～<br class="sp_only">17:50</th>
  <td>14:10～</td>
  <td>14:30～15:00</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">120</th>
  <th rowspan="2">14:10～<br class="sp_only">18:00</th>
  <td>14:10～</td>
  <td>14:30～15:00</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">121</th>
  <th rowspan="2">14:10～<br class="sp_only">18:10</th>
  <td>14:10～</td>
  <td>14:30～15:00</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">122～123</th>
  <th rowspan="2">14:20～<br class="sp_only">17:00</th>
  <td>14:20～</td>
  <td>14:40～15:10</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">124</th>
  <th rowspan="2">14:20～<br class="sp_only">18:00</th>
  <td>14:20～</td>
  <td>14:40～15:10</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">125</th>
  <th rowspan="2">14:30～<br class="sp_only">18:10</th>
  <td>14:30～</td>
  <td>14:50～15:20</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">126</th>
  <th rowspan="2">14:40～<br class="sp_only">17:20</th>
  <td>14:40～</td>
  <td>15:00～15:30</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">127～128</th>
  <th rowspan="2">14:40～<br class="sp_only">18:10</th>
  <td>14:40～</td>
  <td>15:00～15:30</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">129</th>
  <th rowspan="2">14:40～<br class="sp_only">18:10</th>
  <td>14:40～</td>
  <td>15:00～15:30</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">130</th>
  <th rowspan="2">15:00～<br class="sp_only">17:30</th>
  <td>15:00～</td>
  <td>15:20～15:50</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">131</th>
  <th rowspan="2">15:00～<br class="sp_only">17:40</th>
  <td>15:00～</td>
  <td>15:20～15:50</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">132</th>
  <th rowspan="2">15:10～<br class="sp_only">17:40</th>
  <td>15:10～</td>
  <td>15:30～16:00</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">133</th>
  <th rowspan="2">15:10～<br class="sp_only">18:00</th>
  <td>15:10～</td>
  <td>15:30～16:00</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">134</th>
  <th rowspan="2">15:20～<br class="sp_only">17:50</th>
  <td>15:20～</td>
  <td>15:40～16:10</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">135</th>
  <th rowspan="2">16:00～<br class="sp_only">18:40</th>
  <td>16:00～</td>
  <td>16:20～16:50</td>
  <td>17:40～</td>
  <td>18:00～18:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">136</th>
  <th rowspan="2">18:50～<br class="sp_only">21:10</th>
  <td>18:50～</td>
  <td>19:10～19:40</td>
  <td>20:10～</td>
  <td>20:30～21:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>

                <!-- ↑挿入箇所↑ -->
              </tbody>
            </table>
            <table v-if="schedule === '2'" class="cmn_att_time01 cmn_tbl01">
              <caption>Admission date：June 1st～July 1st</caption>
              <colgroup>
              <col class="w100">
              <col>
              </colgroup>
              <thead>
                <tr>
                  <th colspan="6" class="txtleft">▼ View time at left side</th>
                </tr>
              </thead>
              <tbody>
                <!-- ↓挿入箇所↓ -->

<tr>
  <th rowspan="2">1</th>
  <th rowspan="2">10:00～<br class="sp_only">13:40</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>12:40～</td>
  <td>13:00～13:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">2</th>
  <th rowspan="2">10:00～<br class="sp_only">15:30</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">3</th>
  <th rowspan="2">10:00～<br class="sp_only">16:20</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">4</th>
  <th rowspan="2">10:00～<br class="sp_only">17:50</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">5</th>
  <th rowspan="2">10:00～<br class="sp_only">18:10</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">6</th>
  <th rowspan="2">10:10～<br class="sp_only">14:10</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>13:10～</td>
  <td>13:30～14:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">7</th>
  <th rowspan="2">10:10～<br class="sp_only">17:10</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">8</th>
  <th rowspan="2">10:10～<br class="sp_only">18:00</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">9</th>
  <th rowspan="2">10:20～<br class="sp_only">15:00</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>14:00～</td>
  <td>14:20～14:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">10</th>
  <th rowspan="2">10:20～<br class="sp_only">17:50</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">11</th>
  <th rowspan="2">10:20～<br class="sp_only">18:00</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">12</th>
  <th rowspan="2">10:20～<br class="sp_only">18:10</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">13</th>
  <th rowspan="2">10:30～<br class="sp_only">13:40</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>12:40～</td>
  <td>13:00～13:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">14</th>
  <th rowspan="2">10:30～<br class="sp_only">13:50</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>12:50～</td>
  <td>13:10～13:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">15</th>
  <th rowspan="2">10:30～<br class="sp_only">15:40</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">16</th>
  <th rowspan="2">10:30～<br class="sp_only">16:10</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">17</th>
  <th rowspan="2">10:30～<br class="sp_only">16:50</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">18～19</th>
  <th rowspan="2">10:40～<br class="sp_only">14:40</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>13:40～</td>
  <td>14:00～14:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">20</th>
  <th rowspan="2">10:40～<br class="sp_only">15:10</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>14:10～</td>
  <td>14:30～15:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">21</th>
  <th rowspan="2">10:40～<br class="sp_only">15:10</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>14:10～</td>
  <td>14:30～15:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">22</th>
  <th rowspan="2">10:50～<br class="sp_only">16:50</th>
  <td>10:50～</td>
  <td>11:10～11:40</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">23～24</th>
  <th rowspan="2">10:50～<br class="sp_only">17:20</th>
  <td>10:50～</td>
  <td>11:10～11:40</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">25</th>
  <th rowspan="2">11:10～<br class="sp_only">15:20</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>14:20～</td>
  <td>14:40～15:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">26</th>
  <th rowspan="2">11:10～<br class="sp_only">17:40</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">27</th>
  <th rowspan="2">11:10～<br class="sp_only">18:10</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">28</th>
  <th rowspan="2">11:20～<br class="sp_only">13:40</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>12:40～</td>
  <td>13:00～13:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">29</th>
  <th rowspan="2">11:20～<br class="sp_only">16:00</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">30</th>
  <th rowspan="2">11:20～<br class="sp_only">16:50</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">31</th>
  <th rowspan="2">11:20～<br class="sp_only">18:10</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">32</th>
  <th rowspan="2">11:40～<br class="sp_only">15:50</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">33</th>
  <th rowspan="2">11:40～<br class="sp_only">16:40</th>
  <td>11:40～</td>

  <td>12:00～12:30</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">34</th>
  <th rowspan="2">11:40～<br class="sp_only">17:30</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">35</th>
  <th rowspan="2">11:50～<br class="sp_only">14:50</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>13:50～</td>
  <td>14:10～14:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">36</th>
  <th rowspan="2">11:50～<br class="sp_only">15:20</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>14:20～</td>
  <td>14:40～15:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">37</th>
  <th rowspan="2">11:50～<br class="sp_only">16:00</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">38</th>
  <th rowspan="2">11:50～<br class="sp_only">16:10</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">39</th>
  <th rowspan="2">11:50～<br class="sp_only">16:30</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">40</th>
  <th rowspan="2">11:50～<br class="sp_only">16:40</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">41</th>
  <th rowspan="2">11:50～<br class="sp_only">17:10</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">42</th>
  <th rowspan="2">11:50～<br class="sp_only">17:40</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">43</th>
  <th rowspan="2">12:00～<br class="sp_only">14:40</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>13:40～</td>
  <td>14:00～14:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">44</th>
  <th rowspan="2">12:00～<br class="sp_only">15:20</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>14:20～</td>
  <td>14:40～15:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">45</th>
  <th rowspan="2">12:00～<br class="sp_only">16:20</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">46</th>
  <th rowspan="2">12:00～<br class="sp_only">17:50</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">47</th>
  <th rowspan="2">12:10～<br class="sp_only">15:10</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>14:10～</td>
  <td>14:30～15:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">48</th>
  <th rowspan="2">12:10～<br class="sp_only">15:10</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>14:10～</td>
  <td>14:30～15:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">49</th>
  <th rowspan="2">12:10～<br class="sp_only">15:40</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">50</th>
  <th rowspan="2">12:10～<br class="sp_only">16:00</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">51</th>
  <th rowspan="2">12:10～<br class="sp_only">16:10</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">52</th>
  <th rowspan="2">12:10～<br class="sp_only">16:40</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">53</th>
  <th rowspan="2">12:10～<br class="sp_only">16:50</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">54</th>
  <th rowspan="2">12:10～<br class="sp_only">17:00</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">55</th>
  <th rowspan="2">12:10～<br class="sp_only">18:00</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">56</th>
  <th rowspan="2">12:20～<br class="sp_only">14:40</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>13:40～</td>
  <td>14:00～14:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">57</th>
  <th rowspan="2">12:20～<br class="sp_only">15:00</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>14:00～</td>
  <td>14:20～14:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">58</th>
  <th rowspan="2">12:20～<br class="sp_only">15:10</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>14:10～</td>
  <td>14:30～15:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">59</th>
  <th rowspan="2">12:20～<br class="sp_only">15:40</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">60</th>
  <th rowspan="2">12:20～<br class="sp_only">15:40</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">61</th>
  <th rowspan="2">12:20～<br class="sp_only">15:50</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">62</th>
  <th rowspan="2">12:20～<br class="sp_only">16:20</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">63</th>
  <th rowspan="2">12:20～<br class="sp_only">16:30</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">64</th>
  <th rowspan="2">12:20～<br class="sp_only">17:40</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">65</th>
  <th rowspan="2">12:20～<br class="sp_only">18:10</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">66</th>
  <th rowspan="2">12:30～<br class="sp_only">16:30</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">67</th>
  <th rowspan="2">12:30～<br class="sp_only">16:50</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">68</th>
  <th rowspan="2">12:30～<br class="sp_only">17:10</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">69</th>
  <th rowspan="2">12:30～<br class="sp_only">17:30</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">70</th>
  <th rowspan="2">12:30～<br class="sp_only">17:30</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">71</th>
  <th rowspan="2">12:30～<br class="sp_only">17:40</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">72</th>
  <th rowspan="2">12:30～<br class="sp_only">17:50</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">73</th>
  <th rowspan="2">12:30～<br class="sp_only">18:00</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">74</th>
  <th rowspan="2">12:40～<br class="sp_only">17:40</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">75</th>
  <th rowspan="2">12:40～<br class="sp_only">18:00</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">76</th>
  <th rowspan="2">12:50～<br class="sp_only">15:10</th>
  <td>12:50～</td>
  <td>13:10～13:40</td>
  <td>14:10～</td>
  <td>14:30～15:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">77</th>
  <th rowspan="2">12:50～<br class="sp_only">15:30</th>
  <td>12:50～</td>
  <td>13:10～13:40</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">78</th>
  <th rowspan="2">12:50～<br class="sp_only">16:00</th>
  <td>12:50～</td>
  <td>13:10～13:40</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">79</th>
  <th rowspan="2">12:50～<br class="sp_only">17:00</th>
  <td>12:50～</td>
  <td>13:10～13:40</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">80</th>
  <th rowspan="2">12:50～<br class="sp_only">18:10</th>
  <td>12:50～</td>
  <td>13:10～13:40</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">81</th>
  <th rowspan="2">13:00～<br class="sp_only">15:30</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">82</th>
  <th rowspan="2">13:00～<br class="sp_only">16:00</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">83</th>
  <th rowspan="2">13:00～<br class="sp_only">16:10</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">84</th>
  <th rowspan="2">13:00～<br class="sp_only">16:20</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">85</th>
  <th rowspan="2">13:00～<br class="sp_only">17:00</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">86</th>
  <th rowspan="2">13:00～<br class="sp_only">17:20</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">87</th>
  <th rowspan="2">13:10～<br class="sp_only">16:30</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">88</th>
  <th rowspan="2">13:10～<br class="sp_only">17:20</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">89</th>
  <th rowspan="2">13:10～<br class="sp_only">17:50</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">90</th>
  <th rowspan="2">13:20～<br class="sp_only">16:10</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">91</th>
  <th rowspan="2">13:20～<br class="sp_only">17:10</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">92</th>
  <th rowspan="2">13:20～<br class="sp_only">17:20</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">93</th>
  <th rowspan="2">13:20～<br class="sp_only">17:50</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">94</th>
  <th rowspan="2">13:20～<br class="sp_only">18:10</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">95</th>
  <th rowspan="2">13:30～<br class="sp_only">15:50</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">96</th>
  <th rowspan="2">13:30～<br class="sp_only">15:50</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">97</th>
  <th rowspan="2">13:30～<br class="sp_only">16:40</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">98</th>
  <th rowspan="2">13:30～<br class="sp_only">17:00</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">99</th>
  <th rowspan="2">13:50～<br class="sp_only">16:10</th>
  <td>13:50～</td>
  <td>14:10～14:40</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">100</th>
  <th rowspan="2">13:50～<br class="sp_only">16:30</th>
  <td>13:50～</td>
  <td>14:10～14:40</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">101</th>
  <th rowspan="2">14:00～<br class="sp_only">17:10</th>
  <td>14:00～</td>
  <td>14:20～14:50</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">102</th>
  <th rowspan="2">14:00～<br class="sp_only">18:10</th>
  <td>14:00～</td>
  <td>14:20～14:50</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">103</th>
  <th rowspan="2">14:10～<br class="sp_only">16:30</th>
  <td>14:10～</td>
  <td>14:30～15:00</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">104</th>
  <th rowspan="2">14:10～<br class="sp_only">17:20</th>
  <td>14:10～</td>
  <td>14:30～15:00</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">105</th>
  <th rowspan="2">14:10～<br class="sp_only">17:50</th>
  <td>14:10～</td>
  <td>14:30～15:00</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">106</th>
  <th rowspan="2">14:20～<br class="sp_only">17:30</th>
  <td>14:20～</td>
  <td>14:40～15:10</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">107</th>
  <th rowspan="2">14:20～<br class="sp_only">17:50</th>
  <td>14:20～</td>
  <td>14:40～15:10</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">108</th>
  <th rowspan="2">14:20～<br class="sp_only">17:50</th>
  <td>14:20～</td>
  <td>14:40～15:10</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">109</th>
  <th rowspan="2">14:30～<br class="sp_only">16:50</th>
  <td>14:30～</td>
  <td>14:50～15:20</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">110</th>
  <th rowspan="2">14:30～<br class="sp_only">17:30</th>
  <td>14:30～</td>
  <td>14:50～15:20</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">111</th>
  <th rowspan="2">14:30～<br class="sp_only">17:30</th>
  <td>14:30～</td>
  <td>14:50～15:20</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">112</th>
  <th rowspan="2">14:30～<br class="sp_only">17:40</th>
  <td>14:30～</td>
  <td>14:50～15:20</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">113</th>
  <th rowspan="2">14:30～<br class="sp_only">17:40</th>
  <td>14:30～</td>
  <td>14:50～15:20</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">114</th>
  <th rowspan="2">14:30～<br class="sp_only">18:10</th>
  <td>14:30～</td>
  <td>14:50～15:20</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">115</th>
  <th rowspan="2">14:40～<br class="sp_only">17:30</th>
  <td>14:40～</td>
  <td>15:00～15:30</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">116</th>
  <th rowspan="2">14:40～<br class="sp_only">18:10</th>
  <td>14:40～</td>
  <td>15:00～15:30</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">117</th>
  <th rowspan="2">14:50～<br class="sp_only">17:10</th>
  <td>14:50～</td>
  <td>15:10～15:40</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">118</th>
  <th rowspan="2">14:50～<br class="sp_only">18:10</th>
  <td>14:50～</td>
  <td>15:10～15:40</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">119～120</th>
  <th rowspan="2">15:00～<br class="sp_only">17:30</th>
  <td>15:00～</td>
  <td>15:20～15:50</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">121</th>
  <th rowspan="2">15:00～<br class="sp_only">17:30</th>
  <td>15:00～</td>
  <td>15:20～15:50</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">122</th>
  <th rowspan="2">15:00～<br class="sp_only">17:50</th>
  <td>15:00～</td>
  <td>15:20～15:50</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">123</th>
  <th rowspan="2">15:20～<br class="sp_only">17:50</th>
  <td>15:20～</td>
  <td>15:40～16:10</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">124</th>
  <th rowspan="2">15:40～<br class="sp_only">18:00</th>
  <td>15:40～</td>
  <td>16:00～16:30</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>

<tr>
  <th rowspan="2">125</th>
  <th rowspan="2">15:40～<br class="sp_only">18:10</th>
  <td>15:40～</td>
  <td>16:00～16:30</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>

                <!-- ↑挿入箇所↑ -->
              </tbody>
            </table>
  </div>
</template>


<script>
export default {
  data() {
    return{
      schedule: '1'
    }
  }
}
</script>

<style lang="scss" scoped>
  .table_content{
    display: block;
    .cmn_att_time01{
      width: 100%;
      min-width: 620px;
      margin: 30px auto 0;
      table-layout: fixed;
      thead th{
        background-color: #3e3a39;
        color: white;
      }
    }
  }
  .cmn_att_time01 th{
    border: 1px solid #333;
    padding: 9px;
    text-align: center;
    vertical-align: middle;
    overflow-wrap: break-word;
    color: white;
    background: #7C7C7C !important;
  }
  .txtleft{
    text-align: left !important;
  }
  .exp_att_time .cmn_att_time01 tbody tr:nth-child(even) td {
    border-top: 1px dotted #999;
    font-size: 1.3rem;
    line-height: 1.3;
  }
  .cmn_att_time01 tbody tr:nth-child(even) td {
    background: #E8E8E8;
  }
  .cmn_att_time01 td {
    border: 1px solid #333;
    padding: 9px;
    text-align: center;
    vertical-align: middle;
    overflow-wrap: break-word;
}
  .cmn_att_time01 thead th{
    background: #3E3A39 !important;
  }
  .cmn_tbl01{
    a{
      font-weight: bold;
    }
    caption{
      color: #333333;
    background: #D0DDEE;
    font-weight: bold;
    border-top: 1px solid #999;
    border-left: 1px solid #999;
    border-right: 1px solid #999;
    padding: 10px 15px;
    text-align: left;
    }
  }
  .content{
    padding-left: 15px;
    position: relative;
    &::before{
      content: '';
      display: inline-block;
      position: absolute;
      top: 8px;
      left: 5px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #d5ab81;
    }
    a{
      text-decoration:underline ;
      color:#003399;
    }
  }
  .btn_box{
    display: flex;
    padding: 10px;
    cursor: pointer;
    margin-top: 10px;
    padding-left: 0;
      .select_designated_time_btn{
        padding:0 10px;
        border: 1px solid;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 5px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(235, 235, 235, 1) 100%);
        font-weight: bold;
        &:first-child{
          margin-right: 15px;
        }
      }
      .btn-active{
        border: 2px solid #427ABE;
      }
  }
  .btn_box{
    padding-left: 0;
  }
  .table_content .cmn_att_time01{
    margin-top: 10px;
  }
  @media (max-width: 768px) {
    ::v-deep .btn_box{
      flex-wrap: wrap;
      padding-left: 0;
      .select_designated_time_btn{
        width: 290px;
        &:not(:last-child){
          margin-bottom: 10px;
        }
      }
    }
    /deep/.table_content .cmn_att_time01{
      min-width: auto;
    }
  }
</style>