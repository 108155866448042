<template>
  <div>
    <div v-if="productId === 1" class="table_content current">
      <div>
        <p class="content">If you are using a time-designated Studio Pass (such as those that permit entry after 3 p.m.), or an Express Pass, and you plan to participate in a time-designated event, there are time slots in which you will not be able to participate. </p>
        <p class="content">When you are deciding when to participate, make sure the times do not overlap.</p>
        <p class="content">Availability may be limited depending on the entry date. Please check the availability calendar in advance.</p>
        <!-- <p class="content">For attractions that do not specify other times, please use at times other than the following</p> -->
        <!-- <p class="content">It may be sold out. Also, depending on the admission date, there may be times when it is not sold.</p> -->
      </div>
      <table class="cmn_att_time01 cmn_tbl01">
              <!--<caption>入場日：～MM月DD日</caption>-->
              <colgroup>
              <col class="w100">
              <col>
              </colgroup>
              <thead>
                <tr>
                  <th colspan="7" class="txtleft">▼ View time at left side</th>
                </tr>
              </thead>
              <tbody>
                <!-- ↓挿入箇所↓ -->

<tr>
  <th rowspan="2">1</th>
  <th rowspan="2">10:00～<br class="sp_only">14:40</th>
  <td>10:00～</td>
  <td>10:20～<br class="sp_only">10:50</td>
  <td>13:20～</td>
  <td>13:40～<br class="sp_only">14:10</td>
  <td>14:10～<br class="sp_only">14:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">2</th>
  <th rowspan="2">10:10～<br class="sp_only">16:40</th>
  <td>10:10～</td>
  <td>10:30～<br class="sp_only">11:00</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">3</th>
  <th rowspan="2">10:30～<br class="sp_only">13:30</th>
  <td>10:30～</td>
  <td>10:50～<br class="sp_only">11:20</td>
  <td>12:10～</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>13:00～<br class="sp_only">13:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">4</th>
  <th rowspan="2">10:30～<br class="sp_only">15:50</th>
  <td>10:30～</td>
  <td>10:50～<br class="sp_only">11:20</td>
  <td>11:20～<br class="sp_only">11:50</td>
  <td>14:50～</td>
  <td>15:10～<br class="sp_only">15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">5</th>
  <th rowspan="2">10:40～<br class="sp_only">14:00</th>
  <td>10:40～</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>12:40～</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>13:30～<br class="sp_only">14:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">6</th>
  <th rowspan="2">10:40～<br class="sp_only">14:30</th>
  <td>10:40～</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>13:10～</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>14:00～<br class="sp_only">14:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">7</th>
  <th rowspan="2">10:40～<br class="sp_only">16:50</th>
  <td>10:40～</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>15:50～</td>
  <td>16:10～<br class="sp_only">16:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">8</th>
  <th rowspan="2">10:40～<br class="sp_only">17:20</th>
  <td>10:40～</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>16:20～</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">9</th>
  <th rowspan="2">10:50～<br class="sp_only">16:00</th>
  <td>10:50～</td>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>14:40～</td>
  <td>15:00～<br class="sp_only">15:30</td>
  <td>15:30～<br class="sp_only">16:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">10</th>
  <th rowspan="2">10:50～<br class="sp_only">17:10</th>
  <td>10:50～</td>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>16:10～</td>
  <td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">11</th>
  <th rowspan="2">11:00～<br class="sp_only">15:40</th>
  <td>11:00～</td>
  <td>11:20～<br class="sp_only">11:50</td>
  <td>14:20～</td>
  <td>14:40～<br class="sp_only">15:10</td>
  <td>15:10～<br class="sp_only">15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">12</th>
  <th rowspan="2">11:00～<br class="sp_only">16:20</th>
  <td>11:00～</td>
  <td>11:20～<br class="sp_only">11:50</td>
  <td>15:00～</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>15:50～<br class="sp_only">16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">13</th>
  <th rowspan="2">11:00～<br class="sp_only">17:50</th>
  <td>11:00～</td>
  <td>11:20～<br class="sp_only">11:50</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">14</th>
  <th rowspan="2">11:10～<br class="sp_only">15:30</th>
  <td>11:10～</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>14:10～</td>
  <td>14:30～<br class="sp_only">15:00</td>
  <td>15:00～<br class="sp_only">15:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">15</th>
  <th rowspan="2">11:20～<br class="sp_only">15:00</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>14:00～</td>
  <td>14:20～<br class="sp_only">14:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">16</th>
  <th rowspan="2">11:20～<br class="sp_only">16:50</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>15:30～</td>
  <td>15:50～<br class="sp_only">16:20</td>
  <td>16:20～<br class="sp_only">16:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">17</th>
  <th rowspan="2">11:20～<br class="sp_only">17:10</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>15:50～</td>
  <td>16:10～<br class="sp_only">16:40</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">18</th>
  <th rowspan="2">11:30～<br class="sp_only">15:00</th>
  <td>11:30～</td>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>14:00～</td>
  <td>14:20～<br class="sp_only">14:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">19</th>
  <th rowspan="2">11:30～<br class="sp_only">16:00</th>
  <td>11:30～</td>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>14:40～</td>
  <td>15:00～<br class="sp_only">15:30</td>
  <td>15:30～<br class="sp_only">16:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">20</th>
  <th rowspan="2">11:30～<br class="sp_only">16:30</th>
  <td>11:30～</td>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>15:30～</td>
  <td>15:50～<br class="sp_only">16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">21</th>
  <th rowspan="2">11:30～<br class="sp_only">16:30</th>
  <td>11:30～</td>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>15:10～</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>16:00～<br class="sp_only">16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">22</th>
  <th rowspan="2">11:40～<br class="sp_only">15:00</th>
  <td>11:40～</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>14:00～</td>
  <td>14:20～<br class="sp_only">14:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">23</th>
  <th rowspan="2">11:40～<br class="sp_only">16:40</th>
  <td>11:40～</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>15:20～</td>
  <td>15:40～<br class="sp_only">16:10</td>
  <td>16:10～<br class="sp_only">16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">24</th>
  <th rowspan="2">11:40～<br class="sp_only">17:40</th>
  <td>11:40～</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>16:20～</td>
  <td>16:40～<br class="sp_only">17:10</td>
  <td>17:10～<br class="sp_only">17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">25</th>
  <th rowspan="2">11:50～<br class="sp_only">16:30</th>
  <td>11:50～</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>15:10～</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>16:00～<br class="sp_only">16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">26</th>
  <th rowspan="2">11:50～<br class="sp_only">17:30</th>
  <td>11:50～</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>16:10～</td>
  <td>16:30～<br class="sp_only">17:00</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">27</th>
  <th rowspan="2">12:00～<br class="sp_only">16:40</th>
  <td>12:00～</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>15:20～</td>
  <td>15:40～<br class="sp_only">16:10</td>
  <td>16:10～<br class="sp_only">16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">28</th>
  <th rowspan="2">12:10～<br class="sp_only">17:00</th>
  <td>12:10～</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
  <td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">29</th>
  <th rowspan="2">12:10～<br class="sp_only">17:10</th>
  <td>12:10～</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>15:50～</td>
  <td>16:10～<br class="sp_only">16:40</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">30</th>
  <th rowspan="2">12:20～<br class="sp_only">15:40</th>
  <td>12:20～</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>14:20～</td>
  <td>14:40～<br class="sp_only">15:10</td>
  <td>15:10～<br class="sp_only">15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">31</th>
  <th rowspan="2">12:20～<br class="sp_only">17:20</th>
  <td>12:20～</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>16:00～</td>
  <td>16:20～<br class="sp_only">16:50</td>
  <td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">32</th>
  <th rowspan="2">12:20～<br class="sp_only">17:20</th>
  <td>12:20～</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>16:20～</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">33</th>
  <th rowspan="2">12:30～<br class="sp_only">15:40</th>
  <td>12:30～</td>
  <td>12:50～<br class="sp_only">13:20</td>
  <td>14:20～</td>
  <td>14:40～<br class="sp_only">15:10</td>
  <td>15:10～<br class="sp_only">15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">34</th>
  <th rowspan="2">12:30～<br class="sp_only">16:00</th>
  <td>12:30～</td>
  <td>12:50～<br class="sp_only">13:20</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>15:00～</td>
  <td>15:20～<br class="sp_only">15:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">35</th>
  <th rowspan="2">12:50～<br class="sp_only">16:00</th>
  <td>12:50～</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>13:40～<br class="sp_only">14:10</td>
  <td>15:00～</td>
  <td>15:20～<br class="sp_only">15:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">36</th>
  <th rowspan="2">13:00～<br class="sp_only">17:00</th>
  <td>13:00～</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
  <td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">37</th>
  <th rowspan="2">13:30～<br class="sp_only">16:50</th>
  <td>13:30～</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>15:30～</td>
  <td>15:50～<br class="sp_only">16:20</td>
  <td>16:20～<br class="sp_only">16:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">38</th>
  <th rowspan="2">13:30～<br class="sp_only">17:00</th>
  <td>13:30～</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>16:00～</td>
  <td>16:20～<br class="sp_only">16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">39</th>
  <th rowspan="2">13:50～<br class="sp_only">16:40</th>
  <td>13:50～</td>
  <td>14:10～<br class="sp_only">14:40</td>
  <td>14:40～<br class="sp_only">15:10</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">40</th>
  <th rowspan="2">14:00～<br class="sp_only">16:50</th>
  <td>14:00～</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>15:50～</td>
  <td>16:10～<br class="sp_only">16:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">41</th>
  <th rowspan="2">14:00～<br class="sp_only">17:00</th>
  <td>14:00～</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>16:00～</td>
  <td>16:20～<br class="sp_only">16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">42</th>
  <th rowspan="2">14:00～<br class="sp_only">17:00</th>
  <td>14:00～</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
  <td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">43</th>
  <th rowspan="2">14:20～<br class="sp_only">18:00</th>
  <td>14:20～</td>
  <td>14:40～<br class="sp_only">15:10</td>
  <td>15:10～<br class="sp_only">15:40</td>
  <td>17:00～</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">44</th>
  <th rowspan="2">14:30～<br class="sp_only">17:30</th>
  <td>14:30～</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">45</th>
  <th rowspan="2">14:30～<br class="sp_only">17:50</th>
  <td>14:30～</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>16:50～</td>
  <td>17:10～<br class="sp_only">17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">46</th>
  <th rowspan="2">14:30～<br class="sp_only">18:00</th>
  <td>14:30～</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>16:40～</td>
  <td>17:00～<br class="sp_only">17:30</td>
  <td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">47</th>
  <th rowspan="2">14:30～<br class="sp_only">18:00</th>
  <td>14:30～</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>17:00～</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">48</th>
  <th rowspan="2">14:40～<br class="sp_only">17:40</th>
  <td>14:40～</td>
  <td>15:00～<br class="sp_only">15:30</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>16:40～</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">49</th>
  <th rowspan="2">14:50～<br class="sp_only">17:40</th>
  <td>14:50～</td>
  <td>15:10～<br class="sp_only">15:40</td>
  <td>16:20～</td>
  <td>16:40～<br class="sp_only">17:10</td>
  <td>17:10～<br class="sp_only">17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">50</th>
  <th rowspan="2">15:00～<br class="sp_only">17:50</th>
  <td>15:00～</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>15:50～<br class="sp_only">16:20</td>
  <td>16:50～</td>
  <td>17:10～<br class="sp_only">17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>

                <!-- ↑挿入箇所↑ -->
              </tbody>
      </table>
    </div>

    <div v-if="productId === 2" class="table_content current">
      <schedule-detail2 />
    </div>

    <div v-if="productId === 3" class="table_content current">
      <div>
        <p class="content">Harry Potter and the Forbidden Journey ™ will be closed from Monday, May 10th to Sunday, May 16th.</p>
        <p class="content">If you are using a time-designated Studio Pass (such as those that permit entry after 3 p.m.), or an Express Pass, and you plan to participate in a time-designated event, there are time slots in which you will not be able to participate. </p>
        <p class="content">When you are deciding when to participate, make sure the times do not overlap.</p>
        <p class="content">Availability may be limited depending on the entry date. Please check the availability calendar in advance.</p>
        <!-- <p class="content">It may be sold out. Also, depending on the admission date, there may be times when it is not sold.</p> -->
      </div>
      <el-row class="btn_box">
          <div href="session" @click="schedule = '1'" class="select_designated_time_btn" :class="{'btn-active': schedule === '1'}">
            Admission date：～May 31st
            <img src="@/assets/down-icon.png" alt="">
          </div>
          <div href="session" @click="schedule = '2'" class="select_designated_time_btn" :class="{'btn-active': schedule === '2'}">
            Admission date：June 1st～July 1st
            <img src="@/assets/down-icon.png" alt="">
          </div>
      </el-row>
      <table v-if="schedule === '1'" class="cmn_att_time01 cmn_tbl01">
              <caption>Admission date：～May 31st</caption>
              <colgroup>
              <col class="w100">
              <col>
              </colgroup>
              <thead>
                <tr>
                  <th colspan="8" class="txtleft">▼ View time at left side</th>
                </tr>
              </thead>
              <tbody>
                <!-- ↓挿入箇所↓ -->

<tr>
  <th rowspan="2">1～2</th>
  <th rowspan="2">10:00～<br class="sp_only">13:10</th>
  <td>10:00～</td>
  <td>10:20～<br class="sp_only">10:50</td>
  <td>10:50～<br class="sp_only">11:20</td>
  <td>11:50～</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>12:40～<br class="sp_only">13:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">3</th>
  <th rowspan="2">10:00～<br class="sp_only">14:50</th>
  <td>10:00～</td>
  <td>10:20～<br class="sp_only">10:50</td>
  <td>10:50～<br class="sp_only">11:20</td>
  <td>13:30～</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>14:20～<br class="sp_only">14:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">4</th>
  <th rowspan="2">10:00～<br class="sp_only">15:00</th>
  <td>10:00～</td>
  <td>10:20～<br class="sp_only">10:50</td>
  <td>10:50～<br class="sp_only">11:20</td>
  <td>13:40～</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>14:30～<br class="sp_only">15:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">5</th>
  <th rowspan="2">10:00～<br class="sp_only">16:40</th>
  <td>10:00～</td>
  <td>10:20～<br class="sp_only">10:50</td>
  <td>10:50～<br class="sp_only">11:20</td>
  <td>15:20～</td>
  <td>15:40～<br class="sp_only">16:10</td>
  <td>16:10～<br class="sp_only">16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">6</th>
  <th rowspan="2">10:00～<br class="sp_only">16:50</th>
  <td>10:00～</td>
  <td>10:20～<br class="sp_only">10:50</td>
  <td>10:50～<br class="sp_only">11:20</td>
  <td>15:30～</td>
  <td>15:50～<br class="sp_only">16:20</td>
  <td>16:20～<br class="sp_only">16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">7</th>
  <th rowspan="2">10:10～<br class="sp_only">16:10</th>
  <td>10:10～</td>
  <td>10:30～<br class="sp_only">11:00</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>14:50～</td>
  <td>15:10～<br class="sp_only">15:40</td>
  <td>15:40～<br class="sp_only">16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">8</th>
  <th rowspan="2">10:10～<br class="sp_only">16:20</th>
  <td>10:10～</td>
  <td>10:30～<br class="sp_only">11:00</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>15:00～</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>15:50～<br class="sp_only">16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">9</th>
  <th rowspan="2">10:10～<br class="sp_only">17:30</th>
  <td>10:10～</td>
  <td>10:30～<br class="sp_only">11:00</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>16:10～</td>
  <td>16:30～<br class="sp_only">17:00</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">10</th>
  <th rowspan="2">10:10～<br class="sp_only">17:40</th>
  <td>10:10～</td>
  <td>10:30～<br class="sp_only">11:00</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>16:20～</td>
  <td>16:40～<br class="sp_only">17:10</td>
  <td>17:10～<br class="sp_only">17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">11</th>
  <th rowspan="2">10:20～<br class="sp_only">13:30</th>
  <td>10:20～</td>
  <td>10:40～<br class="sp_only">11:10</td>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>12:10～</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>13:00～<br class="sp_only">13:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">12</th>
  <th rowspan="2">10:20～<br class="sp_only">15:30</th>
  <td>10:20～</td>
  <td>10:40～<br class="sp_only">11:10</td>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>14:10～</td>
  <td>14:30～<br class="sp_only">15:00</td>
  <td>15:00～<br class="sp_only">15:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">13</th>
  <th rowspan="2">10:20～<br class="sp_only">16:10</th>
  <td>10:20～</td>
  <td>10:40～<br class="sp_only">11:10</td>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>14:50～</td>
  <td>15:10～<br class="sp_only">15:40</td>
  <td>15:40～<br class="sp_only">16:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">14</th>
  <th rowspan="2">10:20～<br class="sp_only">17:20</th>
  <td>10:20～</td>
  <td>10:40～<br class="sp_only">11:10</td>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>16:00～</td>
  <td>16:20～<br class="sp_only">16:50</td>
  <td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">15</th>
  <th rowspan="2">10:20～<br class="sp_only">17:50</th>
  <td>10:20～</td>
  <td>10:40～<br class="sp_only">11:10</td>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>

  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">16</th>
  <th rowspan="2">10:20～<br class="sp_only">18:00</th>
  <td>10:20～</td>
  <td>10:40～<br class="sp_only">11:10</td>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>16:40～</td>
  <td>17:00～<br class="sp_only">17:30</td>
  <td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">17</th>
  <th rowspan="2">10:30～<br class="sp_only">13:40</th>
  <td>10:30～</td>
  <td>10:50～<br class="sp_only">11:20</td>
  <td>11:20～<br class="sp_only">11:50</td>
  <td>12:20～</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>13:10～<br class="sp_only">13:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">18</th>
  <th rowspan="2">10:30～<br class="sp_only">17:10</th>
  <td>10:30～</td>
  <td>10:50～<br class="sp_only">11:20</td>
  <td>11:20～<br class="sp_only">11:50</td>
  <td>15:50～</td>
  <td>16:10～<br class="sp_only">16:40</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">19</th>
  <th rowspan="2">10:30～<br class="sp_only">17:50</th>
  <td>10:30～</td>
  <td>10:50～<br class="sp_only">11:20</td>
  <td>11:20～<br class="sp_only">11:50</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">20</th>
  <th rowspan="2">10:40～<br class="sp_only">14:40</th>
  <td>10:40～</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>13:20～</td>
  <td>13:40～<br class="sp_only">14:10</td>
  <td>14:10～<br class="sp_only">14:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">21～23</th>
  <th rowspan="2">10:40～<br class="sp_only">15:40</th>
  <td>10:40～</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>14:20～</td>
  <td>14:40～<br class="sp_only">15:10</td>
  <td>15:10～<br class="sp_only">15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">24</th>
  <th rowspan="2">10:40～<br class="sp_only">16:00</th>
  <td>10:40～</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>14:40～</td>
  <td>15:00～<br class="sp_only">15:30</td>
  <td>15:30～<br class="sp_only">16:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">25</th>
  <th rowspan="2">10:40～<br class="sp_only">16:10</th>
  <td>10:40～</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>14:50～</td>
  <td>15:10～<br class="sp_only">15:40</td>
  <td>15:40～<br class="sp_only">16:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">26</th>
  <th rowspan="2">10:40～<br class="sp_only">16:30</th>
  <td>10:40～</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>15:10～</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>16:00～<br class="sp_only">16:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">27</th>
  <th rowspan="2">10:40～<br class="sp_only">17:00</th>
  <td>10:40～</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
  <td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">28</th>
  <th rowspan="2">10:40～<br class="sp_only">17:30</th>
  <td>10:40～</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>16:10～</td>
  <td>16:30～<br class="sp_only">17:00</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">29</th>
  <th rowspan="2">10:40～<br class="sp_only">17:40</th>
  <td>10:40～</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>16:20～</td>
  <td>16:40～<br class="sp_only">17:10</td>
  <td>17:10～<br class="sp_only">17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">30</th>
  <th rowspan="2">10:40～<br class="sp_only">18:40</th>
  <td>10:40～</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>17:20～</td>
  <td>17:40～<br class="sp_only">18:10</td>
  <td>18:10～<br class="sp_only">18:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">31</th>
  <th rowspan="2">10:50～<br class="sp_only">14:50</th>
  <td>10:50～</td>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>13:30～</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>14:20～<br class="sp_only">14:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">32</th>
  <th rowspan="2">10:50～<br class="sp_only">16:40</th>
  <td>10:50～</td>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>15:20～</td>
  <td>15:40～<br class="sp_only">16:10</td>
  <td>16:10～<br class="sp_only">16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">33</th>
  <th rowspan="2">10:50～<br class="sp_only">17:50</th>
  <td>10:50～</td>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">34</th>
  <th rowspan="2">11:10～<br class="sp_only">15:10</th>
  <td>11:10～</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>13:50～</td>
  <td>14:10～<br class="sp_only">14:40</td>
  <td>14:40～<br class="sp_only">15:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">35</th>
  <th rowspan="2">11:10～<br class="sp_only">15:20</th>
  <td>11:10～</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>14:00～</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>14:50～<br class="sp_only">15:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">36</th>
  <th rowspan="2">11:10～<br class="sp_only">15:50</th>
  <td>11:10～</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>14:30～</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>15:20～<br class="sp_only">15:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">37</th>
  <th rowspan="2">11:10～<br class="sp_only">16:00</th>
  <td>11:10～</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>14:40～</td>
  <td>15:00～<br class="sp_only">15:30</td>
  <td>15:30～<br class="sp_only">16:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">38～39</th>
  <th rowspan="2">11:10～<br class="sp_only">16:50</th>
  <td>11:10～</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>15:30～</td>
  <td>15:50～<br class="sp_only">16:20</td>
  <td>16:20～<br class="sp_only">16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">40</th>
  <th rowspan="2">11:10～<br class="sp_only">17:00</th>
  <td>11:10～</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
  <td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">41</th>
  <th rowspan="2">11:10～<br class="sp_only">17:10</th>
  <td>11:10～</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>15:50～</td>
  <td>16:10～<br class="sp_only">16:40</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">42</th>
  <th rowspan="2">11:10～<br class="sp_only">18:00</th>
  <td>11:10～</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>16:40～</td>
  <td>17:00～<br class="sp_only">17:30</td>
  <td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">43</th>
  <th rowspan="2">11:20～<br class="sp_only">14:40</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>13:20～</td>
  <td>13:40～<br class="sp_only">14:10</td>
  <td>14:10～<br class="sp_only">14:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">44</th>
  <th rowspan="2">11:20～<br class="sp_only">15:40</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>14:20～</td>
  <td>14:40～<br class="sp_only">15:10</td>
  <td>15:10～<br class="sp_only">15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">45</th>
  <th rowspan="2">11:20～<br class="sp_only">16:00</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>14:40～</td>
  <td>15:00～<br class="sp_only">15:30</td>
  <td>15:30～<br class="sp_only">16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">46</th>
  <th rowspan="2">11:20～<br class="sp_only">16:20</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>15:00～</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>15:50～<br class="sp_only">16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">47</th>
  <th rowspan="2">11:20～<br class="sp_only">16:30</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>15:10～</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>16:00～<br class="sp_only">16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">48</th>
  <th rowspan="2">11:20～<br class="sp_only">16:40</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>15:20～</td>
  <td>15:40～<br class="sp_only">16:10</td>
  <td>16:10～<br class="sp_only">16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">49</th>
  <th rowspan="2">11:20～<br class="sp_only">17:10</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>15:50～</td>
  <td>16:10～<br class="sp_only">16:40</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">50</th>
  <th rowspan="2">11:20～<br class="sp_only">17:10</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>15:50～</td>
  <td>16:10～<br class="sp_only">16:40</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">51</th>
  <th rowspan="2">11:20～<br class="sp_only">17:40</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>16:20～</td>
  <td>16:40～<br class="sp_only">17:10</td>
  <td>17:10～<br class="sp_only">17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">52</th>
  <th rowspan="2">11:20～<br class="sp_only">17:50</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">53</th>
  <th rowspan="2">11:20～<br class="sp_only">18:00</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>16:40～</td>
  <td>17:00～<br class="sp_only">17:30</td>
  <td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">54～55</th>
  <th rowspan="2">11:30～<br class="sp_only">14:40</th>
  <td>11:30～</td>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>13:20～</td>
  <td>13:40～<br class="sp_only">14:10</td>
  <td>14:10～<br class="sp_only">14:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">56</th>
  <th rowspan="2">11:30～<br class="sp_only">15:10</th>
  <td>11:30～</td>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>13:50～</td>
  <td>14:10～<br class="sp_only">14:40</td>
  <td>14:40～<br class="sp_only">15:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">57</th>
  <th rowspan="2">11:30～<br class="sp_only">15:20</th>
  <td>11:30～</td>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>14:00～</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>14:50～<br class="sp_only">15:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">58</th>
  <th rowspan="2">11:30～<br class="sp_only">15:50</th>
  <td>11:30～</td>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>14:30～</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>15:20～<br class="sp_only">15:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">59</th>
  <th rowspan="2">11:30～<br class="sp_only">16:10</th>
  <td>11:30～</td>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>14:50～</td>
  <td>15:10～<br class="sp_only">15:40</td>
  <td>15:40～<br class="sp_only">16:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">60～61</th>
  <th rowspan="2">11:30～<br class="sp_only">16:20</th>
  <td>11:30～</td>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>15:00～</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>15:50～<br class="sp_only">16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">62～63</th>
  <th rowspan="2">11:30～<br class="sp_only">16:40</th>
  <td>11:30～</td>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>15:20～</td>
  <td>15:40～<br class="sp_only">16:10</td>
  <td>16:10～<br class="sp_only">16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">64</th>
  <th rowspan="2">11:30～<br class="sp_only">17:30</th>
  <td>11:30～</td>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>16:10～</td>
  <td>16:30～<br class="sp_only">17:00</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">65</th>
  <th rowspan="2">11:40～<br class="sp_only">14:50</th>
  <td>11:40～</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>13:30～</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>14:20～<br class="sp_only">14:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">66～67</th>
  <th rowspan="2">11:40～<br class="sp_only">15:00</th>
  <td>11:40～</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>13:40～</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>14:30～<br class="sp_only">15:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">68</th>
  <th rowspan="2">11:40～<br class="sp_only">15:20</th>
  <td>11:40～</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>14:00～</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>14:50～<br class="sp_only">15:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">69～70</th>
  <th rowspan="2">11:40～<br class="sp_only">16:30</th>
  <td>11:40～</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>15:10～</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>16:00～<br class="sp_only">16:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">71</th>
  <th rowspan="2">11:40～<br class="sp_only">16:40</th>
  <td>11:40～</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>15:20～</td>
  <td>15:40～<br class="sp_only">16:10</td>
  <td>16:10～<br class="sp_only">16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">72～73</th>
  <th rowspan="2">11:40～<br class="sp_only">17:00</th>
  <td>11:40～</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
  <td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">74</th>
  <th rowspan="2">11:40～<br class="sp_only">17:30</th>
  <td>11:40～</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>16:10～</td>
  <td>16:30～<br class="sp_only">17:00</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">75</th>
  <th rowspan="2">11:50～<br class="sp_only">15:10</th>
  <td>11:50～</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>13:50～</td>
  <td>14:10～<br class="sp_only">14:40</td>
  <td>14:40～<br class="sp_only">15:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">76</th>
  <th rowspan="2">11:50～<br class="sp_only">15:50</th>
  <td>11:50～</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>14:30～</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>15:20～<br class="sp_only">15:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">77</th>
  <th rowspan="2">11:50～<br class="sp_only">15:50</th>
  <td>11:50～</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>14:30～</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>15:20～<br class="sp_only">15:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">78</th>
  <th rowspan="2">11:50～<br class="sp_only">16:20</th>
  <td>11:50～</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>15:00～</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>15:50～<br class="sp_only">16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">79</th>
  <th rowspan="2">11:50～<br class="sp_only">16:20</th>
  <td>11:50～</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>15:00～</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>15:50～<br class="sp_only">16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">80</th>
  <th rowspan="2">11:50～<br class="sp_only">16:50</th>
  <td>11:50～</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>15:30～</td>
  <td>15:50～<br class="sp_only">16:20</td>
  <td>16:20～<br class="sp_only">16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">81</th>
  <th rowspan="2">11:50～<br class="sp_only">17:20</th>
  <td>11:50～</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>16:00～</td>
  <td>16:20～<br class="sp_only">16:50</td>
  <td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">82</th>
  <th rowspan="2">11:50～<br class="sp_only">17:30</th>
  <td>11:50～</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>16:10～</td>
  <td>16:30～<br class="sp_only">17:00</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">83</th>
  <th rowspan="2">12:00～<br class="sp_only">16:30</th>
  <td>12:00～</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>12:50～<br class="sp_only">13:20</td>
  <td>15:10～</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>16:00～<br class="sp_only">16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">84</th>
  <th rowspan="2">12:00～<br class="sp_only">17:00</th>
  <td>12:00～</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>12:50～<br class="sp_only">13:20</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
  <td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">85</th>
  <th rowspan="2">12:00～<br class="sp_only">17:10</th>
  <td>12:00～</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>12:50～<br class="sp_only">13:20</td>
  <td>15:50～</td>
  <td>16:10～<br class="sp_only">16:40</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">86</th>
  <th rowspan="2">12:00～<br class="sp_only">17:40</th>
  <td>12:00～</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>12:50～<br class="sp_only">13:20</td>
  <td>16:20～</td>
  <td>16:40～<br class="sp_only">17:10</td>
  <td>17:10～<br class="sp_only">17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">87</th>
  <th rowspan="2">12:10～<br class="sp_only">15:30</th>
  <td>12:10～</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>14:10～</td>
  <td>14:30～<br class="sp_only">15:00</td>
  <td>15:00～<br class="sp_only">15:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">88</th>
  <th rowspan="2">12:10～<br class="sp_only">15:30</th>
  <td>12:10～</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>14:10～</td>
  <td>14:30～<br class="sp_only">15:00</td>
  <td>15:00～<br class="sp_only">15:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">89</th>
  <th rowspan="2">12:10～<br class="sp_only">15:30</th>
  <td>12:10～</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>14:10～</td>
  <td>14:30～<br class="sp_only">15:00</td>
  <td>15:00～<br class="sp_only">15:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">90～92</th>
  <th rowspan="2">12:10～<br class="sp_only">16:10</th>
  <td>12:10～</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>14:50～</td>
  <td>15:10～<br class="sp_only">15:40</td>
  <td>15:40～<br class="sp_only">16:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">93～94</th>
  <th rowspan="2">12:10～<br class="sp_only">16:50</th>
  <td>12:10～</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>15:30～</td>
  <td>15:50～<br class="sp_only">16:20</td>
  <td>16:20～<br class="sp_only">16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">95～96</th>
  <th rowspan="2">12:10～<br class="sp_only">17:00</th>
  <td>12:10～</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
  <td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">97</th>
  <th rowspan="2">12:10～<br class="sp_only">17:00</th>
  <td>12:10～</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
  <td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">98</th>
  <th rowspan="2">12:10～<br class="sp_only">17:50</th>
  <td>12:10～</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">99</th>
  <th rowspan="2">12:20～<br class="sp_only">15:40</th>
  <td>12:20～</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>14:20～</td>
  <td>14:40～<br class="sp_only">15:10</td>
  <td>15:10～<br class="sp_only">15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">100</th>
  <th rowspan="2">12:20～<br class="sp_only">16:00</th>
  <td>12:20～</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>14:40～</td>
  <td>15:00～<br class="sp_only">15:30</td>
  <td>15:30～<br class="sp_only">16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">101</th>
  <th rowspan="2">12:20～<br class="sp_only">16:10</th>
  <td>12:20～</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>14:50～</td>
  <td>15:10～<br class="sp_only">15:40</td>
  <td>15:40～<br class="sp_only">16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">102</th>
  <th rowspan="2">12:20～<br class="sp_only">16:20</th>
  <td>12:20～</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>15:00～</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>15:50～<br class="sp_only">16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">103</th>
  <th rowspan="2">12:20～<br class="sp_only">16:30</th>
  <td>12:20～</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>15:10～</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>16:00～<br class="sp_only">16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">104</th>
  <th rowspan="2">12:20～<br class="sp_only">16:50</th>
  <td>12:20～</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>15:30～</td>
  <td>15:50～<br class="sp_only">16:20</td>
  <td>16:20～<br class="sp_only">16:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">105</th>
  <th rowspan="2">12:20～<br class="sp_only">17:10</th>
  <td>12:20～</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>15:50～</td>
  <td>16:10～<br class="sp_only">16:40</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">106</th>
  <th rowspan="2">12:20～<br class="sp_only">17:20</th>
  <td>12:20～</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>16:00～</td>
  <td>16:20～<br class="sp_only">16:50</td>
  <td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">107</th>
  <th rowspan="2">12:20～<br class="sp_only">17:20</th>
  <td>12:20～</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>16:00～</td>
  <td>16:20～<br class="sp_only">16:50</td>
  <td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">108</th>
  <th rowspan="2">12:20～<br class="sp_only">18:20</th>
  <td>12:20～</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>17:00～</td>
  <td>17:20～<br class="sp_only">17:50</td>
  <td>17:50～<br class="sp_only">18:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">109</th>
  <th rowspan="2">12:20～<br class="sp_only">19:00</th>
  <td>12:20～</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>17:40～</td>
  <td>18:00～<br class="sp_only">18:30</td>
  <td>18:30～<br class="sp_only">19:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">110</th>
  <th rowspan="2">12:30～<br class="sp_only">16:00</th>
  <td>12:30～</td>
  <td>12:50～<br class="sp_only">13:20</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>14:40～</td>
  <td>15:00～<br class="sp_only">15:30</td>
  <td>15:30～<br class="sp_only">16:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">111</th>
  <th rowspan="2">12:30～<br class="sp_only">16:50</th>
  <td>12:30～</td>
  <td>12:50～<br class="sp_only">13:20</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>15:30～</td>
  <td>15:50～<br class="sp_only">16:20</td>
  <td>16:20～<br class="sp_only">16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">112</th>
  <th rowspan="2">12:30～<br class="sp_only">17:20</th>
  <td>12:30～</td>
  <td>12:50～<br class="sp_only">13:20</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>16:00～</td>
  <td>16:20～<br class="sp_only">16:50</td>
  <td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">113～114</th>
  <th rowspan="2">12:40～<br class="sp_only">15:50</th>
  <td>12:40～</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>14:30～</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>15:20～<br class="sp_only">15:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">115</th>
  <th rowspan="2">12:40～<br class="sp_only">16:00</th>
  <td>12:40～</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>14:40～</td>
  <td>15:00～<br class="sp_only">15:30</td>
  <td>15:30～<br class="sp_only">16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">116</th>
  <th rowspan="2">12:40～<br class="sp_only">16:50</th>
  <td>12:40～</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>15:30～</td>
  <td>15:50～<br class="sp_only">16:20</td>
  <td>16:20～<br class="sp_only">16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">117</th>
  <th rowspan="2">12:40～<br class="sp_only">17:20</th>
  <td>12:40～</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>16:00～</td>
  <td>16:20～<br class="sp_only">16:50</td>
  <td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">118</th>
  <th rowspan="2">12:40～<br class="sp_only">17:30</th>
  <td>12:40～</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>16:10～</td>
  <td>16:30～<br class="sp_only">17:00</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">119</th>
  <th rowspan="2">12:40～<br class="sp_only">18:10</th>
  <td>12:40～</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>16:50～</td>
  <td>17:10～<br class="sp_only">17:40</td>
  <td>17:40～<br class="sp_only">18:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">120</th>
  <th rowspan="2">13:00～<br class="sp_only">16:10</th>
  <td>13:00～</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>14:50～</td>
  <td>15:10～<br class="sp_only">15:40</td>
  <td>15:40～<br class="sp_only">16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">121</th>
  <th rowspan="2">13:00～<br class="sp_only">16:20</th>
  <td>13:00～</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>15:00～</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>15:50～<br class="sp_only">16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">122</th>
  <th rowspan="2">13:00～<br class="sp_only">17:10</th>
  <td>13:00～</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>15:50～</td>
  <td>16:10～<br class="sp_only">16:40</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">123</th>
  <th rowspan="2">13:10～<br class="sp_only">16:20</th>
  <td>13:10～</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>15:00～</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>15:50～<br class="sp_only">16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">124</th>
  <th rowspan="2">13:10～<br class="sp_only">16:20</th>
  <td>13:10～</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>15:00～</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>15:50～<br class="sp_only">16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">125</th>
  <th rowspan="2">13:10～<br class="sp_only">16:50</th>
  <td>13:10～</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>15:30～</td>
  <td>15:50～<br class="sp_only">16:20</td>
  <td>16:20～<br class="sp_only">16:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">126</th>
  <th rowspan="2">13:10～<br class="sp_only">17:00</th>
  <td>13:10～</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
  <td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">127</th>
  <th rowspan="2">13:10～<br class="sp_only">18:30</th>
  <td>13:10～</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>17:10～</td>
  <td>17:30～<br class="sp_only">18:00</td>
  <td>18:00～<br class="sp_only">18:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">128</th>
  <th rowspan="2">13:20～<br class="sp_only">16:30</th>
  <td>13:20～</td>
  <td>13:40～<br class="sp_only">14:10</td>
  <td>14:10～<br class="sp_only">14:40</td>
  <td>15:10～</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>16:00～<br class="sp_only">16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">129</th>
  <th rowspan="2">13:20～<br class="sp_only">17:00</th>
  <td>13:20～</td>
  <td>13:40～<br class="sp_only">14:10</td>
  <td>14:10～<br class="sp_only">14:40</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
  <td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">130</th>
  <th rowspan="2">13:20～<br class="sp_only">17:30</th>
  <td>13:20～</td>
  <td>13:40～<br class="sp_only">14:10</td>
  <td>14:10～<br class="sp_only">14:40</td>
  <td>16:10～</td>
  <td>16:30～<br class="sp_only">17:00</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">131</th>
  <th rowspan="2">13:20～<br class="sp_only">18:10</th>
  <td>13:20～</td>
  <td>13:40～<br class="sp_only">14:10</td>
  <td>14:10～<br class="sp_only">14:40</td>
  <td>16:50～</td>
  <td>17:10～<br class="sp_only">17:40</td>
  <td>17:40～<br class="sp_only">18:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">132</th>
  <th rowspan="2">13:30～<br class="sp_only">16:50</th>
  <td>13:30～</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>15:30～</td>
  <td>15:50～<br class="sp_only">16:20</td>
  <td>16:20～<br class="sp_only">16:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">133</th>
  <th rowspan="2">13:30～<br class="sp_only">17:00</th>
  <td>13:30～</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
  <td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">134</th>
  <th rowspan="2">13:30～<br class="sp_only">21:00</th>
  <td>13:30～</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>19:40～</td>
  <td>20:00～<br class="sp_only">20:30</td>
  <td>20:30～<br class="sp_only">21:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">135</th>
  <th rowspan="2">13:40～<br class="sp_only">17:00</th>
  <td>13:40～</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>14:30～<br class="sp_only">15:00</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
  <td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">136</th>
  <th rowspan="2">13:40～<br class="sp_only">17:10</th>
  <td>13:40～</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>14:30～<br class="sp_only">15:00</td>
  <td>15:50～</td>
  <td>16:10～<br class="sp_only">16:40</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">137</th>
  <th rowspan="2">13:50～<br class="sp_only">17:30</th>
  <td>13:50～</td>
  <td>14:10～<br class="sp_only">14:40</td>
  <td>14:40～<br class="sp_only">15:10</td>
  <td>16:10～</td>
  <td>16:30～<br class="sp_only">17:00</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">138</th>
  <th rowspan="2">14:00～<br class="sp_only">17:30</th>
  <td>14:00～</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>16:10～</td>
  <td>16:30～<br class="sp_only">17:00</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">139</th>
  <th rowspan="2">14:00～<br class="sp_only">17:50</th>
  <td>14:00～</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">140</th>
  <th rowspan="2">14:00～<br class="sp_only">18:00</th>
  <td>14:00～</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>16:40～</td>
  <td>17:00～<br class="sp_only">17:30</td>
  <td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">141</th>
  <th rowspan="2">14:10～<br class="sp_only">17:20</th>
  <td>14:10～</td>
  <td>14:30～<br class="sp_only">15:00</td>
  <td>15:00～<br class="sp_only">15:30</td>
  <td>16:00～</td>
  <td>16:20～<br class="sp_only">16:50</td>
  <td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">142</th>
  <th rowspan="2">14:10～<br class="sp_only">17:30</th>
  <td>14:10～</td>
  <td>14:30～<br class="sp_only">15:00</td>
  <td>15:00～<br class="sp_only">15:30</td>
  <td>16:10～</td>
  <td>16:30～<br class="sp_only">17:00</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">143</th>
  <th rowspan="2">14:20～<br class="sp_only">18:40</th>
  <td>14:20～</td>
  <td>14:40～<br class="sp_only">15:10</td>
  <td>15:10～<br class="sp_only">15:40</td>
  <td>17:20～</td>
  <td>17:40～<br class="sp_only">18:10</td>
  <td>18:10～<br class="sp_only">18:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">144</th>
  <th rowspan="2">14:30～<br class="sp_only">17:50</th>
  <td>14:30～</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">145～146</th>
  <th rowspan="2">14:30～<br class="sp_only">17:50</th>
  <td>14:30～</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">147</th>
  <th rowspan="2">14:30～<br class="sp_only">18:30</th>
  <td>14:30～</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>17:10～</td>
  <td>17:30～<br class="sp_only">18:00</td>
  <td>18:00～<br class="sp_only">18:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">148</th>
  <th rowspan="2">15:00～<br class="sp_only">18:50</th>
  <td>15:00～</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>15:50～<br class="sp_only">16:20</td>
  <td>17:30～</td>
  <td>17:50～<br class="sp_only">18:20</td>
  <td>18:20～<br class="sp_only">18:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">149</th>
  <th rowspan="2">15:30～<br class="sp_only">18:40</th>
  <td>15:30～</td>
  <td>15:50～<br class="sp_only">16:20</td>
  <td>16:20～<br class="sp_only">16:50</td>
  <td>17:20～</td>
  <td>17:40～<br class="sp_only">18:10</td>
  <td>18:10～<br class="sp_only">18:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>

                <!-- ↑挿入箇所↑ -->
              </tbody>
      </table>
      <table v-if="schedule === '2'" class="cmn_att_time01 cmn_tbl01">
              <caption>Admission date：June 1st～July 1st</caption>
              <colgroup>
              <col class="w100">
              <col>
              </colgroup>
              <thead>
                <tr>
                  <th colspan="8" class="txtleft">▼ View time at left side</th>
                </tr>
              </thead>
              <tbody>
                <!-- ↓挿入箇所↓ -->

<tr class="first-child">
  <th rowspan="2">1</th>
  <th rowspan="2">10:00～<br class="sp_only">13:10</th>
  <td>10:00～</td>
  <td>10:20～<br class="sp_only">10:50</td>
  <td>10:50～<br class="sp_only">11:20</td>
  <td>11:50～</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>12:40～<br class="sp_only">13:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">2</th>
  <th rowspan="2">10:10～<br class="sp_only">13:20</th>
  <td>10:10～</td>
  <td>10:30～<br class="sp_only">11:00</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>12:00～</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>12:50～<br class="sp_only">13:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">3</th>
  <th rowspan="2">10:10～<br class="sp_only">14:20</th>
  <td>10:10～</td>
  <td>10:30～<br class="sp_only">11:00</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>13:00～</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>13:50～<br class="sp_only">14:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">4</th>
  <th rowspan="2">10:10～<br class="sp_only">15:20</th>
  <td>10:10～</td>
  <td>10:30～<br class="sp_only">11:00</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>14:00～</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>14:50～<br class="sp_only">15:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">5</th>
  <th rowspan="2">10:10～<br class="sp_only">16:30</th>
  <td>10:10～</td>
  <td>10:30～<br class="sp_only">11:00</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>15:10～</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>16:00～<br class="sp_only">16:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">6</th>
  <th rowspan="2">10:20～<br class="sp_only">15:30</th>
  <td>10:20～</td>
  <td>10:40～<br class="sp_only">11:10</td>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>14:10～</td>
  <td>14:30～<br class="sp_only">15:00</td>
  <td>15:00～<br class="sp_only">15:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">7～8</th>
  <th rowspan="2">10:20～<br class="sp_only">16:00</th>
  <td>10:20～</td>
  <td>10:40～<br class="sp_only">11:10</td>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>14:40～</td>
  <td>15:00～<br class="sp_only">15:30</td>
  <td>15:30～<br class="sp_only">16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">9</th>
  <th rowspan="2">10:20～<br class="sp_only">16:30</th>
  <td>10:20～</td>
  <td>10:40～<br class="sp_only">11:10</td>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>15:10～</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>16:00～<br class="sp_only">16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">10</th>
  <th rowspan="2">10:20～<br class="sp_only">17:30</th>
  <td>10:20～</td>
  <td>10:40～<br class="sp_only">11:10</td>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>16:10～</td>
  <td>16:30～<br class="sp_only">17:00</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">11</th>
  <th rowspan="2">10:30～<br class="sp_only">14:00</th>
  <td>10:30～</td>
  <td>10:50～<br class="sp_only">11:20</td>
  <td>11:20～<br class="sp_only">11:50</td>
  <td>12:40～</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>13:30～<br class="sp_only">14:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">12</th>
  <th rowspan="2">10:30～<br class="sp_only">15:10</th>
  <td>10:30～</td>
  <td>10:50～<br class="sp_only">11:20</td>
  <td>11:20～<br class="sp_only">11:50</td>
  <td>13:50～</td>
  <td>14:10～<br class="sp_only">14:40</td>
  <td>14:40～<br class="sp_only">15:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">13</th>
  <th rowspan="2">10:30～<br class="sp_only">15:40</th>
  <td>10:30～</td>
  <td>10:50～<br class="sp_only">11:20</td>
  <td>11:20～<br class="sp_only">11:50</td>
  <td>14:20～</td>
  <td>14:40～<br class="sp_only">15:10</td>
  <td>15:10～<br class="sp_only">15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">14</th>
  <th rowspan="2">10:40～<br class="sp_only">15:10</th>
  <td>10:40～</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>13:50～</td>
  <td>14:10～<br class="sp_only">14:40</td>
  <td>14:40～<br class="sp_only">15:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">15</th>
  <th rowspan="2">10:50～<br class="sp_only">15:30</th>
  <td>10:50～</td>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>14:10～</td>
  <td>14:30～<br class="sp_only">15:00</td>

  <td>15:00～<br class="sp_only">15:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">16</th>
  <th rowspan="2">10:50～<br class="sp_only">16:30</th>
  <td>10:50～</td>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>15:10～</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>16:00～<br class="sp_only">16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">17</th>
  <th rowspan="2">10:50～<br class="sp_only">18:00</th>
  <td>10:50～</td>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>16:40～</td>
  <td>17:00～<br class="sp_only">17:30</td>
  <td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">18～19</th>
  <th rowspan="2">11:10～<br class="sp_only">14:40</th>
  <td>11:10～</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>13:20～</td>
  <td>13:40～<br class="sp_only">14:10</td>
  <td>14:10～<br class="sp_only">14:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">20</th>
  <th rowspan="2">11:10～<br class="sp_only">15:30</th>
  <td>11:10～</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>14:10～</td>
  <td>14:30～<br class="sp_only">15:00</td>
  <td>15:00～<br class="sp_only">15:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">21</th>
  <th rowspan="2">11:20～<br class="sp_only">15:50</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>14:30～</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>15:20～<br class="sp_only">15:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">22</th>
  <th rowspan="2">11:20～<br class="sp_only">17:00</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
  <td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">23</th>
  <th rowspan="2">11:20～<br class="sp_only">17:40</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>16:20～</td>
  <td>16:40～<br class="sp_only">17:10</td>
  <td>17:10～<br class="sp_only">17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">24～25</th>
  <th rowspan="2">11:30～<br class="sp_only">16:10</th>
  <td>11:30～</td>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>14:50～</td>
  <td>15:10～<br class="sp_only">15:40</td>
  <td>15:40～<br class="sp_only">16:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">26</th>
  <th rowspan="2">11:30～<br class="sp_only">16:10</th>
  <td>11:30～</td>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>14:50～</td>
  <td>15:10～<br class="sp_only">15:40</td>
  <td>15:40～<br class="sp_only">16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">27</th>
  <th rowspan="2">11:30～<br class="sp_only">17:50</th>
  <td>11:30～</td>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">28</th>
  <th rowspan="2">11:50～<br class="sp_only">17:10</th>
  <td>11:50～</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>15:50～</td>
  <td>16:10～<br class="sp_only">16:40</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">29</th>
  <th rowspan="2">12:00～<br class="sp_only">15:30</th>
  <td>12:00～</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>12:50～<br class="sp_only">13:20</td>
  <td>14:10～</td>
  <td>14:30～<br class="sp_only">15:00</td>
  <td>15:00～<br class="sp_only">15:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">30</th>
  <th rowspan="2">12:00～<br class="sp_only">15:40</th>
  <td>12:00～</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>12:50～<br class="sp_only">13:20</td>
  <td>14:20～</td>
  <td>14:40～<br class="sp_only">15:10</td>
  <td>15:10～<br class="sp_only">15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">31</th>
  <th rowspan="2">12:10～<br class="sp_only">15:30</th>
  <td>12:10～</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>14:10～</td>
  <td>14:30～<br class="sp_only">15:00</td>
  <td>15:00～<br class="sp_only">15:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">32</th>
  <th rowspan="2">12:10～<br class="sp_only">16:10</th>
  <td>12:10～</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>14:50～</td>
  <td>15:10～<br class="sp_only">15:40</td>
  <td>15:40～<br class="sp_only">16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">33</th>
  <th rowspan="2">12:10～<br class="sp_only">16:50</th>
  <td>12:10～</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>15:30～</td>
  <td>15:50～<br class="sp_only">16:20</td>
  <td>16:20～<br class="sp_only">16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">34</th>
  <th rowspan="2">12:10～<br class="sp_only">17:40</th>
  <td>12:10～</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>16:20～</td>
  <td>16:40～<br class="sp_only">17:10</td>
  <td>17:10～<br class="sp_only">17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">35</th>
  <th rowspan="2">12:10～<br class="sp_only">17:50</th>
  <td>12:10～</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">36</th>
  <th rowspan="2">12:20～<br class="sp_only">15:30</th>
  <td>12:20～</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>14:10～</td>
  <td>14:30～<br class="sp_only">15:00</td>
  <td>15:00～<br class="sp_only">15:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">37</th>
  <th rowspan="2">12:20～<br class="sp_only">17:00</th>
  <td>12:20～</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
  <td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">38</th>
  <th rowspan="2">12:20～<br class="sp_only">17:10</th>
  <td>12:20～</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>15:50～</td>
  <td>16:10～<br class="sp_only">16:40</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">39</th>
  <th rowspan="2">12:30～<br class="sp_only">16:10</th>
  <td>12:30～</td>
  <td>12:50～<br class="sp_only">13:20</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>14:50～</td>
  <td>15:10～<br class="sp_only">15:40</td>
  <td>15:40～<br class="sp_only">16:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">40</th>
  <th rowspan="2">12:40～<br class="sp_only">16:00</th>
  <td>12:40～</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>14:40～</td>
  <td>15:00～<br class="sp_only">15:30</td>
  <td>15:30～<br class="sp_only">16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">41</th>
  <th rowspan="2">12:40～<br class="sp_only">16:20</th>
  <td>12:40～</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>15:00～</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>15:50～<br class="sp_only">16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">42</th>
  <th rowspan="2">12:40～<br class="sp_only">16:40</th>
  <td>12:40～</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>15:20～</td>
  <td>15:40～<br class="sp_only">16:10</td>
  <td>16:10～<br class="sp_only">16:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">43</th>
  <th rowspan="2">12:40～<br class="sp_only">18:00</th>
  <td>12:40～</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>16:40～</td>
  <td>17:00～<br class="sp_only">17:30</td>
  <td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">44</th>
  <th rowspan="2">12:50～<br class="sp_only">16:10</th>
  <td>12:50～</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>13:40～<br class="sp_only">14:10</td>
  <td>14:50～</td>
  <td>15:10～<br class="sp_only">15:40</td>
  <td>15:40～<br class="sp_only">16:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">45</th>
  <th rowspan="2">13:00～<br class="sp_only">16:40</th>
  <td>13:00～</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>15:20～</td>
  <td>15:40～<br class="sp_only">16:10</td>
  <td>16:10～<br class="sp_only">16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">46</th>
  <th rowspan="2">13:00～<br class="sp_only">17:40</th>
  <td>13:00～</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>16:20～</td>
  <td>16:40～<br class="sp_only">17:10</td>
  <td>17:10～<br class="sp_only">17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">47</th>
  <th rowspan="2">13:00～<br class="sp_only">17:40</th>
  <td>13:00～</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>16:20～</td>
  <td>16:40～<br class="sp_only">17:10</td>
  <td>17:10～<br class="sp_only">17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">48</th>
  <th rowspan="2">13:10～<br class="sp_only">16:30</th>
  <td>13:10～</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>15:10～</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>16:00～<br class="sp_only">16:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">49</th>
  <th rowspan="2">13:10～<br class="sp_only">17:20</th>
  <td>13:10～</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>16:00～</td>
  <td>16:20～<br class="sp_only">16:50</td>
  <td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">50</th>
  <th rowspan="2">13:10～<br class="sp_only">17:50</th>
  <td>13:10～</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">51</th>
  <th rowspan="2">13:10～<br class="sp_only">17:50</th>
  <td>13:10～</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">52</th>
  <th rowspan="2">13:20～<br class="sp_only">17:10</th>
  <td>13:20～</td>
  <td>13:40～<br class="sp_only">14:10</td>
  <td>14:10～<br class="sp_only">14:40</td>
  <td>15:50～</td>
  <td>16:10～<br class="sp_only">16:40</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">53</th>
  <th rowspan="2">13:30～<br class="sp_only">16:40</th>
  <td>13:30～</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>15:20～</td>
  <td>15:40～<br class="sp_only">16:10</td>
  <td>16:10～<br class="sp_only">16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">54</th>
  <th rowspan="2">13:30～<br class="sp_only">17:10</th>
  <td>13:30～</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>15:50～</td>
  <td>16:10～<br class="sp_only">16:40</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">55</th>
  <th rowspan="2">13:50～<br class="sp_only">17:00</th>
  <td>13:50～</td>
  <td>14:10～<br class="sp_only">14:40</td>
  <td>14:40～<br class="sp_only">15:10</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
  <td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">56</th>
  <th rowspan="2">13:50～<br class="sp_only">18:00</th>
  <td>13:50～</td>
  <td>14:10～<br class="sp_only">14:40</td>
  <td>14:40～<br class="sp_only">15:10</td>
  <td>16:40～</td>
  <td>17:00～<br class="sp_only">17:30</td>
  <td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">57</th>
  <th rowspan="2">14:00～<br class="sp_only">17:10</th>
  <td>14:00～</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>15:50～</td>
  <td>16:10～<br class="sp_only">16:40</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">58</th>
  <th rowspan="2">14:00～<br class="sp_only">17:20</th>
  <td>14:00～</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>16:00～</td>
  <td>16:20～<br class="sp_only">16:50</td>
  <td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">59</th>
  <th rowspan="2">14:00～<br class="sp_only">17:30</th>
  <td>14:00～</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>16:10～</td>
  <td>16:30～<br class="sp_only">17:00</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr class="last-child">
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Flight of the Hippogriff™</td>
</tr>

                <!-- ↑挿入箇所↑ -->
              </tbody>
      </table>
      
    </div>

    <div v-if="productId === 4" class="table_content current">
      <table class="cmn_att_time01 cmn_tbl01">
        <!--<caption>入場日：～MM月DD日</caption>-->
        <colgroup>
        <col class="w100">
        <col>
        </colgroup>
        <thead>
          <tr>
            <th colspan="9" class="txtleft"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">▼ View time at left side</font></font></th>
          </tr>
        </thead>
        <tbody>
          <!-- ↓挿入箇所↓ -->

<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">1</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 00 ～</font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15:00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-10 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">2</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 00 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 00-10 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 00-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 20 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">3</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 00 ～</font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16:00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-10 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 00-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">4</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 00-16 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-10 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">5</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 00-10 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">6</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 00 ～</font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17:00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 00-10 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">7</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-10 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">8</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 00-18: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-10 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">9</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 00-18 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 00-10 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 00-18 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-10 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 10 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-10 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 00-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-10 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-10 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 30-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 20 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 20 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-10 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 20 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: 20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00-14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-10 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">19</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 20 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 20 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-10 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">21</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">22</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: 30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">23</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 30-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 00-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">24</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: 30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">25</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 30-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00-14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">26</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 30-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">27</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">28</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">29</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 30-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-19 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 20 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-19 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">31</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">32</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: 40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">33</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: 40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">34</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">35</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">36</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">37</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: 40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">38</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: 40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">39</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00-14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">41</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: 00-18 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">42</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">43</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">44</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">45</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: 00-18 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">46</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00-16 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 20 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">47</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00-14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">48</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00 ～</font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17:00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">49</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00 ～</font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17:00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">51</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">52</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00 ～</font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18:00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 00-11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">53</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">54</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">55</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">56</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 00-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">57 57</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 00-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">58</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: 10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">59</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: 10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00-14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">60</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: 10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">61</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">62</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 00-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">63</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">64</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">65</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">66</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 20 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">67</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 20 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">68</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">69</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: 20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 20 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">70</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00-14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">71</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">72</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-17 </font><font style="vertical-align: inherit;">: 20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">73</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">74</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">75</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-11 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">76</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">77</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00-14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">78 78</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 20 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">79</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">80</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">81</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">82</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">83</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">84</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">85</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">86</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: 30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">87</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">88</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00-14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">89</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-19 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-19 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">90</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">91</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00-14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">92</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 20 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">93</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">94</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">95</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">96</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00-14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">97</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">98</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">99</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">100</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">101</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00-14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">102</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">103</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 40-18 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">104</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: 00-18 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">105</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-18 </font><font style="vertical-align: inherit;">: 40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">106</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-18 </font><font style="vertical-align: inherit;">: 40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">107</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">108</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00-14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">109</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: 50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">110</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">111</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 20 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">112</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">113</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">114</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">115</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: 50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">116</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-18 </font><font style="vertical-align: inherit;">: 50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">11: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">117</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-16 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00-14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 20 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">118</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">119</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00 ～</font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17:00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">120-121</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-17 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">122</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">123</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00 ～</font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18:00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-12 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">124</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-18 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: 00-18 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">125</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00-18 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">126</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 00-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00-14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">127</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 00-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">128</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00-14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">129</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: 10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">130</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: 10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">131</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">132</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 00-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">133</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">134</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">135</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">136</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 00-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00-14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">137</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00-14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: 00-18 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">138</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 00-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: 00-18 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">139</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">140</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00-16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">141</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 20 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17 </font><font style="vertical-align: inherit;">: 20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">142</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 20 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-12 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">143</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-18 </font><font style="vertical-align: inherit;">: 20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">144</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00-14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">145</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">146</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">147</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">148</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: 00-18 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">149</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 20 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: 00-18 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">150</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">12: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">151</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">152</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 00-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">153</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10-13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 20 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">154</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 10 ～</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00-14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">18: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">155</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 20 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">156</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 20 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">30</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 20 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">10-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 10 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">157</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~ </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 20 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">158</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-13 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">159</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 20 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">160</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 00-15 </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">: 30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00-17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">161</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: 40 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 00-14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-15 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">30-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">00</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: 30 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">16: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>
<tr>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">162</font></font></th>
<th rowspan="2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></th>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">13: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-14 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">14: 50 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: 10 ~ 15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">15: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">40-16 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">10</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: 00 ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">20-17 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">50</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">17: </font></font><br class="sp_only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">50-18 </font><font style="vertical-align: inherit;">: </font><font style="vertical-align: inherit;">20</font></font></td>
</tr>
<tr>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Hollywood Dream The Ride ~ Backdrop ~</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to</font></span><font style="vertical-align: inherit;"> Super Nintendo World ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Mario Kart ~ Bowser's Challenge ~</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yoshi Adventure</font></font></td>
<td><font style="vertical-align: inherit;"><span><font style="vertical-align: inherit;">Admission to The</font></span><font style="vertical-align: inherit;"> Wizarding World of Harry Potter ™</font></font><span><font style="vertical-align: inherit;"></font></span></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Harry Potter and the Forbidden Journey ™</font></font></td>
<td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Flight of the Hippogriff ™</font></font></td>
</tr>

          <!-- ↑挿入箇所↑ -->
        </tbody>
      </table>
    </div>

    <div v-if="productId === 5" class="table_content current">
      <schedule-detail5 />
    </div>

    <div v-if="productId === 6" class="table_content current">
      <div>
        <p class="content">If you are using a time-designated Studio Pass (such as those that permit entry after 3 p.m.), or an Express Pass, and you plan to participate in a time-designated event, there are time slots in which you will not be able to participate. </p>
        <p class="content">When you are deciding when to participate, make sure the times do not overlap.</p>
        <p class="content">Availability may be limited depending on the entry date. Please check the availability calendar in advance.</p>
        <!-- <p class="content">If you are using a time-designated Studio Pass (such as those that permit entry after 3 p.m.), or an Express Pass, and you plan to participate in a time-designated event, there are time slots in which you will not be able to participate. When you are deciding when to participate, make sure the times do not overlap. Availability may be limited depending on the entry date. Please check the availability calendar in advance.</p> -->
        <!-- <p class="content">For attractions that do not specify other times, please use at times other than the following</p> -->
      </div>
      <el-row class="btn_box">
          <!-- <div href="session" @click="schedule = '1'" class="select_designated_time_btn" :class="{'btn-active': schedule === '1'}">
            Admission date: ~ April 11
            <img src="@/assets/down-icon.png" alt="">
          </div> -->
          <div href="session" class="select_designated_time_btn btn-active">
            Admission date：April 12th～May 31st
            <img src="@/assets/down-icon.png" alt="">
          </div>
      </el-row>
      <table class="cmn_att_time01 cmn_tbl01">
              <caption>Admission date：April 12th～May 31st</caption>
              <colgroup>
              <col class="w100">
              <col>
              </colgroup>
              <thead>
                <tr>
                  <th colspan="5" class="txtleft">▼ View time at left side</th>
                </tr>
              </thead>
              <tbody>
                <!-- ↓挿入箇所↓ -->

<tr class="first-child">
  <th rowspan="2">1</th>
  <th rowspan="2">10:00～15:40</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>15:20～15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>SING on Tour™</td>
</tr>
<tr>
  <th rowspan="2">2</th>
  <th rowspan="2">10:20～14:40</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>14:20～14:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>SING on Tour™</td>
</tr>
<tr>
  <th rowspan="2">3</th>
  <th rowspan="2">10:20～15:40</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>15:20～15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>SING on Tour™</td>
</tr>
<tr>
  <th rowspan="2">4</th>
  <th rowspan="2">10:20～16:00</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>15:40～16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>SING on Tour™</td>
</tr>
<tr>
  <th rowspan="2">5</th>
  <th rowspan="2">10:30～12:40</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>12:20～12:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>SING on Tour™</td>
</tr>
<tr>
  <th rowspan="2">6</th>
  <th rowspan="2">10:30～16:40</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>16:20～16:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>SING on Tour™</td>
</tr>
<tr>
  <th rowspan="2">7</th>
  <th rowspan="2">10:40～13:00</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>12:40～13:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>SING on Tour™</td>
</tr>
<tr>
  <th rowspan="2">8</th>
  <th rowspan="2">11:10～16:00</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>15:40～16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>SING on Tour™</td>
</tr>
<tr>
  <th rowspan="2">9～12</th>
  <th rowspan="2">11:20～13:20</th>
  <td>11:20～11:40</td>
  <td>12:20～</td>
  <td>12:40～13:10</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">13</th>
  <th rowspan="2">11:20～14:10</th>
  <td>11:20～11:40</td>
  <td>13:10～</td>
  <td>13:30～14:00</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">14</th>
  <th rowspan="2">11:20～14:30</th>
  <td>11:20～11:40</td>
  <td>13:30～</td>
  <td>13:50～14:20</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">15</th>
  <th rowspan="2">11:20～16:00</th>
  <td>11:20～11:40</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">16～17</th>
  <th rowspan="2">11:20～16:10</th>
  <td>11:20～11:40</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">18</th>
  <th rowspan="2">11:20～16:40</th>
  <td>11:20～11:40</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">19</th>
  <th rowspan="2">11:20～17:10</th>
  <td>11:20～11:40</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">20</th>
  <th rowspan="2">11:20～17:20</th>
  <td>11:20～11:40</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">21</th>
  <th rowspan="2">11:20～18:40</th>
  <td>11:20～11:40</td>
  <td>17:40～</td>
  <td>18:00～18:30</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">22～26</th>
  <th rowspan="2">11:30～13:40</th>
  <td>11:30～</td>
  <td>11:50～12:20</td>
  <td>13:20～13:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>SING on Tour™</td>
</tr>
<tr>
  <th rowspan="2">27</th>
  <th rowspan="2">11:40～15:40</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>15:20～15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>SING on Tour™</td>
</tr>
<tr>
  <th rowspan="2">28</th>
  <th rowspan="2">11:50～16:00</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>15:40～16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>SING on Tour™</td>
</tr>
<tr>
  <th rowspan="2">29</th>
  <th rowspan="2">12:20～15:20</th>
  <td>12:20～12:40</td>
  <td>14:20～</td>
  <td>14:40～15:10</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">30</th>
  <th rowspan="2">12:20～15:50</th>
  <td>12:20～12:40</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">31</th>
  <th rowspan="2">12:20～16:10</th>
  <td>12:20～12:40</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">32</th>
  <th rowspan="2">12:20～16:30</th>
  <td>12:20～12:40</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">33～34</th>
  <th rowspan="2">12:20～17:00</th>
  <td>12:20～12:40</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">35～39</th>
  <th rowspan="2">12:30～14:40</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>14:20～14:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>SING on Tour™</td>
</tr>
<tr>
  <th rowspan="2">40～41</th>
  <th rowspan="2">12:40～15:00</th>
  <td>12:40～13:00</td>
  <td>14:00～</td>
  <td>14:20～14:50</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">42</th>
  <th rowspan="2">12:40～15:20</th>
  <td>12:40～13:00</td>
  <td>14:20～</td>
  <td>14:40～15:10</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">43</th>
  <th rowspan="2">12:40～15:30</th>
  <td>12:40～13:00</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">44</th>
  <th rowspan="2">12:40～16:50</th>
  <td>12:40～13:00</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">45</th>
  <th rowspan="2">12:40～17:30</th>
  <td>12:40～13:00</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">46</th>
  <th rowspan="2">12:40～17:40</th>
  <td>12:40～13:00</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">47</th>
  <th rowspan="2">12:40～18:00</th>
  <td>12:40～13:00</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">48</th>
  <th rowspan="2">12:40～18:40</th>
  <td>12:40～13:00</td>
  <td>17:40～</td>
  <td>18:00～18:30</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">49</th>
  <th rowspan="2">13:20～15:20</th>
  <td>13:20～13:40</td>
  <td>14:20～</td>
  <td>14:40～15:10</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">50</th>
  <th rowspan="2">13:20～15:30</th>
  <td>13:20～13:40</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">51</th>
  <th rowspan="2">13:20～15:40</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>15:20～15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>SING on Tour™</td>
</tr>
<tr>
  <th rowspan="2">52</th>
  <th rowspan="2">13:20～16:00</th>
  <td>13:20～13:40</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">53</th>
  <th rowspan="2">13:20～16:40</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>16:20～16:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>SING on Tour™</td>
</tr>
<tr>
  <th rowspan="2">54</th>
  <th rowspan="2">13:30～16:00</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
  <td>15:40～16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>SING on Tour™</td>
</tr>
<tr>
  <th rowspan="2">55</th>
  <th rowspan="2">14:00～16:00</th>
  <td>14:00～</td>
  <td>14:20～14:50</td>
  <td>15:40～16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>SING on Tour™</td>
</tr>
<tr>
  <th rowspan="2">56</th>
  <th rowspan="2">14:10～16:00</th>
  <td>14:10～</td>
  <td>14:30～15:00</td>
  <td>15:40～16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>SING on Tour™</td>
</tr>
<tr>
  <th rowspan="2">57</th>
  <th rowspan="2">14:10～16:40</th>
  <td>14:10～</td>
  <td>14:30～15:00</td>
  <td>16:20～16:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>SING on Tour™</td>
</tr>
<tr>
  <th rowspan="2">58</th>
  <th rowspan="2">14:20～16:20</th>
  <td>14:20～14:40</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">59</th>
  <th rowspan="2">14:20～16:40</th>
  <td>14:20～14:40</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">60</th>
  <th rowspan="2">14:20～17:40</th>
  <td>14:20～14:40</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">61～62</th>
  <th rowspan="2">15:20～17:20</th>
  <td>15:20～15:40</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">63</th>
  <th rowspan="2">15:20～17:50</th>
  <td>15:20～15:40</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">64～65</th>
  <th rowspan="2">15:40～17:40</th>
  <td>15:40～16:00</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">66</th>
  <th rowspan="2">15:40～17:50</th>
  <td>15:40～16:00</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">67</th>
  <th rowspan="2">15:40～18:00</th>
  <td>15:40～16:00</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">68</th>
  <th rowspan="2">15:40～18:50</th>
  <td>15:40～16:00</td>
  <td>17:50～</td>
  <td>18:10～18:40</td>
</tr>
<tr class="last-child">
  <td>SING on Tour™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>

                <!-- ↑挿入箇所↑ -->
              </tbody>
      </table>
    </div>

    <div v-if="productId === 7" class="table_content current">
      <div>
        <p class="content">If you are using a time-designated Studio Pass (such as those that permit entry after 3 p.m.), or an Express Pass, and you plan to participate in a time-designated event, there are time slots in which you will not be able to participate. </p>
        <p class="content">When you are deciding when to participate, make sure the times do not overlap.</p>
        <p class="content">Availability may be limited depending on the entry date. Please check the availability calendar in advance.</p>
        <!-- <p class="content">For attractions that do not specify other times, please use at times other than the following</p> -->
        <!-- <p class="content">It may be sold out. Also, depending on the admission date, there may be times when it is not sold.</p> -->
      </div>
      <el-row class="btn_box">
          <!-- <div href="session" @click="schedule = '1'" class="select_designated_time_btn" :class="{'btn-active': schedule === '1'}">
            Admission date: ~ April 11
            <img src="@/assets/down-icon.png" alt="">
          </div> -->
          <div href="session" class="select_designated_time_btn btn-active">
            Admission date：April 12th～May 31st
            <img src="@/assets/down-icon.png" alt="">
          </div>
      </el-row>
      <table class="cmn_att_time01 cmn_tbl01">
        <caption>Admission date：April 12th～May 31st</caption>
        <colgroup>
        <col class="w100">
        <col>
        </colgroup>
        <thead>
          <tr>
            <th colspan="5" class="txtleft">▼ View time at left side</th>
          </tr>
        </thead>
        <tbody>
          <!-- ↓挿入箇所↓ -->

<tr class="first-child">
<th rowspan="2">1～2</th>
<th rowspan="2">10:00～12:40</th>
<td>10:00～</td>
<td>10:20～10:50</td>
<td>12:20～12:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">3</th>
<th rowspan="2">10:00～13:00</th>
<td>10:00～</td>
<td>10:20～10:50</td>
<td>12:40～13:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">4</th>
<th rowspan="2">10:00～13:40</th>
<td>10:00～</td>
<td>10:20～10:50</td>
<td>13:20～13:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">5～7</th>
<th rowspan="2">10:00～16:40</th>
<td>10:00～</td>
<td>10:20～10:50</td>
<td>16:20～16:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">8～9</th>
<th rowspan="2">10:10～14:40</th>
<td>10:10～</td>
<td>10:30～11:00</td>
<td>14:20～14:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">10</th>
<th rowspan="2">10:10～16:00</th>
<td>10:10～</td>
<td>10:30～11:00</td>
<td>15:40～16:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">11</th>
<th rowspan="2">10:10～16:40</th>
<td>10:10～</td>
<td>10:30～11:00</td>
<td>16:20～16:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">12～13</th>
<th rowspan="2">10:20～16:00</th>
<td>10:20～</td>
<td>10:40～11:10</td>
<td>15:40～16:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">14</th>
<th rowspan="2">10:20～16:40</th>
<td>10:20～</td>
<td>10:40～11:10</td>
<td>16:20～16:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">15</th>
<th rowspan="2">10:40～12:40</th>
<td>10:40～</td>
<td>11:00～11:30</td>
<td>12:20～12:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">16</th>
<th rowspan="2">10:40～13:00</th>
<td>10:40～</td>
<td>11:00～11:30</td>
<td>12:40～13:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">17</th>
<th rowspan="2">10:40～13:40</th>
<td>10:40～</td>
<td>11:00～11:30</td>
<td>13:20～13:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">18～19</th>
<th rowspan="2">10:50～12:40</th>
<td>10:50～</td>
<td>11:10～11:40</td>
<td>12:20～12:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">20</th>
<th rowspan="2">10:50～13:00</th>
<td>10:50～</td>
<td>11:10～11:40</td>
<td>12:40～13:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">21～23</th>
<th rowspan="2">11:00～13:00</th>
<td>11:00～</td>
<td>11:20～11:50</td>
<td>12:40～13:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">24～25</th>
<th rowspan="2">11:00～13:40</th>
<td>11:00～</td>
<td>11:20～11:50</td>
<td>13:20～13:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">26</th>
<th rowspan="2">11:00～14:40</th>
<td>11:00～</td>
<td>11:20～11:50</td>
<td>14:20～14:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">27</th>
<th rowspan="2">11:00～16:00</th>
<td>11:00～</td>
<td>11:20～11:50</td>
<td>15:40～16:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">28～30</th>
<th rowspan="2">11:10～13:00</th>
<td>11:10～</td>
<td>11:30～12:00</td>
<td>12:40～13:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">31</th>
<th rowspan="2">11:10～13:40</th>
<td>11:10～</td>
<td>11:30～12:00</td>
<td>13:20～13:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">32～33</th>
<th rowspan="2">11:10～15:40</th>
<td>11:10～</td>
<td>11:30～12:00</td>
<td>15:20～15:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">34</th>
<th rowspan="2">11:10～16:40</th>
<td>11:10～</td>
<td>11:30～12:00</td>
<td>16:20～16:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">35</th>
<th rowspan="2">11:20～13:40</th>
<td>11:20～11:40</td>
<td>12:40～</td>
<td>13:00～13:30</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">36～38</th>
<th rowspan="2">11:20～13:50</th>
<td>11:20～11:40</td>
<td>12:50～</td>
<td>13:10～13:40</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">39～42</th>
<th rowspan="2">11:20～14:00</th>
<td>11:20～11:40</td>
<td>13:00～</td>
<td>13:20～13:50</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">43</th>
<th rowspan="2">11:20～14:10</th>
<td>11:20～11:40</td>
<td>13:10～</td>
<td>13:30～14:00</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">44</th>
<th rowspan="2">11:20～14:40</th>
<td>11:20～11:40</td>
<td>13:40～</td>
<td>14:00～14:30</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">45</th>
<th rowspan="2">11:20～14:50</th>
<td>11:20～11:40</td>
<td>13:50～</td>
<td>14:10～14:40</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">46～47</th>
<th rowspan="2">11:20～15:30</th>
<td>11:20～11:40</td>
<td>14:30～</td>
<td>14:50～15:20</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">48～50</th>
<th rowspan="2">11:20～15:40</th>
<td>11:20～11:40</td>
<td>14:40～</td>
<td>15:00～15:30</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">51～52</th>
<th rowspan="2">11:20～15:50</th>
<td>11:20～11:40</td>
<td>14:50～</td>
<td>15:10～15:40</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">53</th>
<th rowspan="2">11:20～16:20</th>
<td>11:20～11:40</td>
<td>15:20～</td>
<td>15:40～16:10</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">54～55</th>
<th rowspan="2">11:20～16:30</th>
<td>11:20～11:40</td>
<td>15:30～</td>
<td>15:50～16:20</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">56～57</th>
<th rowspan="2">11:20～17:30</th>
<td>11:20～11:40</td>
<td>16:30～</td>
<td>16:50～17:20</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">58</th>
<th rowspan="2">11:20～17:40</th>
<td>11:20～11:40</td>
<td>16:40～</td>
<td>17:00～17:30</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">59～60</th>
<th rowspan="2">11:20～18:10</th>
<td>11:20～11:40</td>
<td>17:10～</td>
<td>17:30～18:00</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">61</th>
<th rowspan="2">11:20～18:50</th>
<td>11:20～11:40</td>
<td>17:50～</td>
<td>18:10～18:40</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">62～63</th>
<th rowspan="2">11:40～13:40</th>
<td>11:40～</td>
<td>12:00～12:30</td>
<td>13:20～13:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">64～65</th>
<th rowspan="2">11:40～16:40</th>
<td>11:40～</td>
<td>12:00～12:30</td>
<td>16:20～16:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">66～70</th>
<th rowspan="2">11:50～13:40</th>
<td>11:50～</td>
<td>12:10～12:40</td>
<td>13:20～13:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">71</th>
<th rowspan="2">11:50～15:40</th>
<td>11:50～</td>
<td>12:10～12:40</td>
<td>15:20～15:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">72</th>
<th rowspan="2">11:50～16:00</th>
<td>11:50～</td>
<td>12:10～12:40</td>
<td>15:40～16:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">73</th>
<th rowspan="2">12:00～14:40</th>
<td>12:00～</td>
<td>12:20～12:50</td>
<td>14:20～14:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">74～75</th>
<th rowspan="2">12:00～16:00</th>
<td>12:00～</td>
<td>12:20～12:50</td>
<td>15:40～16:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">76～77</th>
<th rowspan="2">12:10～14:40</th>
<td>12:10～</td>
<td>12:30～13:00</td>
<td>14:20～14:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">78</th>
<th rowspan="2">12:10～15:40</th>
<td>12:10～</td>
<td>12:30～13:00</td>
<td>15:20～15:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">79～81</th>
<th rowspan="2">12:10～16:00</th>
<td>12:10～</td>
<td>12:30～13:00</td>
<td>15:40～16:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">82</th>
<th rowspan="2">12:20～14:20</th>
<td>12:20～12:40</td>
<td>13:20～</td>
<td>13:40～14:10</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">83</th>
<th rowspan="2">12:20～14:40</th>
<td>12:20～12:40</td>
<td>13:40～</td>
<td>14:00～14:30</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">84</th>
<th rowspan="2">12:20～15:30</th>
<td>12:20～12:40</td>
<td>14:30～</td>
<td>14:50～15:20</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">85～87</th>
<th rowspan="2">12:20～15:40</th>
<td>12:20～12:40</td>
<td>14:40～</td>
<td>15:00～15:30</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">88</th>
<th rowspan="2">12:20～15:40</th>
<td>12:20～</td>
<td>12:40～13:10</td>
<td>15:20～15:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">89</th>
<th rowspan="2">12:20～16:00</th>
<td>12:20～12:40</td>
<td>15:00～</td>
<td>15:20～15:50</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">90</th>
<th rowspan="2">12:20～16:10</th>
<td>12:20～12:40</td>
<td>15:10～</td>
<td>15:30～16:00</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">91</th>
<th rowspan="2">12:20～16:30</th>
<td>12:20～12:40</td>
<td>15:30～</td>
<td>15:50～16:20</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">92</th>
<th rowspan="2">12:20～16:50</th>
<td>12:20～12:40</td>
<td>15:50～</td>
<td>16:10～16:40</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">93～96</th>
<th rowspan="2">12:20～17:10</th>
<td>12:20～12:40</td>
<td>16:10～</td>
<td>16:30～17:00</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">97</th>
<th rowspan="2">12:20～17:20</th>
<td>12:20～12:40</td>
<td>16:20～</td>
<td>16:40～17:10</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">98～100</th>
<th rowspan="2">12:20～17:30</th>
<td>12:20～12:40</td>
<td>16:30～</td>
<td>16:50～17:20</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">101</th>
<th rowspan="2">12:20～17:40</th>
<td>12:20～12:40</td>
<td>16:40～</td>
<td>17:00～17:30</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">102</th>
<th rowspan="2">12:20～18:30</th>
<td>12:20～12:40</td>
<td>17:30～</td>
<td>17:50～18:20</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">103</th>
<th rowspan="2">12:20～19:00</th>
<td>12:20～12:40</td>
<td>18:00～</td>
<td>18:20～18:50</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">104</th>
<th rowspan="2">12:40～14:40</th>
<td>12:40～</td>
<td>13:00～13:30</td>
<td>14:20～14:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">105</th>
<th rowspan="2">12:40～14:40</th>
<td>12:40～13:00</td>
<td>13:40～</td>
<td>14:00～14:30</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">106～108</th>
<th rowspan="2">12:40～15:10</th>
<td>12:40～13:00</td>
<td>14:10～</td>
<td>14:30～15:00</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">109～112</th>
<th rowspan="2">12:40～15:20</th>
<td>12:40～13:00</td>
<td>14:20～</td>
<td>14:40～15:10</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">113～115</th>
<th rowspan="2">12:40～15:30</th>
<td>12:40～13:00</td>
<td>14:30～</td>
<td>14:50～15:20</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">116</th>
<th rowspan="2">12:40～16:20</th>
<td>12:40～13:00</td>
<td>15:20～</td>
<td>15:40～16:10</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">117</th>
<th rowspan="2">12:40～16:40</th>
<td>12:40～</td>
<td>13:00～13:30</td>
<td>16:20～16:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">118～120</th>
<th rowspan="2">12:40～17:00</th>
<td>12:40～13:00</td>
<td>16:00～</td>
<td>16:20～16:50</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">121</th>
<th rowspan="2">12:40～17:10</th>
<td>12:40～13:00</td>
<td>16:10～</td>
<td>16:30～17:00</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">122</th>
<th rowspan="2">12:40～17:20</th>
<td>12:40～13:00</td>
<td>16:20～</td>
<td>16:40～17:10</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">123</th>
<th rowspan="2">12:40～17:50</th>
<td>12:40～13:00</td>
<td>16:50～</td>
<td>17:10～17:40</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">124～125</th>
<th rowspan="2">13:10～15:40</th>
<td>13:10～</td>
<td>13:30～14:00</td>
<td>15:20～15:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">126</th>
<th rowspan="2">13:10～16:00</th>
<td>13:10～</td>
<td>13:30～14:00</td>
<td>15:40～16:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">127～128</th>
<th rowspan="2">13:20～15:20</th>
<td>13:20～13:40</td>
<td>14:20～</td>
<td>14:40～15:10</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">129</th>
<th rowspan="2">13:20～15:50</th>
<td>13:20～13:40</td>
<td>14:50～</td>
<td>15:10～15:40</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">130～132</th>
<th rowspan="2">13:20～16:00</th>
<td>13:20～</td>
<td>13:40～14:10</td>
<td>15:40～16:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">133～134</th>
<th rowspan="2">13:20～16:10</th>
<td>13:20～13:40</td>
<td>15:10～</td>
<td>15:30～16:00</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">135</th>
<th rowspan="2">13:20～16:40</th>
<td>13:20～</td>
<td>13:40～14:10</td>
<td>16:20～16:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">136</th>
<th rowspan="2">13:20～16:40</th>
<td>13:20～13:40</td>
<td>15:40～</td>
<td>16:00～16:30</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">137</th>
<th rowspan="2">13:20～16:50</th>
<td>13:20～13:40</td>
<td>15:50～</td>
<td>16:10～16:40</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">138</th>
<th rowspan="2">13:20～17:00</th>
<td>13:20～13:40</td>
<td>16:00～</td>
<td>16:20～16:50</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">139</th>
<th rowspan="2">13:20～17:10</th>
<td>13:20～13:40</td>
<td>16:10～</td>
<td>16:30～17:00</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">140</th>
<th rowspan="2">13:20～18:00</th>
<td>13:20～13:40</td>
<td>17:00～</td>
<td>17:20～17:50</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">141</th>
<th rowspan="2">13:20～18:10</th>
<td>13:20～13:40</td>
<td>17:10～</td>
<td>17:30～18:00</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">142</th>
<th rowspan="2">13:30～15:40</th>
<td>13:30～</td>
<td>13:50～14:20</td>
<td>15:20～15:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">143</th>
<th rowspan="2">13:30～16:00</th>
<td>13:30～</td>
<td>13:50～14:20</td>
<td>15:40～16:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">144</th>
<th rowspan="2">13:40～16:00</th>
<td>13:40～</td>
<td>14:00～14:30</td>
<td>15:40～16:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">145</th>
<th rowspan="2">13:40～16:40</th>
<td>13:40～</td>
<td>14:00～14:30</td>
<td>16:20～16:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">146～147</th>
<th rowspan="2">13:50～15:40</th>
<td>13:50～</td>
<td>14:10～14:40</td>
<td>15:20～15:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">148</th>
<th rowspan="2">13:50～16:00</th>
<td>13:50～</td>
<td>14:10～14:40</td>
<td>15:40～16:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">149～151</th>
<th rowspan="2">13:50～16:40</th>
<td>13:50～</td>
<td>14:10～14:40</td>
<td>16:20～16:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">152～155</th>
<th rowspan="2">14:10～16:00</th>
<td>14:10～</td>
<td>14:30～15:00</td>
<td>15:40～16:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">156</th>
<th rowspan="2">14:10～16:40</th>
<td>14:10～</td>
<td>14:30～15:00</td>
<td>16:20～16:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">157～162</th>
<th rowspan="2">14:20～16:20</th>
<td>14:20～14:40</td>
<td>15:20～</td>
<td>15:40～16:10</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">163～164</th>
<th rowspan="2">14:20～16:30</th>
<td>14:20～14:40</td>
<td>15:30～</td>
<td>15:50～16:20</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">165</th>
<th rowspan="2">14:20～16:40</th>
<td>14:20～14:40</td>
<td>15:40～</td>
<td>16:00～16:30</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">166～167</th>
<th rowspan="2">14:20～17:00</th>
<td>14:20～14:40</td>
<td>16:00～</td>
<td>16:20～16:50</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">168～169</th>
<th rowspan="2">14:20～17:50</th>
<td>14:20～14:40</td>
<td>16:50～</td>
<td>17:10～17:40</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">170</th>
<th rowspan="2">14:20～18:00</th>
<td>14:20～14:40</td>
<td>17:00～</td>
<td>17:20～17:50</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">171</th>
<th rowspan="2">15:20～17:20</th>
<td>15:20～15:40</td>
<td>16:20～</td>
<td>16:40～17:10</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">172</th>
<th rowspan="2">15:20～17:40</th>
<td>15:20～15:40</td>
<td>16:40～</td>
<td>17:00～17:30</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">173～174</th>
<th rowspan="2">15:40～17:40</th>
<td>15:40～16:00</td>
<td>16:40～</td>
<td>17:00～17:30</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">175</th>
<th rowspan="2">15:40～17:50</th>
<td>15:40～16:00</td>
<td>16:50～</td>
<td>17:10～17:40</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">176</th>
<th rowspan="2">15:40～18:00</th>
<td>15:40～16:00</td>
<td>17:00～</td>
<td>17:20～17:50</td>
</tr>
<tr class="last-child">
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
<td>Yoshi's Adventure™</td>
</tr>

          <!-- ↑挿入箇所↑ -->
        </tbody>
      </table>
    </div>
  
    <div v-if="productId === 8" class="table_content current">
      <schedule-detail8 />
    </div>

    <div v-if="productId === 9" class="table_content current">
      <div>
        <p class="content">Harry Potter and the Forbidden Journey ™ will be closed from Monday, May 10th to Sunday, May 16th.</p>
        <p class="content">If you are using a time-designated Studio Pass (such as those that permit entry after 3 p.m.), or an Express Pass, and you plan to participate in a time-designated event, there are time slots in which you will not be able to participate. </p>
        <p class="content">When you are deciding when to participate, make sure the times do not overlap.</p>
        <p class="content">Availability may be limited depending on the entry date. Please check the availability calendar in advance.</p>
        <!-- <p class="content">It may be sold out. Also, depending on the admission date, there may be times when it is not sold.</p> -->
      </div>
      <el-row class="btn_box">
          <div href="session" @click="schedule = '1'" class="select_designated_time_btn" :class="{'btn-active': schedule === '1'}">
            Admission date：～May 31st
            <img src="@/assets/down-icon.png" alt="">
          </div>
          <div href="session" @click="schedule = '2'" class="select_designated_time_btn" :class="{'btn-active': schedule === '2'}">
            Admission date：June 1st～July 1st
            <img src="@/assets/down-icon.png" alt="">
          </div>
      </el-row>
       <table v-if="schedule === '1'" class="cmn_att_time01 cmn_tbl01">
              <caption>Admission date：～May 31st</caption>
              <colgroup>
              <col class="w100">
              <col>
              </colgroup>
              <thead>
                <tr>
                  <th colspan="6" class="txtleft">▼ View time at left side</th>
                </tr>
              </thead>
              <tbody>
                <!-- ↓挿入箇所↓ -->

<tr class="first-child">
  <th rowspan="2">1</th>
  <th rowspan="2">10:00～<br class="sp_only">12:20</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>11:20～</td>
  <td>11:40～12:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">2</th>
  <th rowspan="2">10:00～<br class="sp_only">12:20</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>11:20～</td>
  <td>11:40～12:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">3</th>
  <th rowspan="2">10:00～<br class="sp_only">13:40</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>12:40～</td>
  <td>13:00～13:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">4</th>
  <th rowspan="2">10:00～<br class="sp_only">14:20</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>13:20～</td>
  <td>13:40～14:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">5</th>
  <th rowspan="2">10:00～<br class="sp_only">15:00</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>14:00～</td>
  <td>14:20～14:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">6</th>
  <th rowspan="2">10:00～<br class="sp_only">15:50</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">7</th>
  <th rowspan="2">10:00～<br class="sp_only">16:10</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">8</th>
  <th rowspan="2">10:00～<br class="sp_only">17:10</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">9</th>
  <th rowspan="2">10:00～<br class="sp_only">18:10</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">10</th>
  <th rowspan="2">10:10～<br class="sp_only">13:30</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>12:30～</td>
  <td>12:50～13:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">11～12</th>
  <th rowspan="2">10:10～<br class="sp_only">14:10</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>13:10～</td>
  <td>13:30～14:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">13</th>
  <th rowspan="2">10:10～<br class="sp_only">14:50</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>13:50～</td>
  <td>14:10～14:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">14～15</th>
  <th rowspan="2">10:10～<br class="sp_only">17:20</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">16</th>
  <th rowspan="2">10:10～<br class="sp_only">17:30</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">17</th>
  <th rowspan="2">10:10～<br class="sp_only">17:40</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">18</th>
  <th rowspan="2">10:10～<br class="sp_only">17:50</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">19</th>
  <th rowspan="2">10:10～<br class="sp_only">18:10</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">20</th>
  <th rowspan="2">10:20～<br class="sp_only">12:40</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>11:40～</td>
  <td>12:00～12:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">21</th>
  <th rowspan="2">10:20～<br class="sp_only">12:50</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>11:50～</td>
  <td>12:10～12:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">22</th>
  <th rowspan="2">10:20～<br class="sp_only">13:20</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>12:20～</td>
  <td>12:40～13:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">23</th>
  <th rowspan="2">10:20～<br class="sp_only">15:30</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">24</th>
  <th rowspan="2">10:20～<br class="sp_only">15:50</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">25</th>
  <th rowspan="2">10:20～<br class="sp_only">16:20</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">26～27</th>
  <th rowspan="2">10:20～<br class="sp_only">17:30</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">28</th>
  <th rowspan="2">10:20～<br class="sp_only">17:40</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">29</th>
  <th rowspan="2">10:20～<br class="sp_only">17:50</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">30</th>
  <th rowspan="2">10:20～<br class="sp_only">18:00</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">31</th>
  <th rowspan="2">10:20～<br class="sp_only">18:30</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>17:30～</td>
  <td>17:50～18:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">32</th>
  <th rowspan="2">10:30～<br class="sp_only">13:30</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>12:30～</td>
  <td>12:50～13:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">33</th>
  <th rowspan="2">10:30～<br class="sp_only">13:40</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>12:40～</td>
  <td>13:00～13:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">34</th>
  <th rowspan="2">10:30～<br class="sp_only">14:20</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>13:20～</td>
  <td>13:40～14:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">35</th>
  <th rowspan="2">10:30～<br class="sp_only">14:40</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>13:40～</td>
  <td>14:00～14:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">36</th>
  <th rowspan="2">10:30～<br class="sp_only">15:40</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">37</th>
  <th rowspan="2">10:30～<br class="sp_only">16:30</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">38～39</th>
  <th rowspan="2">10:30～<br class="sp_only">19:00</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>18:00～</td>
  <td>18:20～18:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">40</th>
  <th rowspan="2">10:40～<br class="sp_only">14:30</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>13:30～</td>
  <td>13:50～14:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">41</th>
  <th rowspan="2">10:40～<br class="sp_only">15:50</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">42</th>
  <th rowspan="2">10:40～<br class="sp_only">16:10</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">43</th>
  <th rowspan="2">10:40～<br class="sp_only">16:50</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">44</th>
  <th rowspan="2">10:40～<br class="sp_only">17:20</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">45</th>
  <th rowspan="2">10:40～<br class="sp_only">17:20</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">46</th>
  <th rowspan="2">10:40～<br class="sp_only">18:10</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">47</th>
  <th rowspan="2">10:40～<br class="sp_only">19:00</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>18:00～</td>
  <td>18:20～18:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">48</th>
  <th rowspan="2">10:50～<br class="sp_only">13:20</th>
  <td>10:50～</td>
  <td>11:10～11:40</td>
  <td>12:20～</td>
  <td>12:40～13:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">49～51</th>
  <th rowspan="2">10:50～<br class="sp_only">17:00</th>
  <td>10:50～</td>
  <td>11:10～11:40</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">52</th>
  <th rowspan="2">11:00～<br class="sp_only">14:50</th>
  <td>11:00～</td>
  <td>11:20～11:50</td>
  <td>13:50～</td>
  <td>14:10～14:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">53～62</th>
  <th rowspan="2">11:00～<br class="sp_only">15:00</th>
  <td>11:00～</td>
  <td>11:20～11:50</td>
  <td>14:00～</td>
  <td>14:20～14:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">63</th>
  <th rowspan="2">11:10～<br class="sp_only">13:30</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>12:30～</td>
  <td>12:50～13:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">64～73</th>
  <th rowspan="2">11:10～<br class="sp_only">15:10</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>14:10～</td>
  <td>14:30～15:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">74～75</th>
  <th rowspan="2">11:10～<br class="sp_only">15:20</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>14:20～</td>
  <td>14:40～15:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">76</th>
  <th rowspan="2">11:10～<br class="sp_only">15:40</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">77</th>
  <th rowspan="2">11:10～<br class="sp_only">16:40</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">78</th>
  <th rowspan="2">11:10～<br class="sp_only">17:30</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">79</th>
  <th rowspan="2">11:10～<br class="sp_only">18:10</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">80</th>
  <th rowspan="2">11:20～<br class="sp_only">13:40</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>12:40～</td>
  <td>13:00～13:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">81</th>
  <th rowspan="2">11:20～<br class="sp_only">14:10</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>13:10～</td>
  <td>13:30～14:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">82～91</th>
  <th rowspan="2">11:20～<br class="sp_only">15:20</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>14:20～</td>
  <td>14:40～15:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">92</th>
  <th rowspan="2">11:20～<br class="sp_only">16:50</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">93</th>
  <th rowspan="2">11:20～<br class="sp_only">18:10</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">94</th>
  <th rowspan="2">11:20～<br class="sp_only">18:10</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">95</th>
  <th rowspan="2">11:30～<br class="sp_only">14:30</th>
  <td>11:30～</td>
  <td>11:50～12:20</td>
  <td>13:30～</td>
  <td>13:50～14:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">96～97</th>
  <th rowspan="2">11:30～<br class="sp_only">15:10</th>
  <td>11:30～</td>
  <td>11:50～12:20</td>
  <td>14:10～</td>
  <td>14:30～15:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">98～107</th>
  <th rowspan="2">11:30～<br class="sp_only">15:30</th>
  <td>11:30～</td>
  <td>11:50～12:20</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">108</th>
  <th rowspan="2">11:30～<br class="sp_only">17:00</th>
  <td>11:30～</td>
  <td>11:50～12:20</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">109</th>
  <th rowspan="2">11:30～<br class="sp_only">17:30</th>
  <td>11:30～</td>
  <td>11:50～12:20</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">110</th>
  <th rowspan="2">11:30～<br class="sp_only">17:50</th>
  <td>11:30～</td>
  <td>11:50～12:20</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">111</th>
  <th rowspan="2">11:40～<br class="sp_only">14:50</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>13:50～</td>
  <td>14:10～14:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">112～121</th>
  <th rowspan="2">11:40～<br class="sp_only">15:40</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">122</th>
  <th rowspan="2">11:40～<br class="sp_only">16:00</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">123</th>
  <th rowspan="2">11:40～<br class="sp_only">16:30</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">124</th>
  <th rowspan="2">11:40～<br class="sp_only">18:30</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>17:30～</td>
  <td>17:50～18:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">125</th>
  <th rowspan="2">11:50～<br class="sp_only">15:20</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>14:20～</td>
  <td>14:40～15:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">126</th>
  <th rowspan="2">11:50～<br class="sp_only">15:30</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">127</th>
  <th rowspan="2">11:50～<br class="sp_only">16:30</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">128</th>
  <th rowspan="2">11:50～<br class="sp_only">18:30</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>17:30～</td>
  <td>17:50～18:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">129</th>
  <th rowspan="2">12:00～<br class="sp_only">14:40</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>13:40～</td>
  <td>14:00～14:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">130</th>
  <th rowspan="2">12:00～<br class="sp_only">15:30</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">131</th>
  <th rowspan="2">12:00～<br class="sp_only">16:00</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">132</th>
  <th rowspan="2">12:00～<br class="sp_only">16:30</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">133</th>
  <th rowspan="2">12:00～<br class="sp_only">16:50</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">134</th>
  <th rowspan="2">12:00～<br class="sp_only">17:20</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">135</th>
  <th rowspan="2">12:00～<br class="sp_only">18:50</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>17:50～</td>
  <td>18:10～18:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">136</th>
  <th rowspan="2">12:10～<br class="sp_only">14:30</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>13:30～</td>
  <td>13:50～14:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">137</th>
  <th rowspan="2">12:10～<br class="sp_only">15:40</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">138</th>
  <th rowspan="2">12:10～<br class="sp_only">16:20</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">139</th>
  <th rowspan="2">12:10～<br class="sp_only">17:10</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">140～142</th>
  <th rowspan="2">12:10～<br class="sp_only">17:50</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">143</th>
  <th rowspan="2">12:10～<br class="sp_only">17:50</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">144～145</th>
  <th rowspan="2">12:10～<br class="sp_only">18:10</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">146</th>
  <th rowspan="2">12:10～<br class="sp_only">18:20</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>17:20～</td>
  <td>17:40～18:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">147</th>
  <th rowspan="2">12:10～<br class="sp_only">18:30</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>17:30～</td>
  <td>17:50～18:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">148</th>
  <th rowspan="2">12:20～<br class="sp_only">14:40</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>13:40～</td>
  <td>14:00～14:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">149</th>
  <th rowspan="2">12:20～<br class="sp_only">15:30</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">150</th>
  <th rowspan="2">12:20～<br class="sp_only">16:00</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">151～152</th>
  <th rowspan="2">12:20～<br class="sp_only">17:40</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">153</th>
  <th rowspan="2">12:20～<br class="sp_only">18:40</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>17:40～</td>
  <td>18:00～18:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">154</th>
  <th rowspan="2">12:30～<br class="sp_only">15:30</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">155</th>
  <th rowspan="2">12:30～<br class="sp_only">16:00</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">156</th>
  <th rowspan="2">12:30～<br class="sp_only">16:50</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">157</th>
  <th rowspan="2">12:30～<br class="sp_only">17:00</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">158</th>
  <th rowspan="2">12:30～<br class="sp_only">17:50</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">159～160</th>
  <th rowspan="2">12:30～<br class="sp_only">18:00</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">162</th>
  <th rowspan="2">12:40～<br class="sp_only">15:10</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>14:10～</td>
  <td>14:30～15:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">163</th>
  <th rowspan="2">12:40～<br class="sp_only">15:20</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>14:20～</td>
  <td>14:40～15:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">164</th>
  <th rowspan="2">12:40～<br class="sp_only">15:50</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">165～166</th>
  <th rowspan="2">12:40～<br class="sp_only">16:20</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">167</th>
  <th rowspan="2">12:40～<br class="sp_only">16:40</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">168</th>
  <th rowspan="2">12:40～<br class="sp_only">17:00</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">169</th>
  <th rowspan="2">12:40～<br class="sp_only">17:30</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">170</th>
  <th rowspan="2">12:40～<br class="sp_only">17:50</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">171</th>
  <th rowspan="2">12:40～<br class="sp_only">18:00</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">172</th>
  <th rowspan="2">12:40～<br class="sp_only">18:00</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">173</th>
  <th rowspan="2">12:50～<br class="sp_only">15:10</th>
  <td>12:50～</td>
  <td>13:10～13:40</td>
  <td>14:10～</td>
  <td>14:30～15:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">174</th>
  <th rowspan="2">12:50～<br class="sp_only">16:00</th>
  <td>12:50～</td>
  <td>13:10～13:40</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">175</th>
  <th rowspan="2">12:50～<br class="sp_only">16:20</th>
  <td>12:50～</td>
  <td>13:10～13:40</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">176</th>
  <th rowspan="2">13:00～<br class="sp_only">15:30</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">177</th>
  <th rowspan="2">13:00～<br class="sp_only">15:50</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">178</th>
  <th rowspan="2">13:00～<br class="sp_only">15:50</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">179</th>
  <th rowspan="2">13:00～<br class="sp_only">16:20</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">180</th>
  <th rowspan="2">13:00～<br class="sp_only">16:20</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">181～190</th>
  <th rowspan="2">13:00～<br class="sp_only">16:30</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">191</th>
  <th rowspan="2">13:00～<br class="sp_only">17:10</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">192</th>
  <th rowspan="2">13:00～<br class="sp_only">17:50</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">193</th>
  <th rowspan="2">13:00～<br class="sp_only">18:50</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>17:50～</td>
  <td>18:10～18:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">194</th>
  <th rowspan="2">13:10～<br class="sp_only">15:50</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">195～204</th>
  <th rowspan="2">13:10～<br class="sp_only">16:40</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">205</th>
  <th rowspan="2">13:10～<br class="sp_only">17:20</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">206</th>
  <th rowspan="2">13:10～<br class="sp_only">17:30</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">207</th>
  <th rowspan="2">13:10～<br class="sp_only">18:00</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">208～209</th>
  <th rowspan="2">13:10～<br class="sp_only">18:10</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">210</th>
  <th rowspan="2">13:10～<br class="sp_only">18:10</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">211</th>
  <th rowspan="2">13:20～<br class="sp_only">15:40</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">212</th>
  <th rowspan="2">13:20～<br class="sp_only">15:50</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">213～214</th>
  <th rowspan="2">13:20～<br class="sp_only">16:30</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">215</th>
  <th rowspan="2">13:20～<br class="sp_only">16:30</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">216～225</th>
  <th rowspan="2">13:20～<br class="sp_only">16:50</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">226</th>
  <th rowspan="2">13:20～<br class="sp_only">17:00</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">227</th>
  <th rowspan="2">13:20～<br class="sp_only">17:10</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">228</th>
  <th rowspan="2">13:20～<br class="sp_only">17:30</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">229</th>
  <th rowspan="2">13:20～<br class="sp_only">18:00</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">230</th>
  <th rowspan="2">13:20～<br class="sp_only">18:20</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>17:20～</td>
  <td>17:40～18:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">231</th>
  <th rowspan="2">13:20～<br class="sp_only">19:10</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>18:10～</td>
  <td>18:30～19:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">232</th>
  <th rowspan="2">13:30～<br class="sp_only">16:40</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">233～240</th>
  <th rowspan="2">13:30～<br class="sp_only">17:00</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">241</th>
  <th rowspan="2">13:30～<br class="sp_only">17:00</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">242～243</th>
  <th rowspan="2">13:30～<br class="sp_only">17:00</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">244</th>
  <th rowspan="2">13:30～<br class="sp_only">17:30</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">245</th>
  <th rowspan="2">13:30～<br class="sp_only">18:10</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">246</th>
  <th rowspan="2">13:30～<br class="sp_only">18:20</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
  <td>17:20～</td>
  <td>17:40～18:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">247</th>
  <th rowspan="2">13:40～<br class="sp_only">16:30</th>
  <td>13:40～</td>
  <td>14:00～14:30</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">248～257</th>
  <th rowspan="2">13:40～<br class="sp_only">17:10</th>
  <td>13:40～</td>
  <td>14:00～14:30</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">258</th>
  <th rowspan="2">13:40～<br class="sp_only">17:50</th>
  <td>13:40～</td>
  <td>14:00～14:30</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">259</th>
  <th rowspan="2">13:50～<br class="sp_only">16:50</th>
  <td>13:50～</td>
  <td>14:10～14:40</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">260～261</th>
  <th rowspan="2">13:50～<br class="sp_only">17:10</th>
  <td>13:50～</td>
  <td>14:10～14:40</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">262～263</th>
  <th rowspan="2">13:50～<br class="sp_only">17:20</th>
  <td>13:50～</td>
  <td>14:10～14:40</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">264</th>
  <th rowspan="2">13:50～<br class="sp_only">17:30</th>
  <td>13:50～</td>
  <td>14:10～14:40</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">265</th>
  <th rowspan="2">14:00～<br class="sp_only">17:20</th>
  <td>14:00～</td>
  <td>14:20～14:50</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">266</th>
  <th rowspan="2">14:00～<br class="sp_only">17:40</th>
  <td>14:00～</td>
  <td>14:20～14:50</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">267</th>
  <th rowspan="2">14:00～<br class="sp_only">18:40</th>
  <td>14:00～</td>
  <td>14:20～14:50</td>
  <td>17:40～</td>
  <td>18:00～18:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">268</th>
  <th rowspan="2">14:10～<br class="sp_only">16:30</th>
  <td>14:10～</td>
  <td>14:30～15:00</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">269</th>
  <th rowspan="2">14:10～<br class="sp_only">16:50</th>
  <td>14:10～</td>
  <td>14:30～15:00</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">270</th>
  <th rowspan="2">14:10～<br class="sp_only">17:20</th>
  <td>14:10～</td>
  <td>14:30～15:00</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">271</th>
  <th rowspan="2">14:20～<br class="sp_only">16:40</th>
  <td>14:20～</td>
  <td>14:40～15:10</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">272</th>
  <th rowspan="2">14:20～<br class="sp_only">16:50</th>
  <td>14:20～</td>
  <td>14:40～15:10</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">273</th>
  <th rowspan="2">14:20～<br class="sp_only">17:00</th>
  <td>14:20～</td>
  <td>14:40～15:10</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">274</th>
  <th rowspan="2">14:20～<br class="sp_only">17:40</th>
  <td>14:20～</td>
  <td>14:40～15:10</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">275</th>
  <th rowspan="2">14:20～<br class="sp_only">18:00</th>
  <td>14:20～</td>
  <td>14:40～15:10</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">276</th>
  <th rowspan="2">14:30～<br class="sp_only">18:10</th>
  <td>14:30～</td>
  <td>14:50～15:20</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">277</th>
  <th rowspan="2">14:30～<br class="sp_only">18:10</th>
  <td>14:30～</td>
  <td>14:50～15:20</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">278</th>
  <th rowspan="2">14:40～<br class="sp_only">17:20</th>
  <td>14:40～</td>
  <td>15:00～15:30</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">279</th>
  <th rowspan="2">14:40～<br class="sp_only">18:00</th>
  <td>14:40～</td>
  <td>15:00～15:30</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">280</th>
  <th rowspan="2">14:40～<br class="sp_only">18:10</th>
  <td>14:40～</td>
  <td>15:00～15:30</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">281</th>
  <th rowspan="2">14:40～<br class="sp_only">18:10</th>
  <td>14:40～</td>
  <td>15:00～15:30</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">282</th>
  <th rowspan="2">14:40～<br class="sp_only">18:20</th>
  <td>14:40～</td>
  <td>15:00～15:30</td>
  <td>17:20～</td>
  <td>17:40～18:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">283</th>
  <th rowspan="2">14:40～<br class="sp_only">21:00</th>
  <td>14:40～</td>
  <td>15:00～15:30</td>
  <td>20:00～</td>
  <td>20:20～20:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">284</th>
  <th rowspan="2">14:50～<br class="sp_only">17:20</th>
  <td>14:50～</td>
  <td>15:10～15:40</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">285</th>
  <th rowspan="2">14:50～<br class="sp_only">17:40</th>
  <td>14:50～</td>
  <td>15:10～15:40</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">286</th>
  <th rowspan="2">14:50～<br class="sp_only">18:00</th>
  <td>14:50～</td>
  <td>15:10～15:40</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">287</th>
  <th rowspan="2">14:50～<br class="sp_only">18:00</th>
  <td>14:50～</td>
  <td>15:10～15:40</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">288</th>
  <th rowspan="2">14:50～<br class="sp_only">18:50</th>
  <td>14:50～</td>
  <td>15:10～15:40</td>
  <td>17:50～</td>
  <td>18:10～18:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">289～290</th>
  <th rowspan="2">15:10～<br class="sp_only">18:00</th>
  <td>15:10～</td>
  <td>15:30～16:00</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">291</th>
  <th rowspan="2">15:10～<br class="sp_only">18:10</th>
  <td>15:10～</td>
  <td>15:30～16:00</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">292</th>
  <th rowspan="2">15:20～<br class="sp_only">17:50</th>
  <td>15:20～</td>
  <td>15:40～16:10</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">293</th>
  <th rowspan="2">15:20～<br class="sp_only">18:50</th>
  <td>15:20～</td>
  <td>15:40～16:10</td>
  <td>17:50～</td>
  <td>18:10～18:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">294</th>
  <th rowspan="2">15:30～<br class="sp_only">18:50</th>
  <td>15:30～</td>
  <td>15:50～16:20</td>
  <td>17:50～</td>
  <td>18:10～18:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">295</th>
  <th rowspan="2">15:40～<br class="sp_only">18:10</th>
  <td>15:40～</td>
  <td>16:00～16:30</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">296</th>
  <th rowspan="2">16:10～<br class="sp_only">19:00</th>
  <td>16:10～</td>
  <td>16:30～17:00</td>
  <td>18:00～</td>
  <td>18:20～18:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">297</th>
  <th rowspan="2">18:50～<br class="sp_only">21:10</th>
  <td>18:50～</td>
  <td>19:10～19:40</td>
  <td>20:10～</td>
  <td>20:30～21:00</td>
</tr>
<tr class="last-child">
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>

                <!-- ↑挿入箇所↑ -->
              </tbody>
      </table>
      <table v-if="schedule === '2'" class="cmn_att_time01 cmn_tbl01">
              <caption>Admission date：June 1st～July 1st</caption>
              <colgroup>
              <col class="w100">
              <col>
              </colgroup>
              <thead>
                <tr>
                  <th colspan="6" class="txtleft">▼ View time at left side</th>
                </tr>
              </thead>
              <tbody>
                <!-- ↓挿入箇所↓ -->

<tr class="first-child">
  <th rowspan="2">1</th>
  <th rowspan="2">10:00～<br class="sp_only">13:50</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>12:50～</td>
  <td>13:10～13:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">2</th>
  <th rowspan="2">10:10～<br class="sp_only">18:10</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">3</th>
  <th rowspan="2">10:20～<br class="sp_only">17:10</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">4</th>
  <th rowspan="2">10:30～<br class="sp_only">17:40</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">5</th>
  <th rowspan="2">10:30～<br class="sp_only">17:50</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">6</th>
  <th rowspan="2">10:40～<br class="sp_only">13:40</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>12:40～</td>
  <td>13:00～13:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">7</th>
  <th rowspan="2">10:40～<br class="sp_only">16:30</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">8～10</th>
  <th rowspan="2">11:00～<br class="sp_only">15:00</th>
  <td>11:00～</td>
  <td>11:20～11:50</td>
  <td>14:00～</td>
  <td>14:20～14:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">11～13</th>
  <th rowspan="2">11:10～<br class="sp_only">15:10</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>14:10～</td>
  <td>14:30～15:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">14</th>
  <th rowspan="2">11:10～<br class="sp_only">17:00</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">15～17</th>
  <th rowspan="2">11:20～<br class="sp_only">15:20</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>14:20～</td>
  <td>14:40～15:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">18～20</th>
  <th rowspan="2">11:30～<br class="sp_only">15:30</th>
  <td>11:30～</td>
  <td>11:50～12:20</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">21</th>
  <th rowspan="2">11:30～<br class="sp_only">16:10</th>
  <td>11:30～</td>
  <td>11:50～12:20</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">22</th>
  <th rowspan="2">11:30～<br class="sp_only">17:20</th>
  <td>11:30～</td>
  <td>11:50～12:20</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">23～25</th>
  <th rowspan="2">11:40～<br class="sp_only">15:40</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">26</th>
  <th rowspan="2">11:40～<br class="sp_only">15:50</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">27</th>
  <th rowspan="2">11:50～<br class="sp_only">15:40</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">28</th>
  <th rowspan="2">11:50～<br class="sp_only">16:40</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">29～30</th>
  <th rowspan="2">12:00～<br class="sp_only">15:30</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">31</th>
  <th rowspan="2">12:00～<br class="sp_only">17:40</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">32</th>
  <th rowspan="2">12:10～<br class="sp_only">18:00</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">33</th>
  <th rowspan="2">12:30～<br class="sp_only">15:30</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">34</th>
  <th rowspan="2">12:30～<br class="sp_only">18:00</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">35</th>
  <th rowspan="2">12:40～<br class="sp_only">16:50</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">36</th>
  <th rowspan="2">12:40～<br class="sp_only">17:00</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">37</th>
  <th rowspan="2">12:40～<br class="sp_only">17:10</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">38</th>
  <th rowspan="2">13:00～<br class="sp_only">15:30</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">39～41</th>
  <th rowspan="2">13:00～<br class="sp_only">16:30</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">42</th>
  <th rowspan="2">13:00～<br class="sp_only">17:20</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">43～44</th>
  <th rowspan="2">13:00～<br class="sp_only">17:50</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">45～47</th>
  <th rowspan="2">13:10～<br class="sp_only">16:40</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">48～50</th>
  <th rowspan="2">13:20～<br class="sp_only">16:50</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">51～53</th>
  <th rowspan="2">13:30～<br class="sp_only">17:00</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">54</th>
  <th rowspan="2">13:30～<br class="sp_only">17:30</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">55～57</th>
  <th rowspan="2">13:40～<br class="sp_only">17:10</th>
  <td>13:40～</td>
  <td>14:00～14:30</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">58</th>
  <th rowspan="2">13:40～<br class="sp_only">18:10</th>
  <td>13:40～</td>
  <td>14:00～14:30</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">59</th>
  <th rowspan="2">13:50～<br class="sp_only">16:30</th>
  <td>13:50～</td>
  <td>14:10～14:40</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">60</th>
  <th rowspan="2">13:50～<br class="sp_only">16:40</th>
  <td>13:50～</td>
  <td>14:10～14:40</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">61</th>
  <th rowspan="2">14:00～<br class="sp_only">16:40</th>
  <td>14:00～</td>
  <td>14:20～14:50</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">62</th>
  <th rowspan="2">14:50～<br class="sp_only">18:00</th>
  <td>14:50～</td>
  <td>15:10～15:40</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">63</th>
  <th rowspan="2">14:50～<br class="sp_only">18:10</th>
  <td>14:50～</td>
  <td>15:10～15:40</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr class="last-child">
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>

                <!-- ↑挿入箇所↑ -->
              </tbody>
      </table>
    </div>

    <div v-if="productId === 10" class="table_content current">
      <div>
        <p class="content">Harry Potter and the Forbidden Journey ™ will be closed from Monday, May 10th to Sunday, May 16th.</p>
        <p class="content">If you are using a time-designated Studio Pass (such as those that permit entry after 3 p.m.), or an Express Pass, and you plan to participate in a time-designated event, there are time slots in which you will not be able to participate. </p>
        <p class="content">When you are deciding when to participate, make sure the times do not overlap.</p>
        <p class="content">Availability may be limited depending on the entry date. Please check the availability calendar in advance.</p>
        <!-- <p class="content">For attractions that do not specify other times, please use at times other than the following</p> -->
        <!-- <p class="content">It may be sold out. Also, depending on the admission date, there may be times when it is not sold.</p> -->
      </div>
      <el-row class="btn_box">
          <div href="session" @click="schedule = '1'" class="select_designated_time_btn" :class="{'btn-active': schedule === '1'}">
            Admission date：～May 31st
            <img src="@/assets/down-icon.png" alt="">
          </div>
          <div href="session" @click="schedule = '2'" class="select_designated_time_btn" :class="{'btn-active': schedule === '2'}">
            Admission date：June 1st～July 1st
            <img src="@/assets/down-icon.png" alt="">
          </div>
      </el-row>
      <table v-if="schedule === '1'" class="cmn_att_time01 cmn_tbl01">
              <caption>Admission date：～May 31st</caption>
              <colgroup>
              <col class="w100">
              <col>
              </colgroup>
              <thead>
                <tr>
                  <th colspan="6" class="txtleft">▼ View time at left side</th>
                </tr>
              </thead>
              <tbody>
                <!-- ↓挿入箇所↓ -->

<tr>
  <th rowspan="2">1</th>
  <th rowspan="2">10:00～<br class="sp_only">13:40</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>12:40～</td>
  <td>13:00～13:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">2～4</th>
  <th rowspan="2">10:00～<br class="sp_only">13:50</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>12:50～</td>
  <td>13:10～13:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">5</th>
  <th rowspan="2">10:00～<br class="sp_only">14:00</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>13:00～</td>
  <td>13:20～13:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">6</th>
  <th rowspan="2">10:00～<br class="sp_only">14:40</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>13:40～</td>
  <td>14:00～14:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">7</th>
  <th rowspan="2">10:00～<br class="sp_only">16:30</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">8～9</th>
  <th rowspan="2">10:10～<br class="sp_only">16:40</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">10</th>
  <th rowspan="2">10:10～<br class="sp_only">17:20</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">11</th>
  <th rowspan="2">10:10～<br class="sp_only">18:00</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">12</th>
  <th rowspan="2">10:10～<br class="sp_only">19:10</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>18:10～</td>
  <td>18:30～19:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">13</th>
  <th rowspan="2">10:20～<br class="sp_only">12:40</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>11:40～</td>
  <td>12:00～12:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">14</th>
  <th rowspan="2">10:20～<br class="sp_only">14:20</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>13:20～</td>
  <td>13:40～14:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">15</th>
  <th rowspan="2">10:20～<br class="sp_only">16:30</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">16</th>
  <th rowspan="2">10:20～<br class="sp_only">19:10</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>18:10～</td>
  <td>18:30～19:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">17</th>
  <th rowspan="2">10:30～<br class="sp_only">13:30</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>12:30～</td>
  <td>12:50～13:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">18</th>
  <th rowspan="2">10:30～<br class="sp_only">14:00</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>13:00～</td>
  <td>13:20～13:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">19</th>
  <th rowspan="2">10:30～<br class="sp_only">14:40</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>13:40～</td>
  <td>14:00～14:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">20</th>
  <th rowspan="2">10:30～<br class="sp_only">15:20</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>14:20～</td>
  <td>14:40～15:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">21</th>
  <th rowspan="2">10:30～<br class="sp_only">15:30</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">22～23</th>
  <th rowspan="2">10:30～<br class="sp_only">15:40</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">24</th>
  <th rowspan="2">10:30～<br class="sp_only">16:00</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">25</th>
  <th rowspan="2">10:30～<br class="sp_only">18:30</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>17:30～</td>
  <td>17:50～18:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">26</th>
  <th rowspan="2">10:40～<br class="sp_only">14:30</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>13:30～</td>
  <td>13:50～14:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">27</th>
  <th rowspan="2">10:40～<br class="sp_only">14:30</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>13:30～</td>
  <td>13:50～14:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">28</th>
  <th rowspan="2">10:40～<br class="sp_only">15:00</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>14:00～</td>
  <td>14:20～14:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">29</th>
  <th rowspan="2">10:40～<br class="sp_only">16:30</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">30</th>
  <th rowspan="2">10:40～<br class="sp_only">16:40</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">31</th>
  <th rowspan="2">10:40～<br class="sp_only">17:10</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">32</th>
  <th rowspan="2">10:40～<br class="sp_only">17:30</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">33</th>
  <th rowspan="2">10:40～<br class="sp_only">19:00</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>18:00～</td>
  <td>18:20～18:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">34</th>
  <th rowspan="2">10:50～<br class="sp_only">15:10</th>
  <td>10:50～</td>
  <td>11:10～11:40</td>
  <td>14:10～</td>
  <td>14:30～15:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">35</th>
  <th rowspan="2">10:50～<br class="sp_only">15:50</th>
  <td>10:50～</td>
  <td>11:10～11:40</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">36</th>
  <th rowspan="2">10:50～<br class="sp_only">17:00</th>
  <td>10:50～</td>
  <td>11:10～11:40</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">37</th>
  <th rowspan="2">10:50～<br class="sp_only">17:00</th>
  <td>10:50～</td>
  <td>11:10～11:40</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">38</th>
  <th rowspan="2">10:50～<br class="sp_only">19:10</th>
  <td>10:50～</td>
  <td>11:10～11:40</td>
  <td>18:10～</td>
  <td>18:30～19:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">39</th>
  <th rowspan="2">10:50～<br class="sp_only">20:00</th>
  <td>10:50～</td>
  <td>11:10～11:40</td>
  <td>19:00～</td>
  <td>19:20～19:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">40</th>
  <th rowspan="2">11:00～<br class="sp_only">13:40</th>
  <td>11:00～</td>
  <td>11:20～11:50</td>
  <td>12:40～</td>
  <td>13:00～13:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">41</th>
  <th rowspan="2">11:00～<br class="sp_only">15:20</th>
  <td>11:00～</td>
  <td>11:20～11:50</td>
  <td>14:20～</td>
  <td>14:40～15:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">42</th>
  <th rowspan="2">11:00～<br class="sp_only">16:20</th>
  <td>11:00～</td>
  <td>11:20～11:50</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">43～44</th>
  <th rowspan="2">11:00～<br class="sp_only">17:10</th>
  <td>11:00～</td>
  <td>11:20～11:50</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">45～46</th>
  <th rowspan="2">11:10～<br class="sp_only">13:30</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>12:30～</td>
  <td>12:50～13:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">47</th>
  <th rowspan="2">11:10～<br class="sp_only">13:50</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>12:50～</td>
  <td>13:10～13:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">48</th>
  <th rowspan="2">11:10～<br class="sp_only">14:40</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>13:40～</td>
  <td>14:00～14:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">49</th>
  <th rowspan="2">11:10～<br class="sp_only">15:50</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">50</th>
  <th rowspan="2">11:10～<br class="sp_only">16:40</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">51～52</th>
  <th rowspan="2">11:10～<br class="sp_only">16:50</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">53</th>
  <th rowspan="2">11:10～<br class="sp_only">17:50</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">54</th>
  <th rowspan="2">11:10～<br class="sp_only">18:10</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">55</th>
  <th rowspan="2">11:10～<br class="sp_only">19:10</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
  <td>18:10～</td>
  <td>18:30～19:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">56</th>
  <th rowspan="2">11:20～<br class="sp_only">13:40</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>12:40～</td>
  <td>13:00～13:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">57</th>
  <th rowspan="2">11:20～<br class="sp_only">15:40</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">58</th>
  <th rowspan="2">11:20～<br class="sp_only">16:50</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">59</th>
  <th rowspan="2">11:20～<br class="sp_only">17:30</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">60</th>
  <th rowspan="2">11:20～<br class="sp_only">17:40</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">61</th>
  <th rowspan="2">11:20～<br class="sp_only">17:50</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">62～63</th>
  <th rowspan="2">11:20～<br class="sp_only">18:10</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">64</th>
  <th rowspan="2">11:20～<br class="sp_only">18:30</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>17:30～</td>
  <td>17:50～18:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">65</th>
  <th rowspan="2">11:30～<br class="sp_only">16:40</th>
  <td>11:30～</td>
  <td>11:50～12:20</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">66</th>
  <th rowspan="2">11:30～<br class="sp_only">17:50</th>
  <td>11:30～</td>
  <td>11:50～12:20</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">67</th>
  <th rowspan="2">11:30～<br class="sp_only">18:30</th>
  <td>11:30～</td>
  <td>11:50～12:20</td>
  <td>17:30～</td>
  <td>17:50～18:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">68</th>
  <th rowspan="2">11:40～<br class="sp_only">14:00</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>13:00～</td>
  <td>13:20～13:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">69</th>
  <th rowspan="2">11:40～<br class="sp_only">14:20</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>13:20～</td>
  <td>13:40～14:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">70</th>
  <th rowspan="2">11:40～<br class="sp_only">15:00</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>14:00～</td>
  <td>14:20～14:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">71</th>
  <th rowspan="2">11:40～<br class="sp_only">15:10</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>14:10～</td>
  <td>14:30～15:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">72</th>
  <th rowspan="2">11:40～<br class="sp_only">15:50</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">73</th>
  <th rowspan="2">11:40～<br class="sp_only">16:10</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">74</th>
  <th rowspan="2">11:40～<br class="sp_only">16:30</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">75</th>
  <th rowspan="2">11:50～<br class="sp_only">14:10</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>13:10～</td>
  <td>13:30～14:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">76</th>
  <th rowspan="2">11:50～<br class="sp_only">14:40</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>13:40～</td>
  <td>14:00～14:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">77</th>
  <th rowspan="2">11:50～<br class="sp_only">15:00</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>14:00～</td>
  <td>14:20～14:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">78</th>
  <th rowspan="2">11:50～<br class="sp_only">15:40</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">79</th>
  <th rowspan="2">11:50～<br class="sp_only">16:40</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">80</th>
  <th rowspan="2">11:50～<br class="sp_only">16:50</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">81</th>
  <th rowspan="2">11:50～<br class="sp_only">17:00</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">82</th>
  <th rowspan="2">11:50～<br class="sp_only">17:10</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">83</th>
  <th rowspan="2">11:50～<br class="sp_only">17:40</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">84</th>
  <th rowspan="2">11:50～<br class="sp_only">18:00</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">85</th>
  <th rowspan="2">12:00～<br class="sp_only">14:40</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>13:40～</td>
  <td>14:00～14:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">86</th>
  <th rowspan="2">12:00～<br class="sp_only">14:50</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>13:50～</td>
  <td>14:10～14:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">87</th>
  <th rowspan="2">12:00～<br class="sp_only">15:00</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>14:00～</td>
  <td>14:20～14:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">88</th>
  <th rowspan="2">12:00～<br class="sp_only">15:10</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>14:10～</td>
  <td>14:30～15:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">89</th>
  <th rowspan="2">12:00～<br class="sp_only">15:30</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">90</th>
  <th rowspan="2">12:00～<br class="sp_only">15:50</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">91</th>
  <th rowspan="2">12:00～<br class="sp_only">16:10</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">92</th>
  <th rowspan="2">12:00～<br class="sp_only">16:10</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">93</th>
  <th rowspan="2">12:00～<br class="sp_only">16:10</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">94</th>
  <th rowspan="2">12:00～<br class="sp_only">16:40</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">95</th>
  <th rowspan="2">12:00～<br class="sp_only">18:00</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">96</th>
  <th rowspan="2">12:00～<br class="sp_only">18:20</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>17:20～</td>
  <td>17:40～18:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">97</th>
  <th rowspan="2">12:10～<br class="sp_only">15:10</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>14:10～</td>
  <td>14:30～15:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">98</th>
  <th rowspan="2">12:10～<br class="sp_only">15:10</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>14:10～</td>
  <td>14:30～15:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">99～100</th>
  <th rowspan="2">12:10～<br class="sp_only">15:40</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">101</th>
  <th rowspan="2">12:10～<br class="sp_only">16:20</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">102</th>
  <th rowspan="2">12:10～<br class="sp_only">16:20</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">103</th>
  <th rowspan="2">12:10～<br class="sp_only">16:50</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">104～106</th>
  <th rowspan="2">12:10～<br class="sp_only">17:20</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">107</th>
  <th rowspan="2">12:20～<br class="sp_only">14:40</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>13:40～</td>
  <td>14:00～14:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">108</th>
  <th rowspan="2">12:20～<br class="sp_only">15:20</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>14:20～</td>
  <td>14:40～15:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">109</th>
  <th rowspan="2">12:20～<br class="sp_only">15:40</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">110</th>
  <th rowspan="2">12:20～<br class="sp_only">16:00</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">111～112</th>
  <th rowspan="2">12:20～<br class="sp_only">17:10</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">113</th>
  <th rowspan="2">12:20～<br class="sp_only">17:30</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">114～115</th>
  <th rowspan="2">12:20～<br class="sp_only">18:10</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">116</th>
  <th rowspan="2">12:30～<br class="sp_only">16:10</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">117</th>
  <th rowspan="2">12:30～<br class="sp_only">16:20</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">118</th>
  <th rowspan="2">12:30～<br class="sp_only">16:50</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">119</th>
  <th rowspan="2">12:30～<br class="sp_only">17:40</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">120～121</th>
  <th rowspan="2">12:40～<br class="sp_only">15:30</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">122～124</th>
  <th rowspan="2">12:40～<br class="sp_only">15:40</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">125</th>
  <th rowspan="2">12:40～<br class="sp_only">15:40</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">126</th>
  <th rowspan="2">12:40～<br class="sp_only">15:50</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">127～128</th>
  <th rowspan="2">12:40～<br class="sp_only">15:50</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">129～130</th>
  <th rowspan="2">12:40～<br class="sp_only">16:40</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">131～132</th>
  <th rowspan="2">12:40～<br class="sp_only">17:00</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">133</th>
  <th rowspan="2">12:40～<br class="sp_only">18:00</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">134</th>
  <th rowspan="2">12:50～<br class="sp_only">16:50</th>
  <td>12:50～</td>
  <td>13:10～13:40</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">135</th>
  <th rowspan="2">12:50～<br class="sp_only">17:30</th>
  <td>12:50～</td>
  <td>13:10～13:40</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">136～137</th>
  <th rowspan="2">12:50～<br class="sp_only">17:50</th>
  <td>12:50～</td>
  <td>13:10～13:40</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">138</th>
  <th rowspan="2">13:00～<br class="sp_only">15:30</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">139</th>
  <th rowspan="2">13:00～<br class="sp_only">16:00</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">140</th>
  <th rowspan="2">13:00～<br class="sp_only">16:10</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">141</th>
  <th rowspan="2">13:00～<br class="sp_only">16:20</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">142</th>
  <th rowspan="2">13:00～<br class="sp_only">16:30</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">143～144</th>
  <th rowspan="2">13:00～<br class="sp_only">17:10</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">145</th>
  <th rowspan="2">13:00～<br class="sp_only">17:40</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">146</th>
  <th rowspan="2">13:10～<br class="sp_only">15:40</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">147</th>
  <th rowspan="2">13:10～<br class="sp_only">16:30</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">148</th>
  <th rowspan="2">13:10～<br class="sp_only">16:40</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">149</th>
  <th rowspan="2">13:10～<br class="sp_only">16:50</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">150</th>
  <th rowspan="2">13:10～<br class="sp_only">17:50</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">151</th>
  <th rowspan="2">13:10～<br class="sp_only">18:00</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">152</th>
  <th rowspan="2">13:10～<br class="sp_only">18:10</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">153</th>
  <th rowspan="2">13:10～<br class="sp_only">19:10</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
  <td>18:10～</td>
  <td>18:30～19:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">154</th>
  <th rowspan="2">13:20～<br class="sp_only">15:50</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">155</th>
  <th rowspan="2">13:20～<br class="sp_only">16:10</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">156</th>
  <th rowspan="2">13:20～<br class="sp_only">17:20</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">157</th>
  <th rowspan="2">13:20～<br class="sp_only">17:50</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">158</th>
  <th rowspan="2">13:30～<br class="sp_only">15:50</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">159</th>
  <th rowspan="2">13:40～<br class="sp_only">16:10</th>
  <td>13:40～</td>
  <td>14:00～14:30</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">160</th>
  <th rowspan="2">13:40～<br class="sp_only">17:10</th>
  <td>13:40～</td>
  <td>14:00～14:30</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">161</th>
  <th rowspan="2">13:40～<br class="sp_only">17:40</th>
  <td>13:40～</td>
  <td>14:00～14:30</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">162</th>
  <th rowspan="2">13:40～<br class="sp_only">17:50</th>
  <td>13:40～</td>
  <td>14:00～14:30</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">163</th>
  <th rowspan="2">13:40～<br class="sp_only">18:00</th>
  <td>13:40～</td>
  <td>14:00～14:30</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">164</th>
  <th rowspan="2">13:40～<br class="sp_only">18:10</th>
  <td>13:40～</td>
  <td>14:00～14:30</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">165</th>
  <th rowspan="2">13:40～<br class="sp_only">18:10</th>
  <td>13:40～</td>
  <td>14:00～14:30</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">166</th>
  <th rowspan="2">13:50～<br class="sp_only">16:10</th>
  <td>13:50～</td>
  <td>14:10～14:40</td>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">167</th>
  <th rowspan="2">13:50～<br class="sp_only">16:20</th>
  <td>13:50～</td>
  <td>14:10～14:40</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">168</th>
  <th rowspan="2">13:50～<br class="sp_only">16:40</th>
  <td>13:50～</td>
  <td>14:10～14:40</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">169</th>
  <th rowspan="2">13:50～<br class="sp_only">17:10</th>
  <td>13:50～</td>
  <td>14:10～14:40</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">170</th>
  <th rowspan="2">13:50～<br class="sp_only">17:20</th>
  <td>13:50～</td>
  <td>14:10～14:40</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">171</th>
  <th rowspan="2">13:50～<br class="sp_only">17:50</th>
  <td>13:50～</td>
  <td>14:10～14:40</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">172</th>
  <th rowspan="2">14:00～<br class="sp_only">16:40</th>
  <td>14:00～</td>
  <td>14:20～14:50</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">173</th>
  <th rowspan="2">14:00～<br class="sp_only">17:40</th>
  <td>14:00～</td>
  <td>14:20～14:50</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">174</th>
  <th rowspan="2">14:00～<br class="sp_only">18:40</th>
  <td>14:00～</td>
  <td>14:20～14:50</td>
  <td>17:40～</td>
  <td>18:00～18:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">175</th>
  <th rowspan="2">14:10～<br class="sp_only">16:30</th>
  <td>14:10～</td>
  <td>14:30～15:00</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">176～177</th>
  <th rowspan="2">14:20～<br class="sp_only">17:30</th>
  <td>14:20～</td>
  <td>14:40～15:10</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">178</th>
  <th rowspan="2">14:20～<br class="sp_only">17:50</th>
  <td>14:20～</td>
  <td>14:40～15:10</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">179</th>
  <th rowspan="2">14:30～<br class="sp_only">18:00</th>
  <td>14:30～</td>
  <td>14:50～15:20</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">180</th>
  <th rowspan="2">14:40～<br class="sp_only">17:40</th>
  <td>14:40～</td>
  <td>15:00～15:30</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">181</th>
  <th rowspan="2">14:40～<br class="sp_only">17:50</th>
  <td>14:40～</td>
  <td>15:00～15:30</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">182</th>
  <th rowspan="2">14:40～<br class="sp_only">18:10</th>
  <td>14:40～</td>
  <td>15:00～15:30</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">183</th>
  <th rowspan="2">14:50～<br class="sp_only">17:10</th>
  <td>14:50～</td>
  <td>15:10～15:40</td>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">184</th>
  <th rowspan="2">14:50～<br class="sp_only">17:30</th>
  <td>14:50～</td>
  <td>15:10～15:40</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">185</th>
  <th rowspan="2">14:50～<br class="sp_only">17:30</th>
  <td>14:50～</td>
  <td>15:10～15:40</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">186</th>
  <th rowspan="2">14:50～<br class="sp_only">17:40</th>
  <td>14:50～</td>
  <td>15:10～15:40</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">187</th>
  <th rowspan="2">15:00～<br class="sp_only">17:20</th>
  <td>15:00～</td>
  <td>15:20～15:50</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">188～189</th>
  <th rowspan="2">15:00～<br class="sp_only">17:30</th>
  <td>15:00～</td>
  <td>15:20～15:50</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">190</th>
  <th rowspan="2">15:00～<br class="sp_only">18:00</th>
  <td>15:00～</td>
  <td>15:20～15:50</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">191</th>
  <th rowspan="2">15:00～<br class="sp_only">18:00</th>
  <td>15:00～</td>
  <td>15:20～15:50</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">192</th>
  <th rowspan="2">15:00～<br class="sp_only">18:10</th>
  <td>15:00～</td>
  <td>15:20～15:50</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">193</th>
  <th rowspan="2">15:00～<br class="sp_only">18:20</th>
  <td>15:00～</td>
  <td>15:20～15:50</td>
  <td>17:20～</td>
  <td>17:40～18:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">194</th>
  <th rowspan="2">15:10～<br class="sp_only">17:40</th>
  <td>15:10～</td>
  <td>15:30～16:00</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">195</th>
  <th rowspan="2">15:30～<br class="sp_only">18:00</th>
  <td>15:30～</td>
  <td>15:50～16:20</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">196</th>
  <th rowspan="2">15:40～<br class="sp_only">18:00</th>
  <td>15:40～</td>
  <td>16:00～16:30</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">197</th>
  <th rowspan="2">18:30～<br class="sp_only">21:10</th>
  <td>18:30～</td>
  <td>18:50～19:20</td>
  <td>20:10～</td>
  <td>20:30～21:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>

                <!-- ↑挿入箇所↑ -->
              </tbody>
      </table>
      <table v-if="schedule === '2'" class="cmn_att_time01 cmn_tbl01">
              <caption>Admission date：June 1st～July 1st</caption>
              <colgroup>
              <col class="w100">
              <col>
              </colgroup>
              <thead>
                <tr>
                  <th colspan="6" class="txtleft">▼ View time at left side</th>
                </tr>
              </thead>
              <tbody>
                <!-- ↓挿入箇所↓ -->

<tr>
  <th rowspan="2">1</th>
  <th rowspan="2">10:00～<br class="sp_only">15:30</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">2</th>
  <th rowspan="2">10:10～<br class="sp_only">13:50</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>12:50～</td>
  <td>13:10～13:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">3</th>
  <th rowspan="2">10:10～<br class="sp_only">14:10</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>13:10～</td>
  <td>13:30～14:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">4</th>
  <th rowspan="2">10:10～<br class="sp_only">17:00</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">5</th>
  <th rowspan="2">10:20～<br class="sp_only">13:40</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>12:40～</td>
  <td>13:00～13:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">6</th>
  <th rowspan="2">10:20～<br class="sp_only">15:30</th>
  <td>10:20～</td>
  <td>10:40～11:10</td>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">7</th>
  <th rowspan="2">10:30～<br class="sp_only">17:40</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">8</th>
  <th rowspan="2">10:40～<br class="sp_only">13:20</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
  <td>12:20～</td>
  <td>12:40～13:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">9</th>
  <th rowspan="2">10:50～<br class="sp_only">16:00</th>
  <td>10:50～</td>
  <td>11:10～11:40</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">10</th>
  <th rowspan="2">10:50～<br class="sp_only">18:00</th>
  <td>10:50～</td>
  <td>11:10～11:40</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">11</th>
  <th rowspan="2">10:50～<br class="sp_only">18:10</th>
  <td>10:50～</td>
  <td>11:10～11:40</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">12</th>
  <th rowspan="2">11:20～<br class="sp_only">14:00</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>13:00～</td>
  <td>13:20～13:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">13</th>
  <th rowspan="2">11:20～<br class="sp_only">15:50</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">14</th>
  <th rowspan="2">11:20～<br class="sp_only">17:50</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">15</th>
  <th rowspan="2">11:50～<br class="sp_only">16:00</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">16</th>
  <th rowspan="2">12:00～<br class="sp_only">15:50</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">17</th>
  <th rowspan="2">12:00～<br class="sp_only">16:30</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">18</th>
  <th rowspan="2">12:10～<br class="sp_only">15:50</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">19</th>
  <th rowspan="2">12:10～<br class="sp_only">16:20</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">20</th>
  <th rowspan="2">12:20～<br class="sp_only">14:40</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>13:40～</td>
  <td>14:00～14:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">21</th>
  <th rowspan="2">12:20～<br class="sp_only">16:30</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">22</th>
  <th rowspan="2">12:20～<br class="sp_only">17:20</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">23</th>
  <th rowspan="2">12:20～<br class="sp_only">17:20</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">24</th>
  <th rowspan="2">12:20～<br class="sp_only">17:30</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">25</th>
  <th rowspan="2">12:30～<br class="sp_only">16:00</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">26</th>
  <th rowspan="2">12:30～<br class="sp_only">17:30</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">27</th>
  <th rowspan="2">12:30～<br class="sp_only">17:50</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">28</th>
  <th rowspan="2">12:30～<br class="sp_only">18:00</th>
  <td>12:30～</td>
  <td>12:50～13:20</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">29</th>
  <th rowspan="2">12:40～<br class="sp_only">16:20</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">30</th>
  <th rowspan="2">12:40～<br class="sp_only">16:40</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">31</th>
  <th rowspan="2">12:40～<br class="sp_only">17:00</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">32</th>
  <th rowspan="2">12:50～<br class="sp_only">15:40</th>
  <td>12:50～</td>
  <td>13:10～13:40</td>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">33</th>
  <th rowspan="2">12:50～<br class="sp_only">15:50</th>
  <td>12:50～</td>
  <td>13:10～13:40</td>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">34</th>
  <th rowspan="2">13:00～<br class="sp_only">17:30</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">35</th>
  <th rowspan="2">13:20～<br class="sp_only">16:50</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">36</th>
  <th rowspan="2">13:30～<br class="sp_only">17:00</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">37</th>
  <th rowspan="2">14:00～<br class="sp_only">16:40</th>
  <td>14:00～</td>
  <td>14:20～14:50</td>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">38</th>
  <th rowspan="2">14:20～<br class="sp_only">18:10</th>
  <td>14:20～</td>
  <td>14:40～15:10</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">39</th>
  <th rowspan="2">14:30～<br class="sp_only">17:40</th>
  <td>14:30～</td>
  <td>14:50～15:20</td>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">40～41</th>
  <th rowspan="2">14:40～<br class="sp_only">18:00</th>
  <td>14:40～</td>
  <td>15:00～15:30</td>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>
<tr>
  <th rowspan="2">42</th>
  <th rowspan="2">14:50～<br class="sp_only">17:50</th>
  <td>14:50～</td>
  <td>15:10～15:40</td>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">43</th>
  <th rowspan="2">15:10～<br class="sp_only">18:10</th>
  <td>15:10～</td>
  <td>15:30～16:00</td>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Harry Potter and the Forbidden Journey™</td>
</tr>

                <!-- ↑挿入箇所↑ -->
              </tbody>
      </table>
    </div>
    <div v-if="productId === 11" class="table_content current">
      <div>
        <p class="content">If you are using a time-designated Studio Pass (such as those that permit entry after 3 p.m.), or an Express Pass, and you plan to participate in a time-designated event, there are time slots in which you will not be able to participate. </p>
        <p class="content">When you are deciding when to participate, make sure the times do not overlap.</p>
        <p class="content">Availability may be limited depending on the entry date. Please check the availability calendar in advance.</p>
        <!-- <p class="content">For attractions that do not specify other times, please use at times other than the following</p> -->
        <!-- <p class="content">It may be sold out. Also, depending on the admission date, there may be times when it is not sold.</p> -->
      </div>
      <table class="cmn_att_time01 cmn_tbl01 mt10">
              <!--<caption>入場日：～MM月DD日</caption>-->
              <colgroup>
              <col class="w100">
              <col>
              </colgroup>
              <thead>
                <tr>
                  <th colspan="8" class="txtleft">▼ View time at left side</th>
                </tr>
              </thead>
              <tbody>
                <!-- ↓挿入箇所↓ -->

<tr>
  <th rowspan="2">1</th>
  <th rowspan="2">10:00～<br class="sp_only">14:40</th>
  <td>10:00～<br class="sp_only">10:30</td>
  <td>11:30～</td>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>13:20～</td>
  <td>13:40～<br class="sp_only">14:10</td>
  <td>14:10～<br class="sp_only">14:40</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">2</th>
  <th rowspan="2">10:00～<br class="sp_only">15:30</th>
  <td>10:00～</td>
  <td>10:20～<br class="sp_only">10:50</td>
  <td>10:50～<br class="sp_only">11:20</td>
  <td>12:30～</td>
  <td>12:50～<br class="sp_only">13:20</td>
  <td>15:00～<br class="sp_only">15:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
</tr>
<tr>
  <th rowspan="2">3</th>
  <th rowspan="2">10:00～<br class="sp_only">15:50</th>
  <td>10:00～</td>
  <td>10:20～<br class="sp_only">10:50</td>
  <td>10:50～<br class="sp_only">11:20</td>
  <td>12:00～</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>15:20～<br class="sp_only">15:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
</tr>
<tr>
  <th rowspan="2">4</th>
  <th rowspan="2">10:00～<br class="sp_only">18:10</th>
  <td>10:00～<br class="sp_only">10:30</td>
  <td>11:40～</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>17:10～</td>
  <td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">5</th>
  <th rowspan="2">10:10～<br class="sp_only">15:40</th>
  <td>10:10～<br class="sp_only">10:40</td>
  <td>13:00～</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>14:20～</td>
  <td>14:40～<br class="sp_only">15:10</td>
  <td>15:10～<br class="sp_only">15:40</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">6</th>
  <th rowspan="2">10:10～<br class="sp_only">16:10</th>
  <td>10:10～</td>
  <td>10:30～<br class="sp_only">11:00</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>14:50～</td>
  <td>15:10～<br class="sp_only">15:40</td>
  <td>15:40～<br class="sp_only">16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">7</th>
  <th rowspan="2">10:10～<br class="sp_only">16:20</th>
  <td>10:10～<br class="sp_only">10:40</td>
  <td>12:00～</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>15:00～</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>15:50～<br class="sp_only">16:20</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>

</tr>
<tr>
  <th rowspan="2">8</th>
  <th rowspan="2">10:10～<br class="sp_only">17:10</th>
  <td>10:10～</td>
  <td>10:30～<br class="sp_only">11:00</td>
  <td>12:30～</td>
  <td>12:50～<br class="sp_only">13:20</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
</tr>
<tr>
  <th rowspan="2">9</th>
  <th rowspan="2">10:10～<br class="sp_only">17:20</th>
  <td>10:10～</td>
  <td>10:30～<br class="sp_only">11:00</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>15:20～</td>
  <td>15:40～<br class="sp_only">16:10</td>
  <td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
</tr>
<tr>
  <th rowspan="2">10</th>
  <th rowspan="2">10:20～<br class="sp_only">14:50</th>
  <td>10:20～</td>
  <td>10:40～<br class="sp_only">11:10</td>
  <td>11:50～</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>14:20～<br class="sp_only">14:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
</tr>
<tr>
  <th rowspan="2">11</th>
  <th rowspan="2">10:20～<br class="sp_only">15:20</th>
  <td>10:20～<br class="sp_only">10:50</td>
  <td>12:00～</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>14:00～</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>14:50～<br class="sp_only">15:20</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">12</th>
  <th rowspan="2">10:20～<br class="sp_only">17:10</th>
  <td>10:20～</td>
  <td>10:40～<br class="sp_only">11:10</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>15:50～</td>
  <td>16:10～<br class="sp_only">16:40</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">13</th>
  <th rowspan="2">10:20～<br class="sp_only">17:20</th>
  <td>10:20～<br class="sp_only">10:50</td>
  <td>14:40～</td>
  <td>15:00～<br class="sp_only">15:30</td>
  <td>16:00～</td>
  <td>16:20～<br class="sp_only">16:50</td>
  <td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">14</th>
  <th rowspan="2">10:20～<br class="sp_only">17:50</th>
  <td>10:20～</td>
  <td>10:40～<br class="sp_only">11:10</td>
  <td>12:50～<br class="sp_only">13:20</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">15</th>
  <th rowspan="2">10:30～<br class="sp_only">16:50</th>
  <td>10:30～<br class="sp_only">11:00</td>
  <td>11:30～</td>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>15:50～</td>
  <td>16:10～<br class="sp_only">16:40</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">16</th>
  <th rowspan="2">10:30～<br class="sp_only">17:50</th>
  <td>10:30～</td>
  <td>10:50～<br class="sp_only">11:20</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">17</th>
  <th rowspan="2">10:40～<br class="sp_only">17:10</th>
  <td>10:40～</td>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>16:10～</td>
  <td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">18</th>
  <th rowspan="2">10:40～<br class="sp_only">17:10</th>
  <td>10:40～<br class="sp_only">11:10</td>
  <td>12:50～</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>13:40～<br class="sp_only">14:10</td>
  <td>16:10～</td>
  <td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">19</th>
  <th rowspan="2">10:50～<br class="sp_only">15:40</th>
  <td>10:50～<br class="sp_only">11:20</td>
  <td>12:40～</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>14:40～</td>
  <td>15:00～<br class="sp_only">15:30</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">20</th>
  <th rowspan="2">10:50～<br class="sp_only">16:00</th>
  <td>10:50～</td>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>12:30～</td>
  <td>12:50～<br class="sp_only">13:20</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>15:30～<br class="sp_only">16:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
</tr>
<tr>
  <th rowspan="2">21</th>
  <th rowspan="2">10:50～<br class="sp_only">16:10</th>
  <td>10:50～</td>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>15:10～</td>
  <td>15:30～<br class="sp_only">16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">22</th>
  <th rowspan="2">11:00～<br class="sp_only">16:10</th>
  <td>11:00～</td>
  <td>11:20～<br class="sp_only">11:50</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>14:50～</td>
  <td>15:10～<br class="sp_only">15:40</td>
  <td>15:40～<br class="sp_only">16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">23</th>
  <th rowspan="2">11:00～<br class="sp_only">17:00</th>
  <td>11:00～</td>
  <td>11:20～<br class="sp_only">11:50</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
  <td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">24</th>
  <th rowspan="2">11:00～<br class="sp_only">17:30</th>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>12:20～</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">25</th>
  <th rowspan="2">11:00～<br class="sp_only">18:10</th>
  <td>11:00～<br class="sp_only">11:30</td>
  <td>15:10～</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>16:00～<br class="sp_only">16:30</td>
  <td>17:10～</td>
  <td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">26</th>
  <th rowspan="2">11:10～<br class="sp_only">16:30</th>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>13:40～</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>14:30～<br class="sp_only">15:00</td>
  <td>15:30～</td>
  <td>15:50～<br class="sp_only">16:20</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">27</th>
  <th rowspan="2">11:10～<br class="sp_only">17:30</th>
  <td>11:10～</td>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>13:30～</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
</tr>
<tr>
  <th rowspan="2">28</th>
  <th rowspan="2">11:10～<br class="sp_only">18:10</th>
  <td>11:10～<br class="sp_only">11:40</td>
  <td>14:40～</td>
  <td>15:00～<br class="sp_only">15:30</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>17:10～</td>
  <td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">29</th>
  <th rowspan="2">11:20～<br class="sp_only">15:50</th>
  <td>11:20～<br class="sp_only">11:50</td>
  <td>12:30～</td>
  <td>12:50～<br class="sp_only">13:20</td>
  <td>14:30～</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>15:20～<br class="sp_only">15:50</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">30</th>
  <th rowspan="2">11:20～<br class="sp_only">16:10</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:50～</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>13:40～<br class="sp_only">14:10</td>
  <td>15:40～<br class="sp_only">16:10</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
</tr>
<tr>
  <th rowspan="2">31</th>
  <th rowspan="2">11:20～<br class="sp_only">16:20</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>15:20～</td>
  <td>15:40～<br class="sp_only">16:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">32</th>
  <th rowspan="2">11:20～<br class="sp_only">17:10</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>14:40～</td>
  <td>15:00～<br class="sp_only">15:30</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
</tr>
<tr>
  <th rowspan="2">33</th>
  <th rowspan="2">11:20～<br class="sp_only">17:20</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>14:10～<br class="sp_only">14:40</td>
  <td>16:20～</td>
  <td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">34</th>
  <th rowspan="2">11:20～<br class="sp_only">17:40</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>16:40～</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">35</th>
  <th rowspan="2">11:20～<br class="sp_only">18:10</th>
  <td>11:20～</td>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>15:50～<br class="sp_only">16:20</td>
  <td>17:10～</td>
  <td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">36</th>
  <th rowspan="2">11:20～<br class="sp_only">18:10</th>
  <td>11:20～<br class="sp_only">11:50</td>
  <td>13:50～</td>
  <td>14:10～<br class="sp_only">14:40</td>
  <td>14:40～<br class="sp_only">15:10</td>
  <td>17:10～</td>
  <td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">37</th>
  <th rowspan="2">11:30～<br class="sp_only">16:00</th>
  <td>11:30～</td>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>14:40～</td>
  <td>15:00～<br class="sp_only">15:30</td>
  <td>15:30～<br class="sp_only">16:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">38</th>
  <th rowspan="2">11:30～<br class="sp_only">16:40</th>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>13:40～</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>14:30～<br class="sp_only">15:00</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">39</th>
  <th rowspan="2">11:30～<br class="sp_only">17:40</th>
  <td>11:30～</td>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>14:30～<br class="sp_only">15:00</td>
  <td>16:40～</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">40</th>
  <th rowspan="2">11:30～<br class="sp_only">17:50</th>
  <td>11:30～</td>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>16:50～</td>
  <td>17:10～<br class="sp_only">17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">41</th>
  <th rowspan="2">11:30～<br class="sp_only">18:00</th>
  <td>11:30～<br class="sp_only">12:00</td>
  <td>14:30～</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>16:40～</td>
  <td>17:00～<br class="sp_only">17:30</td>
  <td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">42</th>
  <th rowspan="2">11:40～<br class="sp_only">17:30</th>
  <td>11:40～</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>16:10～</td>
  <td>16:30～<br class="sp_only">17:00</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">43</th>
  <th rowspan="2">11:40～<br class="sp_only">17:30</th>
  <td>11:40～</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>16:10～</td>
  <td>16:30～<br class="sp_only">17:00</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">44</th>
  <th rowspan="2">11:40～<br class="sp_only">17:30</th>
  <td>11:40～</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">45</th>
  <th rowspan="2">11:40～<br class="sp_only">17:40</th>
  <td>11:40～</td>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>16:20～</td>
  <td>16:40～<br class="sp_only">17:10</td>
  <td>17:10～<br class="sp_only">17:40</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">46</th>
  <th rowspan="2">11:40～<br class="sp_only">17:50</th>
  <td>11:40～<br class="sp_only">12:10</td>
  <td>15:10～</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">47</th>
  <th rowspan="2">11:50～<br class="sp_only">16:50</th>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>13:30～</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>15:30～</td>
  <td>15:50～<br class="sp_only">16:20</td>
  <td>16:20～<br class="sp_only">16:50</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">48</th>
  <th rowspan="2">11:50～<br class="sp_only">17:00</th>
  <td>11:50～</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>13:40～<br class="sp_only">14:10</td>
  <td>16:00～</td>
  <td>16:20～<br class="sp_only">16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">49</th>
  <th rowspan="2">11:50～<br class="sp_only">17:30</th>
  <td>11:50～</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>14:30～</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>15:20～<br class="sp_only">15:50</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
</tr>
<tr>
  <th rowspan="2">50</th>
  <th rowspan="2">11:50～<br class="sp_only">18:00</th>
  <td>11:50～<br class="sp_only">12:20</td>
  <td>15:10～</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>16:00～<br class="sp_only">16:30</td>
  <td>17:00～</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">51</th>
  <th rowspan="2">11:50～<br class="sp_only">18:10</th>
  <td>11:50～</td>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>14:40～<br class="sp_only">15:10</td>
  <td>17:10～</td>
  <td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">52</th>
  <th rowspan="2">12:00～<br class="sp_only">16:20</th>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>13:30～</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>15:20～</td>
  <td>15:40～<br class="sp_only">16:10</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">53</th>
  <th rowspan="2">12:00～<br class="sp_only">16:30</th>
  <td>12:00～</td>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>14:10～<br class="sp_only">14:40</td>
  <td>15:10～</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>16:00～<br class="sp_only">16:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">54</th>
  <th rowspan="2">12:00～<br class="sp_only">16:30</th>
  <td>12:00～<br class="sp_only">12:30</td>
  <td>13:30～</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>15:30～</td>
  <td>15:50～<br class="sp_only">16:20</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">55</th>
  <th rowspan="2">12:10～<br class="sp_only">16:10</th>
  <td>12:10～</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>15:10～</td>
  <td>15:30～<br class="sp_only">16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">56</th>
  <th rowspan="2">12:10～<br class="sp_only">16:20</th>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>13:10～</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>15:20～</td>
  <td>15:40～<br class="sp_only">16:10</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">57</th>
  <th rowspan="2">12:10～<br class="sp_only">16:40</th>
  <td>12:10～</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>14:10～<br class="sp_only">14:40</td>
  <td>15:40～</td>
  <td>16:00～<br class="sp_only">16:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">58</th>
  <th rowspan="2">12:10～<br class="sp_only">16:50</th>
  <td>12:10～<br class="sp_only">12:40</td>
  <td>13:40～</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>15:30～</td>
  <td>15:50～<br class="sp_only">16:20</td>
  <td>16:20～<br class="sp_only">16:50</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">59</th>
  <th rowspan="2">12:10～<br class="sp_only">17:40</th>
  <td>12:10～</td>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>15:00～<br class="sp_only">15:30</td>
  <td>16:40～</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">60</th>
  <th rowspan="2">12:20～<br class="sp_only">17:30</th>
  <td>12:20～</td>
  <td>12:40～<br class="sp_only">13:10</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>16:10～</td>
  <td>16:30～<br class="sp_only">17:00</td>
  <td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">61</th>
  <th rowspan="2">12:20～<br class="sp_only">17:30</th>
  <td>12:20～<br class="sp_only">12:50</td>
  <td>13:40～</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>14:30～<br class="sp_only">15:00</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">62</th>
  <th rowspan="2">12:30～<br class="sp_only">17:20</th>
  <td>12:30～</td>
  <td>12:50～<br class="sp_only">13:20</td>
  <td>14:30～<br class="sp_only">15:00</td>
  <td>16:00～</td>
  <td>16:20～<br class="sp_only">16:50</td>
  <td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">63</th>
  <th rowspan="2">12:30～<br class="sp_only">17:50</th>
  <td>12:30～<br class="sp_only">13:00</td>
  <td>14:30～</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>16:30～</td>
  <td>16:50～<br class="sp_only">17:20</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">64</th>
  <th rowspan="2">12:50～<br class="sp_only">17:20</th>
  <td>12:50～</td>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>13:40～<br class="sp_only">14:10</td>
  <td>14:40～</td>
  <td>15:00～<br class="sp_only">15:30</td>
  <td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
</tr>
<tr>
  <th rowspan="2">65</th>
  <th rowspan="2">12:50～<br class="sp_only">18:00</th>
  <td>12:50～<br class="sp_only">13:20</td>
  <td>14:00～</td>
  <td>14:20～<br class="sp_only">14:50</td>
  <td>16:40～</td>
  <td>17:00～<br class="sp_only">17:30</td>
  <td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">66</th>
  <th rowspan="2">13:00～<br class="sp_only">18:00</th>
  <td>13:00～</td>
  <td>13:20～<br class="sp_only">13:50</td>
  <td>15:10～<br class="sp_only">15:40</td>
  <td>16:40～</td>
  <td>17:00～<br class="sp_only">17:30</td>
  <td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">67</th>
  <th rowspan="2">13:00～<br class="sp_only">18:00</th>
  <td>13:00～<br class="sp_only">13:30</td>
  <td>15:10～</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>16:40～</td>
  <td>17:00～<br class="sp_only">17:30</td>
  <td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">68</th>
  <th rowspan="2">13:10～<br class="sp_only">17:50</th>
  <td>13:10～</td>
  <td>13:30～<br class="sp_only">14:00</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>15:00～<br class="sp_only">15:30</td>
  <td>16:50～</td>
  <td>17:10～<br class="sp_only">17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">69</th>
  <th rowspan="2">13:10～<br class="sp_only">18:00</th>
  <td>13:10～<br class="sp_only">13:40</td>
  <td>15:10～</td>
  <td>15:30～<br class="sp_only">16:00</td>
  <td>16:00～<br class="sp_only">16:30</td>
  <td>17:00～</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>
<tr>
  <th rowspan="2">70</th>
  <th rowspan="2">13:30～<br class="sp_only">17:20</th>
  <td>13:30～</td>
  <td>13:50～<br class="sp_only">14:20</td>
  <td>14:50～<br class="sp_only">15:20</td>
  <td>16:00～</td>
  <td>16:20～<br class="sp_only">16:50</td>
  <td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
</tr>
<tr>
  <th rowspan="2">71</th>
  <th rowspan="2">13:40～<br class="sp_only">18:00</th>
  <td>13:40～</td>
  <td>14:00～<br class="sp_only">14:30</td>
  <td>14:30～<br class="sp_only">15:00</td>
  <td>15:50～<br class="sp_only">16:20</td>
  <td>17:00～</td>
  <td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
  <td>Yoshi's Adventure™</td>
  <td>Hollywood Dream - The Ride - BackDrop</td>
  <td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
  <td>Flight of the Hippogriff™</td>
</tr>

                <!-- ↑挿入箇所↑ -->
              </tbody>
      </table>
    </div>
    <div v-if="productId === 12" class="table_content current">
      <div>
        <p class="content">If you are using a time-designated Studio Pass (such as those that permit entry after 3 p.m.), or an Express Pass, and you plan to participate in a time-designated event, there are time slots in which you will not be able to participate. </p>
        <p class="content">When you are deciding when to participate, make sure the times do not overlap.</p>
        <p class="content">Availability may be limited depending on the entry date. Please check the availability calendar in advance.</p>
        <!-- <p class="content">For attractions that do not specify other times, please use at times other than the following</p> -->
        <!-- <p class="content">It may be sold out. Also, depending on the admission date, there may be times when it is not sold.</p> -->
      </div>
      <table class="cmn_att_time01 cmn_tbl01">
              <!--<caption>入場日：～MM月DD日</caption>-->
              <colgroup>
              <col class="w100">
              <col>
              </colgroup>
              <thead>
                <tr>
                  <th colspan="4" class="txtleft">▼ View time at left side</th>
                </tr>
              </thead>
              <tbody>
                <!-- ↓挿入箇所↓ -->

<tr>
  <th rowspan="2">1</th>
  <th rowspan="2">10:00～11:00</th>
  <td>10:00～</td>
  <td>10:20～10:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">2</th>
  <th rowspan="2">10:10～11:10</th>
  <td>10:10～</td>
  <td>10:30～11:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">3</th>
  <th rowspan="2">10:30～11:30</th>
  <td>10:30～</td>
  <td>10:50～11:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">4</th>
  <th rowspan="2">10:40～11:40</th>
  <td>10:40～</td>
  <td>11:00～11:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">5～6</th>
  <th rowspan="2">11:10～12:10</th>
  <td>11:10～</td>
  <td>11:30～12:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">7</th>
  <th rowspan="2">11:20～12:20</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">8</th>
  <th rowspan="2">12:00～13:00</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">9</th>
  <th rowspan="2">12:10～13:10</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">10</th>
  <th rowspan="2">12:40～13:40</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">11</th>
  <th rowspan="2">12:50～13:50</th>
  <td>12:50～</td>
  <td>13:10～13:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">12</th>
  <th rowspan="2">13:30～14:30</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">13</th>
  <th rowspan="2">13:40～14:40</th>
  <td>13:40～</td>
  <td>14:00～14:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">14～15</th>
  <th rowspan="2">14:00～15:00</th>
  <td>14:00～</td>
  <td>14:20～14:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">16～17</th>
  <th rowspan="2">14:10～15:10</th>
  <td>14:10～</td>
  <td>14:30～15:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">18～19</th>
  <th rowspan="2">14:20～15:20</th>
  <td>14:20～</td>
  <td>14:40～15:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">20～21</th>
  <th rowspan="2">14:30～15:30</th>
  <td>14:30～</td>
  <td>14:50～15:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">22～23</th>
  <th rowspan="2">14:40～15:40</th>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">24</th>
  <th rowspan="2">14:50～15:50</th>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">25</th>
  <th rowspan="2">15:10～16:10</th>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">26～27</th>
  <th rowspan="2">15:30～16:30</th>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">28～30</th>
  <th rowspan="2">15:40～16:40</th>
  <td>15:40～</td>
  <td>16:00～16:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">31～32</th>
  <th rowspan="2">15:50～16:50</th>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">33～34</th>
  <th rowspan="2">16:00～17:00</th>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">35～37</th>
  <th rowspan="2">16:10～17:10</th>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">38</th>
  <th rowspan="2">16:40～17:40</th>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">39</th>
  <th rowspan="2">16:50～17:50</th>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">40</th>
  <th rowspan="2">17:00～18:00</th>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>

                <!-- ↑挿入箇所↑ -->
              </tbody>
      </table>
    </div>
    <div v-if="productId === 13" class="table_content current">
      <div>
        <p class="content">If you are using a time-designated Studio Pass (such as those that permit entry after 3 p.m.), or an Express Pass, and you plan to participate in a time-designated event, there are time slots in which you will not be able to participate. </p>
        <p class="content">When you are deciding when to participate, make sure the times do not overlap.</p>
        <p class="content">Availability may be limited depending on the entry date. Please check the availability calendar in advance.</p>
        <!-- <p class="content">For attractions that do not specify other times, please use at times other than the following</p> -->
        <!-- <p class="content">It may be sold out. Also, depending on the admission date, there may be times when it is not sold.</p> -->
      </div>
      <table class="cmn_att_time01 cmn_tbl01">
              <!--<caption>入場日：～MM月DD日</caption>-->
              <colgroup>
              <col class="w100">
              <col>
              </colgroup>
              <thead>
                <tr>
                  <th colspan="4" class="txtleft">▼ View time at left side</th>
                </tr>
              </thead>
              <tbody>
                <!-- ↓挿入箇所↓ -->

<tr>
  <th rowspan="2">1</th>
  <th rowspan="2">10:50～11:50</th>
  <td>10:50～</td>
  <td>11:10～11:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">2～3</th>
  <th rowspan="2">11:00～12:00</th>
  <td>11:00～</td>
  <td>11:20～11:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">4</th>
  <th rowspan="2">11:20～12:20</th>
  <td>11:20～</td>
  <td>11:40～12:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">5～6</th>
  <th rowspan="2">11:40～12:40</th>
  <td>11:40～</td>
  <td>12:00～12:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">7～8</th>
  <th rowspan="2">11:50～12:50</th>
  <td>11:50～</td>
  <td>12:10～12:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">9</th>
  <th rowspan="2">12:00～13:00</th>
  <td>12:00～</td>
  <td>12:20～12:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">10</th>
  <th rowspan="2">12:10～13:10</th>
  <td>12:10～</td>
  <td>12:30～13:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">11～12</th>
  <th rowspan="2">12:20～13:20</th>
  <td>12:20～</td>
  <td>12:40～13:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">13</th>
  <th rowspan="2">12:40～13:40</th>
  <td>12:40～</td>
  <td>13:00～13:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">14</th>
  <th rowspan="2">12:50～13:50</th>
  <td>12:50～</td>
  <td>13:10～13:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">15～16</th>
  <th rowspan="2">13:00～14:00</th>
  <td>13:00～</td>
  <td>13:20～13:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">17～18</th>
  <th rowspan="2">13:10～14:10</th>
  <td>13:10～</td>
  <td>13:30～14:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">19</th>
  <th rowspan="2">13:20～14:20</th>
  <td>13:20～</td>
  <td>13:40～14:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">20</th>
  <th rowspan="2">13:30～14:30</th>
  <td>13:30～</td>
  <td>13:50～14:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">21</th>
  <th rowspan="2">13:40～14:40</th>
  <td>13:40～</td>
  <td>14:00～14:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">22～23</th>
  <th rowspan="2">13:50～14:50</th>
  <td>13:50～</td>
  <td>14:10～14:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">24</th>
  <th rowspan="2">14:00～15:00</th>
  <td>14:00～</td>
  <td>14:20～14:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">25</th>
  <th rowspan="2">14:10～15:10</th>
  <td>14:10～</td>
  <td>14:30～15:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">26</th>
  <th rowspan="2">14:20～15:20</th>
  <td>14:20～</td>
  <td>14:40～15:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">27～28</th>
  <th rowspan="2">14:40～15:40</th>
  <td>14:40～</td>
  <td>15:00～15:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">29～30</th>
  <th rowspan="2">14:50～15:50</th>
  <td>14:50～</td>
  <td>15:10～15:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">31</th>
  <th rowspan="2">15:00～16:00</th>
  <td>15:00～</td>
  <td>15:20～15:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">32</th>
  <th rowspan="2">15:10～16:10</th>
  <td>15:10～</td>
  <td>15:30～16:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">33</th>
  <th rowspan="2">15:20～16:20</th>
  <td>15:20～</td>
  <td>15:40～16:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">34</th>
  <th rowspan="2">15:30～16:30</th>
  <td>15:30～</td>
  <td>15:50～16:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">35</th>
  <th rowspan="2">15:50～16:50</th>
  <td>15:50～</td>
  <td>16:10～16:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">36</th>
  <th rowspan="2">16:00～17:00</th>
  <td>16:00～</td>
  <td>16:20～16:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">37</th>
  <th rowspan="2">16:10～17:10</th>
  <td>16:10～</td>
  <td>16:30～17:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">38～39</th>
  <th rowspan="2">16:20～17:20</th>
  <td>16:20～</td>
  <td>16:40～17:10</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">40～41</th>
  <th rowspan="2">16:30～17:30</th>
  <td>16:30～</td>
  <td>16:50～17:20</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">42</th>
  <th rowspan="2">16:40～17:40</th>
  <td>16:40～</td>
  <td>17:00～17:30</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">43</th>
  <th rowspan="2">16:50～17:50</th>
  <td>16:50～</td>
  <td>17:10～17:40</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">44</th>
  <th rowspan="2">17:00～18:00</th>
  <td>17:00～</td>
  <td>17:20～17:50</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>
<tr>
  <th rowspan="2">45～46</th>
  <th rowspan="2">17:10～18:10</th>
  <td>17:10～</td>
  <td>17:30～18:00</td>
</tr>
<tr>
  <td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
  <td>Mario Kart: Koopa's Challenge™</td>
</tr>

                <!-- ↑挿入箇所↑ -->
              </tbody>
      </table>
    </div>
    <div v-if="productId === 14" class="table_content current">
      <schedule-detail14 />
    </div>
  </div>
</template>
<script>
import scheduleDetail14 from './scheduleDetail14'
import scheduleDetail5 from './scheduleDetail5'
import scheduleDetail2 from './scheduleDetail2'
import scheduleDetail8 from './scheduleDetail8'

export default {
  props: ['productid'],
  components: { scheduleDetail14, scheduleDetail5, scheduleDetail2, scheduleDetail8 },
  data() {
    return{
      productId: this.productid,
      schedule: '1'
    }
  }
}
</script>

<style lang="scss" scoped>
  .table_content{
    display: block;
    .cmn_att_time01{
      width: 100%;
      min-width: 620px;
      margin: 30px auto 0;
      table-layout: fixed;
      thead th{
        background-color: #3e3a39;
        color: white;
      }
    }
  }
  .cmn_att_time01 th{
    border: 1px solid #333;
    padding: 9px;
    text-align: center;
    vertical-align: middle;
    overflow-wrap: break-word;
    color: white;
    background: #7C7C7C !important;
  }
  .txtleft{
    text-align: left !important;
  }
  .exp_att_time .cmn_att_time01 tbody tr:nth-child(even) td {
    border-top: 1px dotted #999;
    font-size: 1.3rem;
    line-height: 1.3;
  }
  .cmn_att_time01 tbody tr:nth-child(even) td {
    background: #E8E8E8;
  }
  .cmn_att_time01 td {
    border: 1px solid #333;
    padding: 9px;
    text-align: center;
    vertical-align: middle;
    overflow-wrap: break-word;
}
  .cmn_att_time01 thead th{
    background: #3E3A39 !important;
  }
  .cmn_tbl01{
    a{
      font-weight: bold;
    }
    caption{
      color: #333333;
      background: #D0DDEE;
      font-weight: bold;
      border-top: 1px solid #999;
      border-left: 1px solid #999;
      border-right: 1px solid #999;
      padding: 10px 15px;
      text-align: left;
    }
  }
  .content{
    padding-left: 15px;
    position: relative;
    &::before{
      content: '';
      display: inline-block;
      position: absolute;
      top: 8px;
      left: 5px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #d5ab81;
    }
    a{
      text-decoration:underline ;
      color:#003399;
    }
  }
  .btn_box{
    display: flex;
    padding: 10px;
    cursor: pointer;
    margin-top: 10px;
    padding-left: 0;
      .select_designated_time_btn{
        padding:0 10px;
        border: 1px solid;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 5px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(235, 235, 235, 1) 100%);
        font-weight: bold;
        &:first-child{
          margin-right: 15px;
        }
      }
      .btn-active{
        border: 2px solid #427ABE;
      }
  }
  .table_content .cmn_att_time01{
    margin-top: 10px;
  }
</style>