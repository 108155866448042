<template>
  <div>
    <div>
        <p class="content">If you are using a time-designated Studio Pass (such as those that permit entry after 3 p.m.), or an Express Pass, and you plan to participate in a time-designated event, there are time slots in which you will not be able to participate. </p>
        <p class="content">When you are deciding when to participate, make sure the times do not overlap.</p>
        <p class="content">Availability may be limited depending on the entry date. Please check the availability calendar in advance.</p>
        <!-- <p class="content">For attractions that do not specify other times, please use at times other than the following</p> -->
        <!-- <p class="content">It may be sold out. Also, depending on the admission date, there may be times when it is not sold.</p> -->
    </div>
    <el-row class="btn_box">
        <div href="session" class="select_designated_time_btn btn-active">
          Admission date：April 12th～May 31st
          <img src="@/assets/down-icon.png" alt="">
        </div>
    </el-row>
    <table class="cmn_att_time01 cmn_tbl01">
      <caption>Admission date：April 12th～May 31st</caption>
      <colgroup>
      <col class="w100">
      <col>
      </colgroup>
      <thead>
        <tr>
          <th colspan="9" class="txtleft">▼ View time at left side</th>
        </tr>
      </thead>
      <tbody>
        <!-- ↓挿入箇所↓ -->

<tr>
<th rowspan="2">1</th>
<th rowspan="2">10:00～<br class="sp_only">17:10</th>
<td>10:00～</td>
<td>10:20～<br class="sp_only">10:50</td>
<td>10:50～<br class="sp_only">11:20</td>
<td>12:20～<br class="sp_only">12:40</td>
<td>15:50～</td>
<td>16:10～<br class="sp_only">16:40</td>
<td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">2</th>
<th rowspan="2">10:00～<br class="sp_only">17:50</th>
<td>10:00～</td>
<td>10:20～<br class="sp_only">10:50</td>
<td>10:50～<br class="sp_only">11:20</td>
<td>15:20～<br class="sp_only">15:40</td>
<td>16:30～</td>
<td>16:50～<br class="sp_only">17:20</td>
<td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">3</th>
<th rowspan="2">10:10～<br class="sp_only">15:40</th>
<td>10:10～</td>
<td>10:30～<br class="sp_only">11:00</td>
<td>11:00～<br class="sp_only">11:30</td>
<td>12:20～<br class="sp_only">12:40</td>
<td>14:20～</td>
<td>14:40～<br class="sp_only">15:10</td>
<td>15:10～<br class="sp_only">15:40</td>
</tr>
<tr>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">4</th>
<th rowspan="2">10:10～<br class="sp_only">15:40</th>
<td>10:10～</td>
<td>10:30～<br class="sp_only">11:00</td>
<td>11:00～<br class="sp_only">11:30</td>
<td>12:50～</td>
<td>13:10～<br class="sp_only">13:40</td>
<td>13:40～<br class="sp_only">14:10</td>
<td>15:20～<br class="sp_only">15:40</td>
</tr>
<tr>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">5</th>
<th rowspan="2">10:10～<br class="sp_only">15:50</th>
<td>10:10～</td>
<td>10:30～<br class="sp_only">11:00</td>
<td>11:00～<br class="sp_only">11:30</td>
<td>13:20～<br class="sp_only">13:40</td>
<td>14:30～</td>
<td>14:50～<br class="sp_only">15:20</td>
<td>15:20～<br class="sp_only">15:50</td>
</tr>
<tr>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">6</th>
<th rowspan="2">10:10～<br class="sp_only">17:00</th>
<td>10:10～</td>
<td>10:30～<br class="sp_only">11:00</td>
<td>11:00～<br class="sp_only">11:30</td>
<td>12:40～<br class="sp_only">13:00</td>
<td>15:40～</td>
<td>16:00～<br class="sp_only">16:30</td>
<td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">7</th>
<th rowspan="2">10:20～<br class="sp_only">15:30</th>
<td>10:20～</td>
<td>10:40～<br class="sp_only">11:10</td>
<td>11:10～<br class="sp_only">11:40</td>
<td>12:20～<br class="sp_only">12:40</td>
<td>14:10～</td>
<td>14:30～<br class="sp_only">15:00</td>
<td>15:00～<br class="sp_only">15:30</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">8</th>
<th rowspan="2">10:20～<br class="sp_only">16:00</th>
<td>10:20～</td>
<td>10:40～<br class="sp_only">11:10</td>
<td>11:10～<br class="sp_only">11:40</td>
<td>13:00～</td>
<td>13:20～<br class="sp_only">13:50</td>
<td>13:50～<br class="sp_only">14:20</td>
<td>15:40～<br class="sp_only">16:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">9</th>
<th rowspan="2">10:20～<br class="sp_only">17:40</th>
<td>10:20～</td>
<td>10:40～<br class="sp_only">11:10</td>
<td>11:10～<br class="sp_only">11:40</td>
<td>12:20～<br class="sp_only">12:40</td>
<td>16:20～</td>
<td>16:40～<br class="sp_only">17:10</td>
<td>17:10～<br class="sp_only">17:40</td>
</tr>
<tr>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">10</th>
<th rowspan="2">10:20～<br class="sp_only">18:00</th>
<td>10:20～</td>
<td>10:40～<br class="sp_only">11:10</td>
<td>11:10～<br class="sp_only">11:40</td>
<td>14:20～<br class="sp_only">14:40</td>
<td>16:40～</td>
<td>17:00～<br class="sp_only">17:30</td>
<td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">11</th>
<th rowspan="2">10:20～<br class="sp_only">18:10</th>
<td>10:20～</td>
<td>10:40～<br class="sp_only">11:10</td>
<td>11:10～<br class="sp_only">11:40</td>
<td>15:40～<br class="sp_only">16:00</td>
<td>16:50～</td>
<td>17:10～<br class="sp_only">17:40</td>
<td>17:40～<br class="sp_only">18:10</td>
</tr>
<tr>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">12</th>
<th rowspan="2">10:30～<br class="sp_only">18:00</th>
<td>10:30～</td>
<td>10:50～<br class="sp_only">11:20</td>
<td>11:20～<br class="sp_only">11:50</td>
<td>15:20～<br class="sp_only">15:40</td>
<td>16:40～</td>
<td>17:00～<br class="sp_only">17:30</td>
<td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">13</th>
<th rowspan="2">11:10～<br class="sp_only">15:40</th>
<td>11:10～</td>
<td>11:30～<br class="sp_only">12:00</td>
<td>12:00～<br class="sp_only">12:30</td>
<td>13:10～</td>
<td>13:30～<br class="sp_only">14:00</td>
<td>14:00～<br class="sp_only">14:30</td>
<td>15:20～<br class="sp_only">15:40</td>
</tr>
<tr>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">14～15</th>
<th rowspan="2">11:10～<br class="sp_only">16:00</th>
<td>11:10～</td>
<td>11:30～<br class="sp_only">12:00</td>
<td>12:00～<br class="sp_only">12:30</td>
<td>13:00～</td>
<td>13:20～<br class="sp_only">13:50</td>
<td>13:50～<br class="sp_only">14:20</td>
<td>15:40～<br class="sp_only">16:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">16</th>
<th rowspan="2">11:10～<br class="sp_only">16:00</th>
<td>11:10～</td>
<td>11:30～<br class="sp_only">12:00</td>
<td>12:00～<br class="sp_only">12:30</td>
<td>13:10～</td>
<td>13:30～<br class="sp_only">14:00</td>
<td>14:00～<br class="sp_only">14:30</td>
<td>15:40～<br class="sp_only">16:00</td>
</tr>
<tr>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">17</th>
<th rowspan="2">11:10～<br class="sp_only">17:30</th>
<td>11:10～</td>
<td>11:30～<br class="sp_only">12:00</td>
<td>12:00～<br class="sp_only">12:30</td>
<td>14:20～<br class="sp_only">14:40</td>
<td>16:10～</td>
<td>16:30～<br class="sp_only">17:00</td>
<td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">18</th>
<th rowspan="2">11:10～<br class="sp_only">18:00</th>
<td>11:10～</td>
<td>11:30～<br class="sp_only">12:00</td>
<td>12:00～<br class="sp_only">12:30</td>
<td>14:20～<br class="sp_only">14:40</td>
<td>16:40～</td>
<td>17:00～<br class="sp_only">17:30</td>
<td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">19</th>
<th rowspan="2">11:10～<br class="sp_only">18:00</th>
<td>11:10～</td>
<td>11:30～<br class="sp_only">12:00</td>
<td>12:00～<br class="sp_only">12:30</td>
<td>14:20～<br class="sp_only">14:40</td>
<td>16:40～</td>
<td>17:00～<br class="sp_only">17:30</td>
<td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">20</th>
<th rowspan="2">11:10～<br class="sp_only">18:30</th>
<td>11:10～</td>
<td>11:30～<br class="sp_only">12:00</td>
<td>12:00～<br class="sp_only">12:30</td>
<td>15:20～<br class="sp_only">15:40</td>
<td>17:10～</td>
<td>17:30～<br class="sp_only">18:00</td>
<td>18:00～<br class="sp_only">18:30</td>
</tr>
<tr>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">21</th>
<th rowspan="2">11:20～<br class="sp_only">16:00</th>
<td>11:20～</td>
<td>11:40～<br class="sp_only">12:10</td>
<td>12:10～<br class="sp_only">12:40</td>
<td>13:20～</td>
<td>13:40～<br class="sp_only">14:10</td>
<td>14:10～<br class="sp_only">14:40</td>
<td>15:40～<br class="sp_only">16:00</td>
</tr>
<tr>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">22</th>
<th rowspan="2">11:20～<br class="sp_only">16:10</th>
<td>11:20～<br class="sp_only">11:40</td>
<td>12:50～</td>
<td>13:10～<br class="sp_only">13:40</td>
<td>13:40～<br class="sp_only">14:10</td>
<td>14:50～</td>
<td>15:10～<br class="sp_only">15:40</td>
<td>15:40～<br class="sp_only">16:10</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">23</th>
<th rowspan="2">11:20～<br class="sp_only">16:40</th>
<td>11:20～<br class="sp_only">11:40</td>
<td>13:20～</td>
<td>13:40～<br class="sp_only">14:10</td>
<td>14:10～<br class="sp_only">14:40</td>
<td>15:20～</td>
<td>15:40～<br class="sp_only">16:10</td>
<td>16:10～<br class="sp_only">16:40</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">24</th>
<th rowspan="2">11:20～<br class="sp_only">17:00</th>
<td>11:20～<br class="sp_only">11:40</td>
<td>12:20～</td>
<td>12:40～<br class="sp_only">13:10</td>
<td>13:10～<br class="sp_only">13:40</td>
<td>15:40～</td>
<td>16:00～<br class="sp_only">16:30</td>
<td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">25</th>
<th rowspan="2">11:20～<br class="sp_only">17:10</th>
<td>11:20～<br class="sp_only">11:40</td>
<td>13:00～</td>
<td>13:20～<br class="sp_only">13:50</td>
<td>13:50～<br class="sp_only">14:20</td>
<td>15:50～</td>
<td>16:10～<br class="sp_only">16:40</td>
<td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">26</th>
<th rowspan="2">11:20～<br class="sp_only">17:10</th>
<td>11:20～<br class="sp_only">11:40</td>
<td>13:10～</td>
<td>13:30～<br class="sp_only">14:00</td>
<td>14:00～<br class="sp_only">14:30</td>
<td>15:50～</td>
<td>16:10～<br class="sp_only">16:40</td>
<td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">27</th>
<th rowspan="2">11:20～<br class="sp_only">17:10</th>
<td>11:20～<br class="sp_only">11:40</td>
<td>13:50～</td>
<td>14:10～<br class="sp_only">14:40</td>
<td>14:40～<br class="sp_only">15:10</td>
<td>15:50～</td>
<td>16:10～<br class="sp_only">16:40</td>
<td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">28</th>
<th rowspan="2">11:20～<br class="sp_only">17:10</th>
<td>11:20～<br class="sp_only">11:40</td>
<td>12:50～</td>
<td>13:10～<br class="sp_only">13:40</td>
<td>13:40～<br class="sp_only">14:10</td>
<td>15:50～</td>
<td>16:10～<br class="sp_only">16:40</td>
<td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">29</th>
<th rowspan="2">11:20～<br class="sp_only">17:20</th>
<td>11:20～<br class="sp_only">11:40</td>
<td>14:00～</td>
<td>14:20～<br class="sp_only">14:50</td>
<td>14:50～<br class="sp_only">15:20</td>
<td>16:00～</td>
<td>16:20～<br class="sp_only">16:50</td>
<td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">30</th>
<th rowspan="2">11:20～<br class="sp_only">17:30</th>
<td>11:20～<br class="sp_only">11:40</td>
<td>12:20～</td>
<td>12:40～<br class="sp_only">13:10</td>
<td>13:10～<br class="sp_only">13:40</td>
<td>16:10～</td>
<td>16:30～<br class="sp_only">17:00</td>
<td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">31</th>
<th rowspan="2">11:20～<br class="sp_only">17:30</th>
<td>11:20～<br class="sp_only">11:40</td>
<td>12:50～</td>
<td>13:10～<br class="sp_only">13:40</td>
<td>13:40～<br class="sp_only">14:10</td>
<td>16:10～</td>
<td>16:30～<br class="sp_only">17:00</td>
<td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">32</th>
<th rowspan="2">11:20～<br class="sp_only">17:40</th>
<td>11:20～<br class="sp_only">11:40</td>
<td>14:30～</td>
<td>14:50～<br class="sp_only">15:20</td>
<td>15:20～<br class="sp_only">15:50</td>
<td>16:20～</td>
<td>16:40～<br class="sp_only">17:10</td>
<td>17:10～<br class="sp_only">17:40</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">33</th>
<th rowspan="2">11:20～<br class="sp_only">18:30</th>
<td>11:20～<br class="sp_only">11:40</td>
<td>12:50～</td>
<td>13:10～<br class="sp_only">13:40</td>
<td>13:40～<br class="sp_only">14:10</td>
<td>17:10～</td>
<td>17:30～<br class="sp_only">18:00</td>
<td>18:00～<br class="sp_only">18:30</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">34</th>
<th rowspan="2">11:30～<br class="sp_only">17:40</th>
<td>11:30～</td>
<td>11:50～<br class="sp_only">12:20</td>
<td>12:20～<br class="sp_only">12:50</td>
<td>15:20～<br class="sp_only">15:40</td>
<td>16:20～</td>
<td>16:40～<br class="sp_only">17:10</td>
<td>17:10～<br class="sp_only">17:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">35</th>
<th rowspan="2">11:40～<br class="sp_only">16:40</th>
<td>11:40～</td>
<td>12:00～<br class="sp_only">12:30</td>
<td>12:30～<br class="sp_only">13:00</td>
<td>14:20～</td>
<td>14:40～<br class="sp_only">15:10</td>
<td>15:10～<br class="sp_only">15:40</td>
<td>16:20～<br class="sp_only">16:40</td>
</tr>
<tr>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">36</th>
<th rowspan="2">11:40～<br class="sp_only">17:00</th>
<td>11:40～</td>
<td>12:00～<br class="sp_only">12:30</td>
<td>12:30～<br class="sp_only">13:00</td>
<td>14:20～<br class="sp_only">14:40</td>
<td>15:40～</td>
<td>16:00～<br class="sp_only">16:30</td>
<td>16:30～<br class="sp_only">17:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">37</th>
<th rowspan="2">11:40～<br class="sp_only">17:10</th>
<td>11:40～</td>
<td>12:00～<br class="sp_only">12:30</td>
<td>12:30～<br class="sp_only">13:00</td>
<td>14:20～<br class="sp_only">14:40</td>
<td>15:50～</td>
<td>16:10～<br class="sp_only">16:40</td>
<td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">38</th>
<th rowspan="2">11:40～<br class="sp_only">18:00</th>
<td>11:40～</td>
<td>12:00～<br class="sp_only">12:30</td>
<td>12:30～<br class="sp_only">13:00</td>
<td>15:20～<br class="sp_only">15:40</td>
<td>16:40～</td>
<td>17:00～<br class="sp_only">17:30</td>
<td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">39</th>
<th rowspan="2">12:00～<br class="sp_only">17:30</th>
<td>12:00～</td>
<td>12:20～<br class="sp_only">12:50</td>
<td>12:50～<br class="sp_only">13:20</td>
<td>14:20～<br class="sp_only">14:40</td>
<td>16:10～</td>
<td>16:30～<br class="sp_only">17:00</td>
<td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">40</th>
<th rowspan="2">12:10～<br class="sp_only">16:50</th>
<td>12:10～</td>
<td>12:30～<br class="sp_only">13:00</td>
<td>13:00～<br class="sp_only">13:30</td>
<td>14:20～<br class="sp_only">14:40</td>
<td>15:30～</td>
<td>15:50～<br class="sp_only">16:20</td>
<td>16:20～<br class="sp_only">16:50</td>
</tr>
<tr>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">41</th>
<th rowspan="2">12:10～<br class="sp_only">18:00</th>
<td>12:10～</td>
<td>12:30～<br class="sp_only">13:00</td>
<td>13:00～<br class="sp_only">13:30</td>
<td>15:20～<br class="sp_only">15:40</td>
<td>16:40～</td>
<td>17:00～<br class="sp_only">17:30</td>
<td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">42</th>
<th rowspan="2">12:10～<br class="sp_only">18:00</th>
<td>12:10～</td>
<td>12:30～<br class="sp_only">13:00</td>
<td>13:00～<br class="sp_only">13:30</td>
<td>15:40～<br class="sp_only">16:00</td>
<td>16:40～</td>
<td>17:00～<br class="sp_only">17:30</td>
<td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">43</th>
<th rowspan="2">12:20～<br class="sp_only">16:30</th>
<td>12:20～<br class="sp_only">12:40</td>
<td>13:20～</td>
<td>13:40～<br class="sp_only">14:10</td>
<td>14:10～<br class="sp_only">14:40</td>
<td>15:10～</td>
<td>15:30～<br class="sp_only">16:00</td>
<td>16:00～<br class="sp_only">16:30</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">44</th>
<th rowspan="2">12:20～<br class="sp_only">16:40</th>
<td>12:20～</td>
<td>12:40～<br class="sp_only">13:10</td>
<td>13:10～<br class="sp_only">13:40</td>
<td>14:20～</td>
<td>14:40～<br class="sp_only">15:10</td>
<td>15:10～<br class="sp_only">15:40</td>
<td>16:20～<br class="sp_only">16:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">45</th>
<th rowspan="2">12:20～<br class="sp_only">17:10</th>
<td>12:20～<br class="sp_only">12:40</td>
<td>14:00～</td>
<td>14:20～<br class="sp_only">14:50</td>
<td>14:50～<br class="sp_only">15:20</td>
<td>15:50～</td>
<td>16:10～<br class="sp_only">16:40</td>
<td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">46</th>
<th rowspan="2">12:20～<br class="sp_only">17:10</th>
<td>12:20～<br class="sp_only">12:40</td>
<td>13:20～</td>
<td>13:40～<br class="sp_only">14:10</td>
<td>14:10～<br class="sp_only">14:40</td>
<td>15:50～</td>
<td>16:10～<br class="sp_only">16:40</td>
<td>16:40～<br class="sp_only">17:10</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">47</th>
<th rowspan="2">12:20～<br class="sp_only">17:20</th>
<td>12:20～<br class="sp_only">12:40</td>
<td>13:30～</td>
<td>13:50～<br class="sp_only">14:20</td>
<td>14:20～<br class="sp_only">14:50</td>
<td>16:00～</td>
<td>16:20～<br class="sp_only">16:50</td>
<td>16:50～<br class="sp_only">17:20</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">48</th>
<th rowspan="2">12:20～<br class="sp_only">17:30</th>
<td>12:20～<br class="sp_only">12:40</td>
<td>14:20～</td>
<td>14:40～<br class="sp_only">15:10</td>
<td>15:10～<br class="sp_only">15:40</td>
<td>16:10～</td>
<td>16:30～<br class="sp_only">17:00</td>
<td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">49</th>
<th rowspan="2">12:20～<br class="sp_only">17:30</th>
<td>12:20～<br class="sp_only">12:40</td>
<td>14:00～</td>
<td>14:20～<br class="sp_only">14:50</td>
<td>14:50～<br class="sp_only">15:20</td>
<td>16:10～</td>
<td>16:30～<br class="sp_only">17:00</td>
<td>17:00～<br class="sp_only">17:30</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">50</th>
<th rowspan="2">12:20～<br class="sp_only">17:40</th>
<td>12:20～<br class="sp_only">12:40</td>
<td>14:20～</td>
<td>14:40～<br class="sp_only">15:10</td>
<td>15:10～<br class="sp_only">15:40</td>
<td>16:20～</td>
<td>16:40～<br class="sp_only">17:10</td>
<td>17:10～<br class="sp_only">17:40</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">51</th>
<th rowspan="2">12:30～<br class="sp_only">16:40</th>
<td>12:30～</td>
<td>12:50～<br class="sp_only">13:20</td>
<td>13:20～<br class="sp_only">13:50</td>
<td>14:20～</td>
<td>14:40～<br class="sp_only">15:10</td>
<td>15:10～<br class="sp_only">15:40</td>
<td>16:20～<br class="sp_only">16:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>SING on Tour™</td>
</tr>
<tr>
<th rowspan="2">52</th>
<th rowspan="2">12:30～<br class="sp_only">18:00</th>
<td>12:30～</td>
<td>12:50～<br class="sp_only">13:20</td>
<td>13:20～<br class="sp_only">13:50</td>
<td>15:20～<br class="sp_only">15:40</td>
<td>16:40～</td>
<td>17:00～<br class="sp_only">17:30</td>
<td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">53</th>
<th rowspan="2">12:40～<br class="sp_only">17:50</th>
<td>12:40～<br class="sp_only">13:00</td>
<td>13:40～</td>
<td>14:00～<br class="sp_only">14:30</td>
<td>14:30～<br class="sp_only">15:00</td>
<td>16:30～</td>
<td>16:50～<br class="sp_only">17:20</td>
<td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">54</th>
<th rowspan="2">12:40～<br class="sp_only">17:50</th>
<td>12:40～<br class="sp_only">13:00</td>
<td>14:10～</td>
<td>14:30～<br class="sp_only">15:00</td>
<td>15:00～<br class="sp_only">15:30</td>
<td>16:30～</td>
<td>16:50～<br class="sp_only">17:20</td>
<td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">55</th>
<th rowspan="2">12:40～<br class="sp_only">17:50</th>
<td>12:40～</td>
<td>13:00～<br class="sp_only">13:30</td>
<td>13:30～<br class="sp_only">14:00</td>
<td>15:20～<br class="sp_only">15:40</td>
<td>16:30～</td>
<td>16:50～<br class="sp_only">17:20</td>
<td>17:20～<br class="sp_only">17:50</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">56</th>
<th rowspan="2">13:00～<br class="sp_only">18:00</th>
<td>13:00～</td>
<td>13:20～<br class="sp_only">13:50</td>
<td>13:50～<br class="sp_only">14:20</td>
<td>15:40～<br class="sp_only">16:00</td>
<td>16:40～</td>
<td>17:00～<br class="sp_only">17:30</td>
<td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">57</th>
<th rowspan="2">13:10～<br class="sp_only">17:40</th>
<td>13:10～</td>
<td>13:30～<br class="sp_only">14:00</td>
<td>14:00～<br class="sp_only">14:30</td>
<td>15:20～<br class="sp_only">15:40</td>
<td>16:20～</td>
<td>16:40～<br class="sp_only">17:10</td>
<td>17:10～<br class="sp_only">17:40</td>
</tr>
<tr>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
</tr>
<tr>
<th rowspan="2">58</th>
<th rowspan="2">13:20～<br class="sp_only">18:00</th>
<td>13:20～<br class="sp_only">13:40</td>
<td>14:40～</td>
<td>15:00～<br class="sp_only">15:30</td>
<td>15:30～<br class="sp_only">16:00</td>
<td>16:40～</td>
<td>17:00～<br class="sp_only">17:30</td>
<td>17:30～<br class="sp_only">18:00</td>
</tr>
<tr>
<td>SING on Tour™</td>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>
<tr>
<th rowspan="2">59</th>
<th rowspan="2">13:30～<br class="sp_only">18:50</th>
<td>13:30～</td>
<td>13:50～<br class="sp_only">14:20</td>
<td>14:20～<br class="sp_only">14:50</td>
<td>15:40～<br class="sp_only">16:00</td>
<td>17:30～</td>
<td>17:50～<br class="sp_only">18:20</td>
<td>18:20～<br class="sp_only">18:50</td>
</tr>
<tr>
<td>The Wizarding World of Harry Potter™ Entry（Re-entry not permitted）</td>
<td>Harry Potter and the Forbidden Journey™</td>
<td>Flight of the Hippogriff™</td>
<td>SING on Tour™</td>
<td>Super Nintendo World™ Entry （Re-entry not permitted）</span></td>
<td>Mario Kart: Koopa's Challenge™</td>
<td>Yoshi's Adventure™</td>
</tr>

        <!-- ↑挿入箇所↑ -->
      </tbody>
    </table>
  </div>
</template>


<script>
export default {
  data() {
    return{
      schedule: '1'
    }
  }
}
</script>

<style lang="scss" scoped>
  .table_content{
    display: block;
    .cmn_att_time01{
      width: 100%;
      min-width: 620px;
      margin: 30px auto 0;
      table-layout: fixed;
      thead th{
        background-color: #3e3a39;
        color: white;
      }
    }
  }
  .cmn_att_time01 th{
    border: 1px solid #333;
    padding: 9px;
    text-align: center;
    vertical-align: middle;
    overflow-wrap: break-word;
    color: white;
    background: #7C7C7C !important;
  }
  .txtleft{
    text-align: left !important;
  }
  .exp_att_time .cmn_att_time01 tbody tr:nth-child(even) td {
    border-top: 1px dotted #999;
    font-size: 1.3rem;
    line-height: 1.3;
  }
  .cmn_att_time01 tbody tr:nth-child(even) td {
    background: #E8E8E8;
  }
  .cmn_att_time01 td {
    border: 1px solid #333;
    padding: 9px;
    text-align: center;
    vertical-align: middle;
    overflow-wrap: break-word;
}
  .cmn_att_time01 thead th{
    background: #3E3A39 !important;
  }
  .cmn_tbl01{
    a{
      font-weight: bold;
    }
    caption{
      color: #333333;
      background: #D0DDEE;
      font-weight: bold;
      border-top: 1px solid #999;
      border-left: 1px solid #999;
      border-right: 1px solid #999;
      padding: 10px 15px;
      text-align: left;
    }
  }
  .content{
    padding-left: 15px;
    position: relative;
    &::before{
      content: '';
      display: inline-block;
      position: absolute;
      top: 8px;
      left: 5px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #d5ab81;
    }
    a{
      text-decoration:underline ;
      color:#003399;
    }
  }
  .btn_box{
    display: flex;
    padding: 10px;
    cursor: pointer;
    margin-top: 10px;
    padding-left: 0;
      .select_designated_time_btn{
        padding:0 10px;
        border: 1px solid;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 5px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(235, 235, 235, 1) 100%);
        font-weight: bold;
        &:first-child{
          margin-right: 15px;
        }
      }
      .btn-active{
        border: 2px solid #427ABE;
        line-height: 48px;
      }
  }
  .btn_box{
    padding-left: 0;
  }
  .table_content .cmn_att_time01{
    margin-top: 10px;
  }
  @media (max-width: 768px) {
    ::v-deep .btn_box{
      flex-wrap: wrap;
      padding-left: 0;
      .select_designated_time_btn{
        width: 290px;
        &:not(:last-child){
          margin-bottom: 10px;
        }
      }
    }
    /deep/.table_content .cmn_att_time01{
      min-width: auto;
    }
  }
</style>