// 每个产品对应的可允许进入的设施，键为产品id

export const detailJSON = [
  {
    productId: 1,
    equipments: [
      "MarioKart: Koopa's Challenge",
      "Yoshi's Adventure",
      "Flight of the Hippogriff™,JAWS,The Flying Dinosaur,Despicable Me: Minion Mayhem,Space Fantasy - The Ride,Hollywood Dream -The Ride"
    ]
  },
  {
    productId: 2,
    equipments: [
      "SING on Tour",
      "MarioKart: Koopa's Challenge",
      "Yoshi's Adventure",
      "Harry Potter and the Forbidden Journey™",
      "Flight of the Hippogriff™,Space Fantasy - The Ride,Hollywood Dream - The Ride,JAWS"
    ]
  },
  {
    productId: 3,
    equipments: [
      "MarioKart: Koopa's Challenge",
      "Yoshi's Adventure",
      "Harry Potter and the Forbidden Journey™",
      "Flight of the Hippogriff™,Hollywood Dream - The Ride,JAWS,The Flying Dinosaur,Despicable Me: Minion Mayhem"
    ]
  },
  {
    productId: 4,
    equipments: [
      "Hollywood Dream - The Ride: Backdrop",
      "MarioKart: Koopa's Challenge",
      "Yoshi's Adventure",
      "Harry Potter and the Forbidden Journey™",
      "Flight of the Hippogriff™,Hollywood Dream - The Ride,JAWS"
    ]
  },
  {
    productId: 5,
    equipments: [
      "MarioKart: Koopa's Challenge",
      "Yoshi's Adventure",
      "Harry Potter and the Forbidden Journey™",
      "Flight of the Hippogriff™,Despicable Me Minion Mayhem,Hollywood Dream - The Ride,JAWS,Space Fantasy - The Ride"
    ]
  },
  {
    productId: 6,
    equipments: [
      "SING on Tour",
      "MarioKart: Koopa's Challenge,Despicable Me Minion Mayhem,The Amazing Adventures of Spider-Man 4K3D - The Ride,Jurassic Park - The Ride"
    ]
  },
  {
    productId: 7,
    equipments: [
      "SING on Tour",
      "Yoshi's Adventure,Despicable Me Minion Mayhem,The Amazing Adventures of Spider-Man 4K3D - The Ride,Jurassic Park - The Ride"
    ]
  },
  {
    productId: 8,
    equipments: [
      "MarioKart: Koopa's Challenge",
      "Harry Potter and the Forbidden Journey™,The Amazing Adventures of Spider-Man 4K3D - The Ride,Jurassic Park - The Ride, The Flying Dinosaur"
    ]
  },
  {
    productId: 9,
    equipments: [
      "MarioKart: Koopa's Challenge",
      "Harry Potter and the Forbidden Journey™,The Amazing Adventures of Spider-Man 4K3D - The Ride,Jurassic Park - The Ride,Despicable Me: Minion Mayhem"
    ]
  },
  {
    productId: 10,
    equipments: [
      "MarioKart: Koopa's Challenge",
      "Harry Potter and the Forbidden Journey™,The Amazing Adventures of Spider-Man 4K3D - The Ride,Jurassic Park - The Ride,Space Fantasy - The Ride"
    ]
  },
  {
    productId: 11,
    equipments: [
      "MarioKart: Koopa's Challenge",
      "Yoshi's Adventure",
      "Harry Potter and the Forbidden Journey",
      "Flight of the Hippogriff",
      "Hollywood Dream - The Ride: Backdrop,Despicable Me: Minion Mayhem,Hollywood Dream - The Ride,JAWS"
    ]
  },
  {
    productId: 12,
    equipments: [
      "MarioKart: Koopa's Challenge,Jurassic Park - The Ride,The Amazing Adventures of Spider-Man 4K3D - The Ride,Despicable Me: Minion Mayhem,Space Fantasy - The Ride"
    ]
  },
  {
    productId: 13,
    equipments: [
      "MarioKart: Koopa's Challenge,Jurassic Park - The Ride,The Flying Dinosaur,Space Fantasy - The Ride,The Amazing Adventures of Spider-Man 4K3D - The Ride"
    ]
  },
  {
    productId: 14,
    equipments: [
      "MarioKart: Koopa's Challenge",
      "Yoshi's Adventure",
      "Harry Potter and the Forbidden Journey™",
      "Flight of the Hippogriff™",
      "Hollywood Dream - The Ride: Backdrop,Despicable Me Minion Mayhem,Hollywood Dream - The Ride,JAWS"
    ]
  },
  // 5.28新增
  {
    productId: 15,
    equipments: [
      "Yoshi's Adventure,Despicable Me Minion Mayhem,JAWS,The Amazing Adventures of Spider-Man 4K3D - The Ride,Jurassic Park - The Ride"
    ]
  },
  {
    productId: 16,
    equipments: [
      "MarioKart: Koopa's Challenge,JAWS,The Amazing Adventures of Spider-Man 4K3D - The Ride,Jurassic Park - The Ride"
    ]
  },
  // 8月新增
  {
    productId: 17,
    equipments: [
      "Sherlock Holmes  Area Entry",
      "Sherlock Holmes - The CURSE of the Rose Sword -"
    ]
  },
  {
    productId: 18,
    equipments: [
      "GeGeGe no Kitaro - The Real : Haunted Village -",
      "Sherlock Holmes  Area Entry",
      "Sherlock Holmes - The CURSE of the Rose Sword -"
    ]
  },
  {
    productId: 19,
    equipments: [
      "MarioKart: Koopa's Challenge",
      "Yoshi's Adventure",
      "Harry Potter and the Forbidden Journey™",
      "Flight of the Hippogriff™",
      "Demon Slayer: Kimetsu no Yaiba XR Ride,Despicable Me Minion Mayhem,Hollywood Dream - The Ride,JAWS"
    ]
  },
  {
    productId: 20,
    equipments: [
      "MarioKart: Koopa's Challenge",
      "Yoshi's Adventure",
      "Harry Potter and the Forbidden Journey™",
      "Flight of the Hippogriff™",
      "SING on Tour",
      "Demon Slayer: Kimetsu no Yaiba XR Ride,Hollywood Dream - The Ride,JAWS"
    ]
  },
  {
    productId: 21,
    equipments: [
      "MarioKart: Koopa's Challenge",
      "Harry Potter and the Forbidden Journey™",
      "Demon Slayer: Kimetsu no Yaiba XR Ride,The Amazing Adventures of Spider-Man 4K3D - The Ride,Jurassic Park - The Ride"
    ]
  },
  {
    productId: 22,
    equipments: [
      "Hollywood Dream - The Ride: Backdrop",
      "Demon Slayer: Kimetsu no Yaiba XR Ride,The Flying Dinosaur,Hollywood Dream - The Ride,JAWS"
    ]
  },
  {
    productId: 23,
    equipments: [
      "Hollywood Dream - The Ride: Backdrop",
      "Demon Slayer: Kimetsu no Yaiba XR Ride,The Flying Dinosaur,Hollywood Dream - The Ride,JAWS"
    ]
  },
  {
    productId: 24,
    equipments: [
      "Hollywood Dream - The Ride: Backdrop",
      "Demon Slayer: Kimetsu no Yaiba XR Ride,The Flying Dinosaur,Hollywood Dream - The Ride,JAWS"
    ]
  },
  {
    productId: 26,
    equipments: [
      "Harry Potter and the Forbidden Journey™",
      "Flight of the Hippogriff™,Hollywood Dream - The Ride,The Amazing Adventures of Spider-Man 4K3D - The Ride,Jurassic Park - The Ride"
    ]
  },
]